import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Drawer, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAlert } from "../../../context/alertContext";
import { FilterOption } from "../../../types";
import api from "../../../utils/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ICON_OPTIONS = [
  { title: "Keywords", id: "1" },
  { title: "Artist", id: "2" },
  { title: "Material", id: "3" },
  { title: "Culture", id: "4" },
  { title: "Condition", id: "5" },
  { title: "Design", id: "6" },
  { title: "Architect", id: "7" },
  { title: "Engineering design", id: "8" },
  { title: "Religion", id: "9" },
  { title: "Builder", id: "10" },
  { title: "Sculptor", id: "11" },
  { title: "Dedicated to", id: "12" },
  { title: "Emperor", id: "13" },
  { title: "City", id: "14" },
  { title: "In the reign of", id: "15" },
  { title: "Mausoleum for", id: "16" },
];

const categoriesNames = ICON_OPTIONS.map((el) => el.title);

interface ModalFilterOptionAddProps {
  filterOptions: FilterOption[];
  isModalAddFilterOpen: boolean;
  setIsModalAddFilterOpen: any;
  onFilterOptionCreateSuccess: any;
}

const ModalFilterOptionAdd = ({
  filterOptions,
  isModalAddFilterOpen,
  setIsModalAddFilterOpen,
  onFilterOptionCreateSuccess,
}: ModalFilterOptionAddProps) => {
  const [title, setTitle] = useState("");
  const [sort, setSort] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>([]);
  const [selectedFilterTitle, setSelectedFilterTitle] = useState("");
  const { addAlert, removeAlert } = useAlert() || {};

  useEffect(() => {
    setErrors([]);
  }, [isModalAddFilterOpen]);

  const onButtonAddFilterOptionClick = async () => {
    try {
      setErrors([]);
      setLoading(true);
      let filterId = parseInt(
        ICON_OPTIONS.find((el) => el.title === selectedFilterTitle)?.id || ""
      );
      await api.post("/filterOption/create", {
        title,
        sort: parseInt(sort),
        filterId,
      });
      setLoading(false);
      let d1 = Date.now();
      addAlert &&
        addAlert({
          id: d1,
          message: "Filter option created successfully",
          severity: "success",
          title: "Success",
        });
      setTimeout(() => {
        removeAlert && removeAlert(d1);
      }, 3000);
      onFilterOptionCreateSuccess();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setSelectedFilterTitle(value);
    let filterId = parseInt(
      ICON_OPTIONS.find((el) => el.title === value)?.id || ""
    );
    const optionsWithTheSameFilter = filterOptions.filter(
      (el) => el.filterId === filterId
    );
    const maxFilterOption =
      optionsWithTheSameFilter
        .map((el: any) => el.sort)
        .sort((a: any, b: any) => a - b)
        .pop() || 0;
    setSort(maxFilterOption + 1);
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalAddFilterOpen}
      onClose={() => setIsModalAddFilterOpen(false)}
    >
      <div className="modal-wrap">
        <header>
          <h2>{`Add Filter Option`}</h2>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">
                {`Filter`}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={selectedFilterTitle}
                onChange={handleCategoryChange}
                input={<OutlinedInput label="Filter" />}
                MenuProps={MenuProps}
              >
                {categoriesNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Filter Option"
              variant="outlined"
              onChange={({ target: { value } }) => setTitle(value)}
              fullWidth={true}
              type={`text`}
              value={title}
            />
            <TextField
              label="Sort"
              variant="outlined"
              onChange={({ target: { value: sort } }) => setSort(sort)}
              fullWidth={true}
              type={`number`}
              value={sort}
            />
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }: any) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonAddFilterOptionClick}
              disabled={loading}
            >
              {`Add Filter Option`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalFilterOptionAdd;
