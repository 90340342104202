import "./ExhibitPaginationRow.style.scss";
import { useState } from "react";
import {
  ArrowDropDown,
  ArrowDropUp,
  DownloadForOffline,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  ViewColumn
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography
} from "@mui/material";

interface ExhibitPaginationRowProps {
  exhibitsCount: number;
  currentPageNumber: number;
  onIconButtonPageFirstClick: () => any;
  onIconButtonPageNextClick: () => any;
  onIconButtonPagePreviousClick: () => any;
  onIconButtonPageLastClick: () => any;
  onOpenModalFieldChooser?: () => any;
  onPageSizeChange: (pageSize: string) => any;
  pageSize: number;
  pageLoaded: boolean;
  showButtonDownloadExcel?: boolean;
}

const handleClick = () => {
  console.log("test");
};

const ExhibitPaginationRow = ({
  exhibitsCount,
  currentPageNumber,
  onIconButtonPageFirstClick,
  onIconButtonPageNextClick,
  onIconButtonPagePreviousClick,
  onIconButtonPageLastClick,
  onOpenModalFieldChooser,
  onPageSizeChange,
  pageSize,
  pageLoaded,
  showButtonDownloadExcel,
}: ExhibitPaginationRowProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onListItemButtonPageSizeChange = (pageSize: string) => {
    onPageSizeChange(pageSize);
    handleClose();
  };

  const isPopoverOpen = Boolean(anchorEl);
  const id = isPopoverOpen ? "simple-popover" : undefined;
  const lastPageNumber = Math.ceil(exhibitsCount / pageSize);

  return (
    <div className="wrap-pagination-row">
      <div className="wrap-pagination-left">
        {onOpenModalFieldChooser ? (
          <Button
            variant="text"
            color="inherit"
            onClick={onOpenModalFieldChooser}
            startIcon={<ViewColumn />}
          >
            {`Fields`}
          </Button>
        ) : null}
        {showButtonDownloadExcel ? (
          <Button
            variant="text"
            color="inherit"
            onClick={() => null}
            style={{ marginLeft: 10 }}
            startIcon={<DownloadForOffline />}
          >
            {`Download Excel`}
          </Button>
        ) : null}
      </div>
      <div className="wrap-pagination-right">
        <div className="wrap-page-size">
          <span style={{ marginRight: 8 }}>Rows:</span>
          <Button
            style={{
              backgroundColor: "white",
              color: "rgba(0, 0, 0, 0.87)",
              padding: "5px 5px 5px 11px",
              minWidth: "unset",
              boxShadow: "none",
              fontSize: 14,
            }}
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            {pageSize}
            {isPopoverOpen ? (
              <ArrowDropUp fill={`#757575`} />
            ) : (
              <ArrowDropDown fill={`#757575`} />
            )}
          </Button>
          <Popover
            id={id}
            open={isPopoverOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <List>
              {["5", "10", "25", "50"].map((pageNumber) => (
                <ListItem key={`id-${pageNumber}`} disablePadding>
                  <ListItemButton
                    onClick={() => onListItemButtonPageSizeChange(pageNumber)}
                    style={{ paddingTop: 2, paddingBottom: 2 }}
                    selected={String(pageSize) === pageNumber}
                  >
                    <ListItemText primary={pageNumber} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Popover>
        </div>
        <div className={`wrap-exhibits-count ${!pageLoaded ? "loading" : ""}`}>
          <span style={{ marginRight: 5 }}>Total:</span>
          <span className="counter">{exhibitsCount || "100"}</span>
        </div>
        <div className="wrap-page-number">
          <span style={{ marginRight: 5 }}>Page:</span>
          {currentPageNumber}
        </div>
        <IconButton
          color="inherit"
          aria-label="arrow right"
          component="label"
          onClick={onIconButtonPageFirstClick}
          disabled={currentPageNumber < 2}
        >
          <FirstPage />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="arrow left"
          component="label"
          onClick={onIconButtonPagePreviousClick}
          disabled={currentPageNumber < 2}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          aria-label="arrow right"
          color="inherit"
          disabled={currentPageNumber === lastPageNumber}
          component="label"
          onClick={onIconButtonPageNextClick}
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          aria-label="arrow right"
          color="inherit"
          component="label"
          disabled={currentPageNumber === lastPageNumber}
          onClick={onIconButtonPageLastClick}
        >
          <LastPage />
        </IconButton>
      </div>
    </div>
  );
};

export default ExhibitPaginationRow;
