import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Drawer, TextField } from "@mui/material";
import { useAlert } from "../../../context/alertContext";
import api from "../../../utils/api";

const ModalFilterOptionDelete = ({
  filterOption,
  isModalDeleteFilterOpen,
  onFilterOptionDeleteSuccess,
  setIsModalDeleteFilterOpen,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>([]);
  const { title } = filterOption || {};
  const { addAlert, removeAlert } = useAlert() || {};

  useEffect(() => {
    setErrors(false);
  }, [isModalDeleteFilterOpen]);

  const onButtonDeleteFilterOptionClick = async () => {
    console.log("delete", filterOption);
    try {
      setErrors([]);
      setLoading(true);
      await api.post("/filterOption/delete", {
        filterOptionId: filterOption.id,
      });
      setLoading(false);
      onFilterOptionDeleteSuccess();
      let d1 = Date.now();
      addAlert &&
        addAlert({
          id: d1,
          message: "Filter option deleted successfully",
          severity: "info",
          title: "Success",
        });
      setTimeout(() => {
        removeAlert && removeAlert(d1);
      }, 3000);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
      }
    }
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalDeleteFilterOpen}
      onClose={() => setIsModalDeleteFilterOpen(false)}
      key={JSON.stringify(isModalDeleteFilterOpen)}
    >
      <div className="modal-wrap">
        <header>
          <h2>{`Delete Filter Option`}</h2>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <h3>{`Are you sure you want to delete the Filter Option: ${title}`}</h3>
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }: { msg: string }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonDeleteFilterOptionClick}
              disabled={loading}
              color="error"
            >
              {`Delete Filter Option`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalFilterOptionDelete;
