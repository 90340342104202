import React from "react";
import Router from "../Router/Router";
import Loader from "../reusable/Loader/Loader";
import "./App.style.scss";
import { useAuth } from "../../context/authContext";

const App = () => {
  const { loading } = useAuth() || {};

  return <div id="application-wrap">{loading ? <Loader /> : <Router />}</div>;
};

export default App;
