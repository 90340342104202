import "./Layout.style.scss";
import React, { Suspense, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Stack } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useAlert } from "../context/alertContext";
import { useAuth } from "../context/authContext";
import ModalExhibitImagesFullScreen from "../routes/ExhibitPreview/modals/ModalExhibitFullScreen";
import { getURLParamsObjectByLocation } from "../utils/URL";
import Content from "./Content";
import FooterAuth from "./FooterAuth";
import NavBarAuth from "./NavBarAuth";

const TheLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { alerts, removeAlert } = useAlert() || {};
  const { user, loading } = useAuth() || {};

  let URLParamsObject = getURLParamsObjectByLocation(location);
  const { fullScreen } = URLParamsObject || {};

  useEffect(() => {
    if (!loading) {
      if (user) {
        navigate("/exhibits");
      } else {
        navigate("/login");
      }
    }
  }, [user, loading]);

  return (
    <>
      {fullScreen ? (
        <Suspense fallback={null}>
          <ModalExhibitImagesFullScreen browserLocation={window.location} />
        </Suspense>
      ) : null}
      <div className="c-app c-default-layout">
        <Stack sx={{ width: "100%" }} spacing={2} className="app-alert-wrap">
          {(alerts || []).map(({ severity, message, title, id }) => (
            <Alert
              key={id}
              severity={severity}
              onClose={() => removeAlert && removeAlert(id)}
            >
              {title && <AlertTitle>{title}</AlertTitle>}
              {message}
            </Alert>
          ))}
        </Stack>
        {user && <NavBarAuth />}
        <div id="content-wrap">
          <Content />
        </div>
        {user && <FooterAuth />}
      </div>
    </>
  );
};

export default TheLayout;
