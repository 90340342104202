import "./MapPopupExhibit.style.scss";
import { useEffect, useState } from "react";
import ArrowRight from "../../../assets/icons/ArrowRight";
import { useModals } from "../../../context/modalsContext";
import { Category, Exhibit, FilterOption } from "../../../types";
import { computeCategoriesTitlesArrayFromCategories } from "../../../utils/compute";
import { formatExhibitLocation } from "../../../utils/format";
import { getURLParamsObjectByLocation } from "../../../utils/URL";
import MapPopupExhibitImages from "./MapPopupExhibitImages/MapPopupExhibitImages";

interface MapPopupExhibitProps {
  exhibitUUID: string;
  left: number;
  location: any;
  top: number;
}

const MapPopupExhibit = ({
  exhibitUUID,
  left,
  location: browserLocation,
  top,
}: MapPopupExhibitProps) => {
  const { setCurrentGlobalExhibit } = useModals() || {};
  const [loading, setLoading] = useState(false);
  const [exhibit, setExhibit] = useState<Exhibit | null>(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let results: any = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/findExhibitByUUID?UUID=${exhibitUUID}`
      );
      results = await results.json();
      const { exhibit } = results || {};
      setExhibit(exhibit);
      setLoading(false);
      const URLObjectParams = getURLParamsObjectByLocation(window.location);
      if (
        URLObjectParams.fullScreen &&
        Array.isArray(URLObjectParams.fullScreen) &&
        URLObjectParams.fullScreen[0] === exhibitUUID
      ) {
        setCurrentGlobalExhibit(exhibit);
      }
    })();
  }, [exhibitUUID]);

  const {
    Categories,
    FilterOptions,
    location,
    subtitle,
    title,
    yearCompleted,
  } = exhibit || {};

  const categoriesTitles =
    computeCategoriesTitlesArrayFromCategories(Categories);

  return (
    <section id="max-exhibit" className="map-exhibit" style={{ top, left }}>
      <article className="map-exhibit-article">
        {exhibit && !loading ? (
          <>
            <div className="map-exhibit-images-wrap">
              <MapPopupExhibitImages
                exhibit={exhibit}
                browserLocation={browserLocation}
              />
            </div>
            <div className="map-exhibit-main">
              <h2 className="map-exhibit-main-title">{title}</h2>
              <p className="map-exhibit-main-location-year">
                {formatExhibitLocation(location)} · {yearCompleted}
              </p>
              <p className="map-exhibit-main-subtitle">{subtitle}</p>
              <ul className="map-exhibit-main-category-list">
                {categoriesTitles.map((categoryTitle, i: number) => (
                  <li
                    className="map-exhibit-main-category-item"
                    key={categoryTitle}
                  >
                    {categoryTitle}
                    {categoriesTitles.length > 1 &&
                    i < categoriesTitles.length - 1 ? (
                      <ArrowRight />
                    ) : null}
                  </li>
                ))}
              </ul>
              <ul className="map-exhibit-main-filter-options">
                {(FilterOptions || []).map(({ UUID, title }: FilterOption) => (
                  <li
                    className="map-exhibit-main-filter-option-item"
                    key={UUID}
                  >
                    {title}
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : null}
      </article>
      <div className="map-exhibit-hr">
        <div className="circle"></div>
      </div>
      {/* <div className="map-exhibit-hr">
        <div className="map-exhibit-hr-left" />
        <div className="map-exhibit-hr-right" />
      </div> */}
    </section>
  );
};

export default MapPopupExhibit;
