import React, { useEffect, useRef, useState } from "react";
import { Alert, Box, Button, Drawer, TextField } from "@mui/material";
import api from "../../../utils/api";

interface Error {
  msg: string;
}

const ModalNumericGroupCreate = ({
  isModalNumericGroupCreateOpen,
  setIsModalNumericGroupCreateOpen,
  onNumericGroupCreateSuccess,
}: any) => {
  const titleRef = useRef<HTMLInputElement>(null);
  const sortRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);

  useEffect(() => {
    setErrors([]);
  }, [isModalNumericGroupCreateOpen]);

  const onButtonFilterCreateClick = async () => {
    try {
      setErrors([]);
      setLoading(true);
      await api.post("/numericGroup/create", {
        title: titleRef.current!.value,
        sort: parseInt(sortRef.current!.value),
      });
      setLoading(false);
      onNumericGroupCreateSuccess();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalNumericGroupCreateOpen}
      onClose={() => setIsModalNumericGroupCreateOpen(false)}
    >
      <div className="modal-wrap">
        <header>
          <h2>{`Create Numeric Group`}</h2>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Title"
              variant="outlined"
              inputRef={titleRef}
              fullWidth={true}
              type={`text`}
              autoFocus
            />
            <TextField
              label="Sort"
              variant="outlined"
              inputRef={sortRef}
              fullWidth={true}
              type={`number`}
            />
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonFilterCreateClick}
              disabled={loading}
            >
              {`Create Numeric Group`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalNumericGroupCreate;
