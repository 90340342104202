import { memo } from "react";
import { NumericGroupOption } from "../../types";
import Table from "./components/Table/Table";
import { Column } from "./components/Table/Table.types";

const TABLE_COLUMNS_NUMERICS: Column[] = [
  { title: "ID", id: "id", width: 30 },
  { title: "Group", id: "numericGroupTitle", width: 150 },
  { title: "Option", id: "title", width: 150 },
  { title: "URL Slag", id: "URLSlug", width: 150 },
  { title: "Unit", id: "unit", width: 150 },
  { title: "Sort Order", id: "sort", width: 30 },
];

const NumericsSectionTable = ({
  numericGroupOptions,
  loading,
  setIsModalNumericGroupOptionAddOpen,
  onButtonNumericGroupOptionDeleteClick,
  onPopoverMoreButtonFilterDeleteClick,
  onPopoverButtonMoreNumericGroupEditClick,
}: any) => {
  const data = numericGroupOptions.map(
    ({ URLSlug, id, sort, title, unit, NumericGroup }: NumericGroupOption) => ({
      id,
      sort,
      title,
      URLSlug,
      unit,
      numericGroupTitle: NumericGroup?.title,
    })
  );

  return (
    <section className={`section-table-wrap`}>
      <Table
        columns={TABLE_COLUMNS_NUMERICS}
        numericGroupOptions={data}
        loading={loading}
        onButtonOptionAddClick={setIsModalNumericGroupOptionAddOpen}
        onButtonOptionDeleteClick={onButtonNumericGroupOptionDeleteClick}
        onPopoverButtonMoreDeleteClick={onPopoverMoreButtonFilterDeleteClick}
        onPopoverButtonMoreEditClick={onPopoverButtonMoreNumericGroupEditClick}
      />
    </section>
  );
};

export default memo(NumericsSectionTable);
