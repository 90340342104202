import React, { useEffect } from "react";
import { Cached } from "@mui/icons-material";
import { Box, Grid, TextField, Typography } from "@mui/material";
import NavStepHeader from "../../../../components/reusable/NavStepHeader/NavStepHeader";
import { Category, NumericGroup, NumericGroupOption } from "../../../../types";
import api from "../../../../utils/api";

interface NumericFieldsProps {
  categories: Category[];
  categoryEnabledNumericGroups: {
    [key: string]: boolean;
  };
  categoriesIteration_Ids: number[][];
  numericGroups: any;
  numericGroupOptionsValues: any;
  setCategoryEnabledNumericGroups: any;
  setNumericGroupOptionsValues: any;
  setNumericGroups: any;
  setLoading: any;
  setTimestamp: any;
  timestamp: any;
}

const NumericFields = ({
  categories,
  categoryEnabledNumericGroups,
  categoriesIteration_Ids,
  numericGroups,
  numericGroupOptionsValues,
  setCategoryEnabledNumericGroups,
  setNumericGroupOptionsValues,
  setNumericGroups,
  setLoading,
  setTimestamp,
  timestamp,
}: NumericFieldsProps) => {
  useEffect(() => {
    const selectedCategoriesIds = [...new Set(categoriesIteration_Ids.flat(1))];
    const activeNumericGroups: any = categories
      .filter((el) => selectedCategoriesIds.includes(el.id))
      .map((el: Category) => el.NumericGroups);
    const activeNumericGroupsIds: any = [];
    activeNumericGroups.forEach((el: any) => {
      (el || []).forEach((f: NumericGroup) => {
        if (!activeNumericGroupsIds.includes(f.id)) {
          activeNumericGroupsIds.push(f.id);
        }
      });
    });
    const updatedCategoryEnabledNumericGroups = {
      ...categoryEnabledNumericGroups,
    };
    const updatedNumericGroupOptionsValues = { ...numericGroupOptionsValues };
    Object.keys(updatedCategoryEnabledNumericGroups).forEach((key) => {
      updatedCategoryEnabledNumericGroups[key] =
        activeNumericGroupsIds.includes(parseInt(key));
    });
    const allowedNumericGroupOptionsIds: number[] = [];
    Object.keys(updatedCategoryEnabledNumericGroups).forEach(
      (numericGroupId) => {
        if (updatedCategoryEnabledNumericGroups[numericGroupId]) {
          let numericGroup = numericGroups.find(
            (numericGroup: NumericGroup) =>
              numericGroup.id === parseInt(numericGroupId)
          );
          numericGroup?.NumericGroupOptions.forEach(
            (numericGroupOption: NumericGroupOption) => {
              allowedNumericGroupOptionsIds.push(numericGroupOption.id);
            }
          );
        }
      }
    );
    Object.keys(updatedNumericGroupOptionsValues).forEach(
      (numericGroupOptionId: string) => {
        if (
          !allowedNumericGroupOptionsIds.includes(
            parseInt(numericGroupOptionId)
          )
        ) {
          delete updatedNumericGroupOptionsValues[numericGroupOptionId];
        }
      }
    );
    setCategoryEnabledNumericGroups(updatedCategoryEnabledNumericGroups);
    setNumericGroupOptionsValues(updatedNumericGroupOptionsValues);
  }, [timestamp]);

  const onChange = (e: any, numericGroupOptionId: number) => {
    const updatedNumericGroupOptionsValues = { ...numericGroupOptionsValues };
    updatedNumericGroupOptionsValues[numericGroupOptionId] = parseFloat(
      e.target.value
    );
    setNumericGroupOptionsValues(updatedNumericGroupOptionsValues);
  };

  const onButtonReloadNumericGroupsClick = async () => {
    try {
      setLoading(true);
      const JSONResponse = await api.get("/numericGroups");
      const updatedCategoryEnabledNumericGroups = {
        ...categoryEnabledNumericGroups,
      };
      (JSONResponse?.data?.data || []).forEach((el: NumericGroup) => {
        if (!updatedCategoryEnabledNumericGroups[el.id]) {
          updatedCategoryEnabledNumericGroups[el.id] = false;
        }
      });
      setNumericGroups(JSONResponse?.data?.data);
      setCategoryEnabledNumericGroups(updatedCategoryEnabledNumericGroups);
      setLoading(false);
      setTimestamp(Date.now());
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section>
      <NavStepHeader
        stepNumber={6}
        onButtonReloadClick={onButtonReloadNumericGroupsClick}
      />
      {numericGroups.map(
        ({ URLSlug, NumericGroupOptions, id, title }: NumericGroup) => {
          return categoryEnabledNumericGroups[id] ? (
            <Box sx={{ mb: 3 }} key={URLSlug}>
              <Typography variant="h3" sx={{ mb: 2 }}>
                {title}
              </Typography>
              <Grid container spacing={2}>
                {(NumericGroupOptions || []).map((el: NumericGroupOption) => (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label={`${el.title} ${el.unit ? `(${el.unit})` : ""}`}
                      onChange={(e) => onChange(e, el.id)}
                      type={`number`}
                      variant="outlined"
                      value={numericGroupOptionsValues[el.id]}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : null;
        }
      )}
    </section>
  );
};

export default NumericFields;
