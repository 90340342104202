import "./ExhibitCreateFilters.style.scss";
import React, { useEffect } from "react";
import { Cached } from "@mui/icons-material";
import { Autocomplete, Chip, Grid, TextField, Typography } from "@mui/material";
import NavStepHeader from "../../../../components/reusable/NavStepHeader/NavStepHeader";
import { Category, Filter } from "../../../../types";
import api from "../../../../utils/api";

interface ExhibitCreateFiltersProps {
  categories: Category[];
  categoryEnabledFilters: {
    [key: string]: boolean;
  };
  categoriesIteration_Ids: number[][];
  filters: Filter[];
  selectedFilterOptions: { [key: string]: string[] };
  setFilters: any;
  setCategoryEnabledFilters: any;
  setSelectedFilterOptions: any;
  setLoading: any;
  timestamp: any;
}

const ExhibitCreateFilters = ({
  categories,
  categoryEnabledFilters,
  categoriesIteration_Ids,
  filters,
  selectedFilterOptions,
  setCategoryEnabledFilters,
  setFilters,
  setLoading,
  setSelectedFilterOptions,
  timestamp,
}: ExhibitCreateFiltersProps) => {
  useEffect(() => {
    const selectedCategoriesIds = [...new Set(categoriesIteration_Ids.flat(1))];
    const activeFilters: any = categories
      .filter((el) => selectedCategoriesIds.includes(el.id))
      .map((el: Category) => el.Filters);
    const activeFiltersIds: any = [];
    activeFilters.forEach((el: any) => {
      (el || []).forEach((f: Filter) => {
        if (!activeFiltersIds.includes(f.id)) {
          activeFiltersIds.push(f.id);
        }
      });
    });
    const currentCategoryEnabledFilters = { ...categoryEnabledFilters };
    Object.keys(categoryEnabledFilters).forEach((key) => {
      currentCategoryEnabledFilters[key] = activeFiltersIds.includes(
        parseInt(key)
      );
    });
    const updatedSelectedFilterOptions = { ...selectedFilterOptions };
    Object.keys(currentCategoryEnabledFilters).forEach((key) => {
      let isEnabled = currentCategoryEnabledFilters[key];
      if (!isEnabled && updatedSelectedFilterOptions[key].length) {
        updatedSelectedFilterOptions[key] = [];
      }
    });
    setCategoryEnabledFilters(currentCategoryEnabledFilters);
    setSelectedFilterOptions(updatedSelectedFilterOptions);
  }, [timestamp]);

  const onFilterOptionChange = (newInputValue: string[], filterId: any) => {
    setSelectedFilterOptions({
      ...selectedFilterOptions,
      [filterId]: newInputValue,
    });
  };

  const onButtonReloadFiltersClick = async () => {
    try {
      setLoading(true);
      const JSONResponse = await api.get("/filters");
      const updatedCategoryEnabledFilters = { ...categoryEnabledFilters };
      const updatedSelectedFilterOptions = { ...selectedFilterOptions };
      (JSONResponse?.data?.data || []).forEach((el: Filter) => {
        if (!updatedCategoryEnabledFilters[el.id]) {
          updatedCategoryEnabledFilters[el.id] = false;
          updatedSelectedFilterOptions[el.id] = [];
        }
      });
      setFilters(JSONResponse?.data?.data);
      setCategoryEnabledFilters(updatedCategoryEnabledFilters);
      setSelectedFilterOptions(updatedSelectedFilterOptions);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section id="exhibit-create-section-filters">
      <NavStepHeader
        stepNumber={5}
        onButtonReloadClick={onButtonReloadFiltersClick}
      />
      <div style={{ minHeight: 200 }}>
        <Grid container spacing={2}>
          {filters.map((filter) =>
            categoryEnabledFilters[filter.id] ? (
              <Grid item xs={6} key={filter.id}>
                <div className="filter-autocomplete-wrap">
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={(filter?.filterOptions || []).map(
                      (option) => option.title
                    )}
                    onChange={(_, value) =>
                      onFilterOptionChange(value, filter.id)
                    }
                    value={selectedFilterOptions[filter.id]}
                    freeSolo
                    disabled={!categoryEnabledFilters[filter.id]}
                    renderTags={(value: string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label={filter.title}
                        placeholder="enter to add new"
                      />
                    )}
                  />
                </div>
              </Grid>
            ) : null
          )}
        </Grid>
      </div>
    </section>
  );
};

export default ExhibitCreateFilters;
