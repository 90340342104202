import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import api from "../../utils/api";
import { useAlert } from "../../context/alertContext";

const SignUp = (): JSX.Element => {
  const navigate = useNavigate();
  const { addAlert } = useAlert() || {};
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const secretRef = useRef<HTMLInputElement>(null);
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const onSubmit = async () => {
    setErrorFirstName(false);
    setErrorLastName(false);
    setErrorEmail(false);
    setErrorPassword(false);
    const firstName = firstNameRef.current!.value;
    const lastName = lastNameRef.current!.value;
    const email = emailRef.current!.value;
    const password = passwordRef.current!.value;
    const secret = secretRef.current!.value;
    let formHasError = false;
    if (!/^[a-zA-Z]{2,50}$/g.test(firstName.trim())) {
      setErrorFirstName(true);
      formHasError = true;
    }
    if (!/^[a-zA-Z]{2,50}$/g.test(lastName.trim())) {
      setErrorLastName(true);
      formHasError = true;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())) {
      setErrorEmail(true);
      formHasError = true;
    }
    if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password)) {
      setErrorPassword(true);
      formHasError = true;
    }
    if (formHasError) return;
    try {
      const JSONResponse = await api.post("/user/create", {
        firstName,
        lastName,
        email,
        password,
        secret,
      });
      if (JSONResponse?.data?.data === "OK") {
        addAlert &&
          addAlert({
            severity: "success",
            id: Date.now(),
            title: "Success",
            message:
              "Account created successfully. You can use your credentials to login!",
          });
        navigate("/login");
      } else {
        addAlert &&
          addAlert({
            severity: "error",
            id: Date.now(),
            title: "Error",
            message: "Something went wrong. Please try again later",
          });
      }
    } catch (error) {
      addAlert &&
        addAlert({
          severity: "error",
          id: Date.now(),
          title: "Error",
          message: "Something went wrong. Please try again later",
        });
    }
  };
  return (
    <main>
      <Box
        component="form"
        sx={{
          margin: "0 auto",
          width: 300,
          "& > :not(style)": { mb: 2 },
        }}
        noValidate
        autoComplete="off"
      >
        <h1>Sign Up</h1>
        <TextField
          variant="outlined"
          id="firstName"
          label="First Name"
          type="text"
          inputRef={firstNameRef}
          fullWidth={true}
          error={errorFirstName}
          helperText={errorFirstName ? `Please include a valid first name` : ""}
        />
        <TextField
          variant="outlined"
          id="lastName"
          label="Last Name"
          type="text"
          inputRef={lastNameRef}
          fullWidth={true}
          error={errorLastName}
          helperText={errorLastName ? `Please include a valid last name` : ""}
        />
        <TextField
          variant="outlined"
          id="email"
          label="Email"
          type="email"
          autoComplete="new-email"
          inputRef={emailRef}
          fullWidth={true}
          error={errorEmail}
          helperText={errorEmail ? `Please include a valid email` : ""}
        />
        <TextField
          variant="outlined"
          id="password"
          label="Password"
          type="password"
          autoComplete="new-password"
          inputRef={passwordRef}
          fullWidth={true}
          error={errorPassword}
          helperText={
            errorPassword
              ? `Password should contain minimum eight characters, at least one letter and one number`
              : ""
          }
        />
        <TextField
          variant="outlined"
          id="secret"
          label="Secret"
          type="password"
          autoComplete="secret"
          inputRef={secretRef}
          fullWidth={true}
        />
        <Button variant="contained" onClick={() => onSubmit()} fullWidth={true}>
          Submit
        </Button>
        <Link to="/login">Login</Link>
      </Box>
    </main>
  );
};

export default SignUp;
