import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Refresh } from "@mui/icons-material";
import { Alert, Backdrop, Box, CircularProgress } from "@mui/material";
import HeroBannerSearchLayout from "../../components/HeroBannerSearchLayout/HeroBannerSearchLayout";
import NavSteps from "../../components/NavSteps/NavSteps";
import { useModals } from "../../context/modalsContext";
import {
  Category,
  Exhibit,
  Filter,
  FilterOption,
  Location,
  NumericGroup,
} from "../../types";
import api from "../../utils/api";
import MainInformation from "../ExhibitCreate/components/1_MainInformation/MainInformation";
import Map from "../ExhibitCreate/components/3_Map/Map";
import Categories from "../ExhibitCreate/components/4_Categories/Categories";
import ExhibitCreateFilters from "../ExhibitCreate/components/5_ExhibitCreateFilters/ExhibitCreateFilters";
import NumericFields from "../ExhibitCreate/components/6_NumericFields/NumericFields";
import ExternalLinks from "../ExhibitCreate/components/7_ExternalLinks/ExternalLinks";
import RelatedExhibits from "../ExhibitCreate/components/8_RelatedExhibits/RelatedExhibits";
import {
  utilComputeParsedFilterOptions,
  utilGetImagesField,
  utilGetImagesPromises,
} from "../ExhibitCreate/ExhibitCreate.utils";
import ImageList from "./components/ImageList/ImageList";
import { findCategoriesIterationsIds } from "./ExhibitDetails.utils";

interface Error {
  msg: string;
}

const ExhibitsDetails = () => {
  const browserLocation = useLocation();
  const { setCurrentGlobalExhibit } = useModals() || {};
  const [loading, setLoading] = useState(false);
  const [exhibit, setExhibit] = useState<Exhibit | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [category1Id, setCategory1Id] = useState<number>(0);
  const [category2Id, setCategory2Id] = useState<number>(0);
  const [category3Id, setCategory3Id] = useState<number>(0);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<any>({});
  const [errors, setErrors] = useState<Error[]>([]);
  const [finalImagesKeys, setFinalImagesKeys] = useState<string[]>([]);
  const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);
  const [deletedOriginalIndices, setDeletedOriginalIndices] = useState<
    number[]
  >([]);
  const [imagesAttributions, setImagesAttributions] = useState<string[]>([]);
  /*--- */
  const [activeStepNumber, setActiveStepNumber] = useState(1);
  const [validatedSteps, setValidateSteps] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [timestamp, setTimestamp] = useState(Date.now());
  /* -- 01 --- */
  const [title, setTitle] = useState("");
  const [yearCompleted, setYearCompleted] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [icon, setIcon] = useState("");
  /*-- 03 --- */
  const [location, setLocation] = useState<Location>({
    country: "",
    region: "",
    place: "",
    district: "",
    locality: "",
    postcode: "",
  });
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  /* -- 04 --- */
  const [categoriesIterationsCounter, setCategoriesIterationsCounter] =
    useState(1);
  const [categoriesIteration_Ids, setCategoriesIteration_Ids] = useState<
    number[][]
  >([[]]);
  /*-- 06 --- */
  const [numericGroups, setNumericGroups] = useState<NumericGroup[]>([]);
  const [numericGroupOptionsValues, setNumericGroupOptionsValues] =
    useState<any>({});
  // const [categoryEnabledFilters, setCategoryEnabledFilters] = useState<{
  //   [key: string]: boolean;
  // }>({});
  const [categoryEnabledFilters, setCategoryEnabledFilters] = useState<any>({});
  const [categoryEnabledNumericGroups, setCategoryEnabledNumericGroups] =
    useState<{
      [key: string]: boolean;
    }>({});
  /* -- 07 --- */
  const [externalLinksCounter, setExternalLinksCounter] = useState<number>(1);
  const [externalLinksValues, setExternalLinksValues] = useState<string[]>([]);
  /* -- 08 --- */
  const [tableSelectedExhibits, setTableSelectedExhibits] = useState<Exhibit[]>(
    []
  );

  useEffect(() => {
    const { search } = browserLocation;
    let UUID = search.split("exhibitUUID=").pop();
    (async () => {
      try {
        setLoading(true);
        let d1 = Date.now();
        const [JSONExhibit, JSONCategories, JSONFilters, JSONNumericGroups] =
          await Promise.all([
            api.get(`/v1/findExhibitByUUID?exhibitUUID=${UUID}`),
            api.get("/categories"),
            api.get("/filters"),
            api.get("/numericGroups"),
          ]);
        let exhibit = JSONExhibit?.data?.exhibit;
        setCurrentGlobalExhibit(exhibit);
        setExhibit(exhibit);
        setCategories(JSONCategories?.data?.data);
        setFilters(JSONFilters?.data?.data);
        setNumericGroups(JSONNumericGroups?.data?.data);
        setFinalImagesKeys(
          exhibit?.images?.keys.map((_: any, i: number) => `ORIGINAL_${i}`)
        );
        setImagesAttributions(exhibit?.imagesAttributions);
        /* -- 01 --*/
        setTitle(exhibit?.title);
        setYearCompleted(exhibit?.yearCompleted);
        setSubtitle(exhibit?.subtitle);
        setIcon(exhibit?.icon);
        /* -- 03 -- */
        setLatitude(exhibit?.lat);
        setLongitude(exhibit?.lng);
        setLocation(exhibit?.location);
        /* -- 04 --- */
        const categoriesIterationsIds = findCategoriesIterationsIds(
          exhibit?.Categories
        );
        //TODO: fix for 2 rows
        setCategoriesIteration_Ids(categoriesIterationsIds);
        setCategoriesIterationsCounter(categoriesIterationsIds.length);
        /* -- 05 --- */
        const filtersIdsMap: any = {};
        const filterIdsOptionsMap: any = {};
        (JSONFilters?.data?.data || []).forEach((el: Filter) => {
          filtersIdsMap[el.id] = false;
          filterIdsOptionsMap[el.id] = [];
        });
        (exhibit.FilterOptions || []).forEach((filterOption: FilterOption) => {
          filterIdsOptionsMap[filterOption.filterId].push(filterOption.title);
        });
        setCategoryEnabledFilters(filtersIdsMap);
        setSelectedFilterOptions(filterIdsOptionsMap);
        const numericGroupsIdsMap: { [key: string]: boolean } = {};
        (JSONNumericGroups?.data?.data || []).forEach((el: NumericGroup) => {
          numericGroupsIdsMap[el.id] = false;
        });
        /* -- 06 --- */
        const numopts: any = {};
        exhibit?.NumericGroupOptions.forEach((ngo: any) => {
          const { NumericGroupOp, value } =
            ngo?.Exhibits_NumericGroupOptions || {};
          numopts[NumericGroupOp] = value;
        });
        setNumericGroupOptionsValues(numopts);
        /* -- 07 --- */
        setExternalLinksValues(exhibit?.externalLinks);
        setExternalLinksCounter(exhibit?.externalLinks.length);
        setCategoryEnabledNumericGroups(numericGroupsIdsMap);
        // TODO: update this with iteration, may have multiple categories
        (exhibit.Categories || []).forEach((category: Category) => {
          category.level === 0 && setCategory1Id(category.id);
          category.level === 1 && setCategory2Id(category.id);
          category.level === 2 && setCategory3Id(category.id);
        });
        setTimestamp(Date.now());
        let d2 = Date.now();
        if (d2 - d1 > 1500) {
          setLoading(false);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1500 - (d2 - d1));
        }
      } catch (error) {
        console.error(error);
      }
    })();
    return () => {
      setCurrentGlobalExhibit(null);
    };
  }, []);

  useEffect(() => {
    const categoryId = category3Id || category2Id || category1Id;
    const currentCategoryEnabledFilters = { ...categoryEnabledFilters };
    Object.keys(categoryEnabledFilters).forEach((key) => {
      currentCategoryEnabledFilters[key] = categories
        .find((el) => el.id === categoryId)
        ?.Filters.map((el) => el.id)
        .includes(parseInt(key));
    });
    setCategoryEnabledFilters(currentCategoryEnabledFilters);
    let activeFiltersIds = exhibit?.FilterOptions.map(
      (el: any) => el.Filter
    ).map((el: Filter) => el.id);
    Object.keys(categoryEnabledFilters).forEach((key) => {
      currentCategoryEnabledFilters[key] = activeFiltersIds?.includes(
        parseInt(key)
      );
    });
  }, [category1Id, category2Id, category3Id, exhibit]);

  const onFilterOptionChange = (newInputValue: string[], filterId: any) => {
    setSelectedFilterOptions({
      ...selectedFilterOptions,
      [filterId]: newInputValue,
    });
  };

  const onButtonUpdateExhibitClick = async () => {
    setLoading(true);
    const filterOptions = utilComputeParsedFilterOptions({
      filters,
      selectedFilterOptions,
    });
    const images: any = {
      dir: [],
      keys: [],
      sizes: [],
    };
    const imagePromises = await utilGetImagesPromises({
      UUID: exhibit?.UUID || "",
      selectedImageFiles,
      selectedImageFileSizes: new Array(selectedImageFiles.length).fill([
        "sm",
        "lg",
      ]),
    });
    const imageResults = await Promise.all(imagePromises);
    const imagesField = utilGetImagesField(imageResults);
    finalImagesKeys.forEach((imageKey) => {
      let indx: any = (imageKey || "").split("_").pop();
      if (imageKey.startsWith("ORIGINAL")) {
        images.dir.push(exhibit?.images.dir[indx]);
        images.keys.push(exhibit?.images?.keys[indx]);
        images.sizes.push(exhibit?.images?.sizes[indx]);
      } else {
        images.dir.push(imagesField.dir[indx]);
        images.keys.push(imagesField.keys[indx]);
        images.sizes.push(imagesField.sizes[indx]);
      }
    });
    const imagesKeysToDelete: string[] = [];
    deletedOriginalIndices.forEach((index) => {
      const { images } = exhibit || {};
      const { dir = [], keys = [], sizes = [] } = images || {};
      sizes[index]?.forEach((size) => {
        let key = `${keys[index]}-${dir[index]}-${size}`;
        imagesKeysToDelete.push(key);
      });
      imagesKeysToDelete.push(`${keys[index]}-${dir[index]}-original`);
    });
    const JSONRequest = {
      exhibitUUID: exhibit?.UUID,
      categories: (exhibit?.Categories || []).map((el: Category) => el.id),
      filterOptions,
      icon,
      images,
      imagesAttributions,
      imagesKeysToDelete,
      title,
      subtitle,
      yearCompleted,
    };

    try {
      const JSONResponse = await api.post(
        "/exhibit/updateDetails",
        JSONRequest
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const { UUID, NumericGroupOptions, images } = exhibit || {};

  const numericGroupsWithOptions: any = {};

  (NumericGroupOptions || []).forEach((el: any) => {
    if (!numericGroupsWithOptions[el.NumericGroup.title]) {
      numericGroupsWithOptions[el.NumericGroup.title] = [];
    }
    numericGroupsWithOptions[el.NumericGroup.title].push(
      `${el.title}: ${el.Exhibits_NumericGroupOptions.value} ${el.unit}`
    );
  });

  return (
    <main id={`page-exhibit-details`} style={{ minHeight: "100vh" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <HeroBannerSearchLayout
        buttonActionTitle={`Update Exhibit`}
        onButtonCreateClick={onButtonUpdateExhibitClick}
        subtitle="The Misty Map library includes all exhibits registered since 2022. You may search for information concerning exhibits and titles which are still in force or have been withdrawn or terminated."
        title="Exhibit Details"
        buttonActionIcon={<Refresh />}
      >
        <NavSteps
          activeStepNumber={activeStepNumber}
          validatedSteps={validatedSteps}
          setActiveStepNumber={setActiveStepNumber}
        />
        {activeStepNumber === 1 ? (
          <MainInformation
            icon={icon}
            setIcon={setIcon}
            title={title}
            setTitle={setTitle}
            subtitle={subtitle}
            setSubtitle={setSubtitle}
            yearCompleted={yearCompleted}
            setYearCompleted={setYearCompleted}
          />
        ) : null}
        {activeStepNumber === 2 ? (
          <ImageList
            UUID={UUID || ""}
            deletedOriginalIndices={deletedOriginalIndices}
            finalImagesKeys={finalImagesKeys}
            imagesAttributions={imagesAttributions}
            originalImages={images || []}
            setImagesAttributions={setImagesAttributions}
            selectedImageFiles={selectedImageFiles}
            setSelectedImageFiles={setSelectedImageFiles}
            setFinalImagesKeys={setFinalImagesKeys}
            setDeletedOriginalIndices={setDeletedOriginalIndices}
          />
        ) : null}
        {activeStepNumber === 3 ? (
          <Map
            setLocation={setLocation}
            location={location}
            latitude={latitude}
            longitude={longitude}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
          />
        ) : null}
        {activeStepNumber === 4 ? (
          <Categories
            categories={categories}
            categoriesIterationsCounter={categoriesIterationsCounter}
            categoriesIteration_Ids={categoriesIteration_Ids}
            setCategories={setCategories}
            setCategoriesIterationsCounter={setCategoriesIterationsCounter}
            setCategoriesIteration_Ids={setCategoriesIteration_Ids}
            setTimestamp={setTimestamp}
            setLoading={setLoading}
          />
        ) : null}
        {activeStepNumber === 5 ? (
          <ExhibitCreateFilters
            categories={categories}
            categoryEnabledFilters={categoryEnabledFilters}
            categoriesIteration_Ids={categoriesIteration_Ids}
            filters={filters}
            selectedFilterOptions={selectedFilterOptions}
            setCategoryEnabledFilters={setCategoryEnabledFilters}
            setFilters={setFilters}
            setLoading={setLoading}
            setSelectedFilterOptions={setSelectedFilterOptions}
            timestamp={timestamp}
          />
        ) : null}
        {activeStepNumber === 6 ? (
          <NumericFields
            categories={categories}
            categoryEnabledNumericGroups={categoryEnabledNumericGroups}
            categoriesIteration_Ids={categoriesIteration_Ids}
            numericGroups={numericGroups}
            numericGroupOptionsValues={numericGroupOptionsValues}
            setCategoryEnabledNumericGroups={setCategoryEnabledNumericGroups}
            setNumericGroupOptionsValues={setNumericGroupOptionsValues}
            setNumericGroups={setNumericGroups}
            setLoading={setLoading}
            setTimestamp={setTimestamp}
            timestamp={timestamp}
          />
        ) : null}
        {activeStepNumber === 7 ? (
          <ExternalLinks
            externalLinksCounter={externalLinksCounter}
            externalLinksValues={externalLinksValues}
            setExternalLinksCounter={setExternalLinksCounter}
            setExternalLinksValues={setExternalLinksValues}
          />
        ) : null}
        {activeStepNumber === 8 ? (
          <RelatedExhibits
            tableSelectedExhibits={tableSelectedExhibits}
            setTableSelectedExhibits={setTableSelectedExhibits}
          />
        ) : null}
        <div style={{ marginTop: 100 }}>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
          </Box>
        </div>
        <NavSteps
          activeStepNumber={activeStepNumber}
          hideStepNumbers={true}
          validatedSteps={validatedSteps}
          setActiveStepNumber={setActiveStepNumber}
        />
      </HeroBannerSearchLayout>
    </main>
  );
};

export default ExhibitsDetails;
