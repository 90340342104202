import "./Categories.style.scss";
import { Add, Remove } from "@mui/icons-material";
import { Box, Grid, IconButton, MenuItem, TextField } from "@mui/material";
import NavStepHeader from "../../../../components/reusable/NavStepHeader/NavStepHeader";
import { Category } from "../../../../types";
import api from "../../../../utils/api";

const Categories = ({
  categories,
  categoriesIterationsCounter,
  categoriesIteration_Ids,
  setCategories,
  setCategoriesIterationsCounter,
  setCategoriesIteration_Ids,
  setTimestamp,
  setLoading,
}: any) => {
  const onButtonReloadCategoriesClick = async () => {
    try {
      setLoading(true);
      const JSONResponse = await api.get("/categories");
      const { data: categories } = JSONResponse?.data || {};
      setCategories(categories);
      setTimestamp(Date.now());
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const onIconButtonCategoryAdd = () => {
    let updatedCategoriesIteration_Ids = [...categoriesIteration_Ids];
    updatedCategoriesIteration_Ids.push([]);
    setCategoriesIterationsCounter(categoriesIterationsCounter + 1);
    setCategoriesIteration_Ids(updatedCategoriesIteration_Ids);
    setTimestamp(Date.now());
  };

  const onIconButtonCategoryRemove = (iteration: number) => {
    let updatedCategoriesIteration_Ids = [...categoriesIteration_Ids];
    updatedCategoriesIteration_Ids.splice(iteration, 1);
    setCategoriesIterationsCounter(categoriesIterationsCounter - 1);
    setCategoriesIteration_Ids(updatedCategoriesIteration_Ids);
    setTimestamp(Date.now());
  };

  const setCategoryId = (
    categoryId: string,
    iteration: number,
    level: number
  ) => {
    let updatedCategoriesIteration_Ids = [...categoriesIteration_Ids];
    updatedCategoriesIteration_Ids[iteration][level] = categoryId;
    if (level === 0) {
      updatedCategoriesIteration_Ids[iteration] = [categoryId];
    }
    if (level === 1) {
      updatedCategoriesIteration_Ids[iteration] = [
        updatedCategoriesIteration_Ids[iteration][0],
        categoryId,
      ];
    }
    if (level === 2) {
      updatedCategoriesIteration_Ids[iteration][level] = categoryId;
    }
    setCategoriesIteration_Ids(updatedCategoriesIteration_Ids);
    setTimestamp(Date.now());
  };

  console.log(
    categoriesIteration_Ids,
    categoriesIterationsCounter,
    "all component"
  );

  return (
    <section id="exhibit-create-section-categories">
      {/* key={Math.random()} */}
      <NavStepHeader
        stepNumber={4}
        onButtonReloadClick={onButtonReloadCategoriesClick}
      />
      {Array(categoriesIterationsCounter)
        .fill(true)
        .map((_, iteration) => (
          <Box sx={{ mb: 3, mt: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Grid container spacing={2}>
                  {Array(3)
                    .fill(true)
                    .map((_, level) => {
                      let isDisabled = true;
                      let levelTitle = "Main Category";
                      let categoriesOptions: Category[] = [];
                      const levelsTitle: any = {
                        "1": "Secondary",
                        "2": "Tertiary",
                      };
                      if (level === 0) {
                        categoriesOptions = categories.filter(
                          (el: Category) => el.level === 0
                        );
                        isDisabled = false;
                      }
                      if (level > 0) {
                        levelTitle = `${levelsTitle[level]} Category`;
                        let selectedPreviousCategory =
                          categoriesIteration_Ids[iteration][level - 1];
                        if (selectedPreviousCategory) {
                          categoriesOptions = categories.filter(
                            (el: Category) =>
                              el.parentId === selectedPreviousCategory
                          );
                          isDisabled = false;
                        }
                      }
                      return (
                        <Grid item xs={4}>
                          <TextField
                            value={categoriesIteration_Ids[iteration][level]}
                            onChange={(e) =>
                              setCategoryId(e.target.value, iteration, level)
                            }
                            select
                            label={levelTitle}
                            fullWidth={true}
                            disabled={isDisabled}
                            required={true}
                          >
                            {(categoriesOptions || []).map(
                              ({ title, id }: Category) => {
                                return (
                                  <MenuItem key={id} value={id}>
                                    {title}
                                  </MenuItem>
                                );
                              }
                            )}
                          </TextField>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
              <Grid item xs={1}>
                {categoriesIterationsCounter > 1 ? (
                  <IconButton
                    onClick={() => onIconButtonCategoryRemove(iteration)}
                  >
                    <Remove />
                  </IconButton>
                ) : null}
              </Grid>
            </Grid>
          </Box>
        ))}
      <Box sx={{ mt: 2 }}>
        <IconButton onClick={onIconButtonCategoryAdd}>
          <Add fontSize="medium" />
        </IconButton>
      </Box>
    </section>
  );
};

export default Categories;
