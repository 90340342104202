import "./RelatedExhibitsSelectedItem.style.scss";
import * as React from "react";
import { Close } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import { red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Exhibit } from "../../../../types";
import { computeImageURL } from "../../../../utils/URL";

interface SelectedItemProps {
  exhibit: Exhibit;
  selectedExhibits: Exhibit[];
  setSelectedExhibits: any;
}

export default function RecipeReviewCard({
  exhibit,
  selectedExhibits,
  setSelectedExhibits,
}: SelectedItemProps) {
  const onIconButtonCloseClick = () => {
    const updatedSelectedExhibits = [...selectedExhibits];
    updatedSelectedExhibits.splice(
      selectedExhibits.findIndex((el) => el.id === exhibit.id),
      1
    );
    setSelectedExhibits(updatedSelectedExhibits);
  };

  const imageURL = computeImageURL(exhibit.images, exhibit.UUID, "md");
  return (
    <Grid item xs={4}>
      <Card sx={{ maxWidth: 345 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {exhibit.title.slice(0, 1)}
            </Avatar>
          }
          action={
            <IconButton aria-label="close" onClick={onIconButtonCloseClick}>
              <Close />
            </IconButton>
          }
          title={exhibit.title}
          subheader={exhibit.yearCompleted}
        />
        <div className="related-exhibit-image-wrap">
          <img className="image-thumbnail" src={imageURL} alt={exhibit.title} />
          <img
            src={imageURL}
            alt={exhibit.title}
            className="map-exhibit-image-background"
          />
        </div>
        <CardContent>
          <div style={{ minHeight: 80 }}>
            <Typography variant="body2" color="text.secondary">
              {exhibit.subtitle}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}
