import "./Table.style.scss";
import React, { useEffect } from "react";
import { Add, Delete, MoreVert } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Order, TableProps } from "./Table.types";
import { getComparator, stableSort } from "./Table.utils";
import TableFooter from "./TableFooter";
import TableHead from "./TableHead";
import TableRowsLoading from "./TableRowsLoading";

export interface ITableRow {
  id: number;
  sort: number;
  title: string;
  URLSlug: string;
  unit: string;
  numericGroupTitle: string;
}

export default function CustomTable({
  columns,
  numericGroupOptions,
  loading,
  onButtonOptionAddClick,
  onButtonOptionDeleteClick,
  onPopoverButtonMoreDeleteClick,
  onPopoverButtonMoreEditClick,
}: TableProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof ITableRow>("title");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [data, setData] = React.useState<ITableRow[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  useEffect(() => {
    setData([...numericGroupOptions]);
  }, [numericGroupOptions]);

  // Avoid a layout jump when reaching the last page with empty rows.

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ITableRow
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    console.log("order", order, orderBy);
  };

  // const onSearchChange = ({
  //   target: { value },
  // }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
  //   setSearchTerm(value);

  // const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) =>
  //   setAnchorEl(event.currentTarget);

  const handleMoreClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  // const onPopoverButtonMoreDeleteCallback = () => {
  //   handleMoreClose();
  //   onPopoverButtonMoreDeleteClick(numericGroupId);
  //   console.log(numericGroupId, "clicking");
  // };

  // const onPopoverButtonMoreEditCallback = () => {
  //   handleMoreClose();
  //   onPopoverButtonMoreEditClick(numericGroupId);
  // };

  // let locationSearch = window.location.search;
  const emptyRows =
    page === 0
      ? data.length < 5
        ? 5 - data.length
        : 0
      : Math.max(0, (1 + page) * rowsPerPage - data.length);

  return (
    <div className={`table-wrap ${loading ? "loading" : ""}`}>
      {/* <header> */}
      {/* <div className="row">
          <h2 className={loading ? "loading" : ""}>{title}</h2>
          <IconButton
            className="icon-button-more-vert"
            onClick={handleMoreClick}
          >
            <MoreVert />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleMoreClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="more-popover-wrap">
              <Button
                variant="outlined"
                color="primary"
                onClick={onPopoverButtonMoreEditCallback}
              >
                {`Edit Numeric Group`}
              </Button>
              <Button
                color="error"
                onClick={onPopoverButtonMoreDeleteCallback}
                variant="outlined"
              >
                {`Delete Numeric Group`}
              </Button>
            </div>
          </Popover>
        </div> */}
      {/* <div className="row">
          <div className={`left ${loading ? "loading" : ""}`}>
            <div className={`subtitle`}>
              {`ID: `}
              <span>{numericGroup.id}</span>
            </div>
            <div className="subtitle">
              {`Sort: `}
              <span>{filterSort}</span>
            </div>
            <div className="subtitle">
              {searchTerm ? "Results: " : "Options:"}
              <span>{searchTerm ? data.length : rowsData.length}</span>
            </div>
          </div>
          <div className="right">
            <TextField onChange={onSearchChange} size="small" />
            <IconButton
              size="medium"
              className="icon-add-wrap"
              onClick={() => onButtonOptionAddClick(title)}
            >
              <Add fontSize="medium" />
            </IconButton>
          </div>
        </div> */}
      {/* </header> */}
      <TableContainer component={Paper}>
        <div className="pagination-top-wrap">
          <TableFooter
            data={data}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            loading={loading}
          />
        </div>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead
            columns={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {loading ? <TableRowsLoading columns={columns} /> : null}
            {!loading
              ? stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={`${row.numericGroupTitle}-${row.id}-${row.URLSlug}`}
                    >
                      {columns.map(({ id, width }) => (
                        <TableCell
                          component="th"
                          scope="row"
                          width={width}
                          key={`cell-${id}`}
                        >
                          {row[id]}
                        </TableCell>
                      ))}
                      <TableCell align="right" width={60}>
                        <div className="row-actions">
                          <IconButton
                            onClick={() => onButtonOptionDeleteClick(row)}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
              : null}
            {emptyRows > 0 && (
              <TableRow style={{ height: 57 * emptyRows }}>
                {columns.map(({ id, width }) => (
                  <TableCell key={`cell-${id}`} width={width} />
                ))}
                <TableCell align="right" width={60} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter
            data={data}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            loading={loading}
          />
        </Table>
      </TableContainer>
    </div>
  );
}
