import "./TableRowsLoading.style.scss";
import React from "react";
import { Delete } from "@mui/icons-material";
import { IconButton, TableCell, TableRow } from "@mui/material";

const loadingTitleMap: any = {
  id: "mm",
  numericGroupTitle: "Specifications",
  title: "Architectural",
  URLSlug: "architectural",
  unit: "mmm",
  sort: "mm",
};

const TableRowsLoading = ({ columns }: any) => {
  return (
    <>
      {Array(5)
        .fill(1)
        .map((_, i) => {
          return (
            <TableRow key={i}>
              {columns.map(({ id, width }: any) => (
                <TableCell
                  component="th"
                  scope="row"
                  width={width}
                  key={`loading-cell-${id}`}
                  className="table-loading-cell"
                >
                  <span
                    className="cell-loading"
                    style={{ width: `${width}px` }}
                  >
                    {loadingTitleMap[id]}
                  </span>
                </TableCell>
              ))}
              <TableCell align="right" width={60}>
                <div className="row-actions">
                  <IconButton onClick={() => null}>
                    <Delete />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
    </>
  );
};

export default TableRowsLoading;
