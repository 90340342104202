import "./Categories.style.scss";
import React, { useEffect, useState } from "react";
import { DoubleArrow, MoreVert, PostAdd } from "@mui/icons-material";
import { Button, Grid, IconButton, Popover } from "@mui/material";
import HeroBannerSearchLayout from "../../components/HeroBannerSearchLayout/HeroBannerSearchLayout";
import { useAlert } from "../../context/alertContext";
import { Category, Filter, NumericGroup } from "../../types";
import api from "../../utils/api";
import CategoriesTreeView from "./components/CategoriesTreeView";
import CategoryFilters from "./components/CategoryFilters";
import CategoryNumericGroups from "./components/CategoryNumericGroups";
import SKELETON_CATEGORIES from "./data/SKELETON_CATEGORIES.json";
import SKELETON_FILTERS from "./data/SKELETON_FILTERS.json";
import SKELETON_NUMERIC_GROUPS from "./data/SKELETON_NUMERIC_GROUPS.json";
import ModalCategoryAdd from "./modals/ModalCategoryAdd";
import ModalCategoryDelete from "./modals/ModalCategoryDelete";
import ModalCategoryEdit from "./modals/ModalCategoryEdit";

const Categories = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<Category[]>(SKELETON_CATEGORIES);
  const [filters, setFilters] = useState<Filter[]>(SKELETON_FILTERS);
  const [numericGroups, setNumericGroups] = useState<NumericGroup[]>(
    SKELETON_NUMERIC_GROUPS
  );
  const [activeCategoryTree, setActiveCategoryTree] = useState<Category[]>([
    SKELETON_CATEGORIES[0],
  ]);
  const [categoryAddParentCategory, setCategoryAddParentCategory] = useState<
    Category | undefined
  >(undefined);
  const [isModalCategoryAddOpen, setIsModalCategoryAddOpen] = useState(false);
  const [isModalCategoryDeleteOpen, setIsModalCategoryDeleteOpen] =
    useState(false);
  const [isModalCategoryEditOpen, setIsModalCategoryEditOpen] = useState(false);
  const [categoryIdToEdit, setCategoryIdToEdit] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [screenLoaded, setScreenLoaded] = useState(false);
  const [timeStart, setTimestart] = useState(Date.now());
  const { addAlert, removeAlert } = useAlert() || {};

  useEffect(() => {
    loadCategories();
    loadFilters();
    loadNumericGroups();
  }, []);

  useEffect(() => {
    activeCategoryTree?.length
      ? onTreeItemClick(activeCategoryTree[0].id)
      : console.log("ok");
  }, [categories]);

  useEffect(() => {
    if (
      categories.length &&
      filters.length &&
      numericGroups.length &&
      !screenLoaded
    ) {
      let d2 = Date.now();
      onTreeItemClick(1);
      if (d2 - timeStart > 1500) {
        setLoading(false);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1500 - (d2 - timeStart));
      }
      setScreenLoaded(true);
    }
  }, [categories, filters, numericGroups]);

  const loadCategories = async () => {
    try {
      const categories: Category[] = (await api.get("/categories"))?.data?.data;
      setCategories(categories);
    } catch (error) {
      console.error(error);
    }
  };

  const loadFilters = async () => {
    try {
      const filters: Filter[] = (await api.get("/filters"))?.data?.data;
      setFilters(filters);
    } catch (error) {
      console.error(error);
    }
  };

  const loadNumericGroups = async () => {
    try {
      const numericGroups: NumericGroup[] = (await api.get("/numericGroups"))
        ?.data?.data;
      setNumericGroups(numericGroups);
    } catch (error) {
      console.error(error);
    }
  };

  const formatCategoryTreeTitle = (categoryTree: Category[]) => {
    const categoryTitles = [...categoryTree].reverse().map((el) => el?.title);
    return categoryTitles.map((title, index) => (
      <>
        <span>{title}</span>
        {index + 1 < categoryTitles.length ? (
          <DoubleArrow style={{ width: 16 }} />
        ) : null}
      </>
    ));
  };

  const onIconCategoryAddClick = () => {
    setCategoryAddParentCategory(
      activeCategoryTree && activeCategoryTree.length
        ? [...activeCategoryTree][0]
        : undefined
    );
    setIsModalCategoryAddOpen(true);
  };

  const onCategoryAddSuccess = () => {
    setCategoryAddParentCategory(undefined);
    setIsModalCategoryAddOpen(false);
    loadCategories();
    let d1 = Date.now();
    addAlert &&
      addAlert({
        id: d1,
        message: "Category created successfully",
        severity: "success",
        title: "Success",
      });
    setTimeout(() => {
      removeAlert && removeAlert(d1);
    }, 3000);
  };

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleMoreClose = () => setAnchorEl(null);

  const onPopoverMoreButtonCategoryDeleteClick = () => {
    handleMoreClose();
    setIsModalCategoryDeleteOpen(true);
  };

  const onPopoverMoreButtonCategoryEditClick = () => {
    handleMoreClose();
    setIsModalCategoryEditOpen(true);
  };

  const onCategoryDeleteSuccess = () => {
    setIsModalCategoryDeleteOpen(false);
    setActiveCategoryTree([]);
    loadCategories();
  };

  const onCategoryEditSuccess = () => {
    setIsModalCategoryEditOpen(false);
    // setActiveCategoryTree([]);
    loadCategories();
    let d1 = Date.now();
    addAlert &&
      addAlert({
        id: d1,
        message: "Category updated successfully",
        severity: "success",
        title: "Success",
      });
    setTimeout(() => {
      removeAlert && removeAlert(d1);
    }, 3000);
  };

  const onTreeItemClick = (categoryId: any) => {
    const categoriesLastToFirst = [];
    let categoryTreeLast: any = categories.find(
      (el: any) => el.id === categoryId
    );
    categoriesLastToFirst.push(categoryTreeLast);
    let lastCategory: any = null;
    let lastCategoryParentId = categoryTreeLast?.parentId;
    [1, 2, 3, 4].forEach((i) => {
      lastCategory = categories.find(
        (el: any) => el.id === lastCategoryParentId
      );
      if (lastCategory) {
        categoriesLastToFirst.push(lastCategory);
        lastCategoryParentId = lastCategory.parentId;
      }
    });
    setActiveCategoryTree(categoriesLastToFirst);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <main id="categories-page">
        <ModalCategoryAdd
          parentCategory={categoryAddParentCategory}
          isModalCategoryAddOpen={isModalCategoryAddOpen}
          setIsModalCategoryAddOpen={setIsModalCategoryAddOpen}
          onCategoryAddSuccess={onCategoryAddSuccess}
        />
        <ModalCategoryDelete
          activeCategoryTree={activeCategoryTree}
          isModalCategoryDeleteOpen={isModalCategoryDeleteOpen}
          onCategoryDeleteSuccess={onCategoryDeleteSuccess}
          setIsModalCategoryDeleteOpen={setIsModalCategoryDeleteOpen}
        />
        <ModalCategoryEdit
          category={activeCategoryTree[0]}
          isModalCategoryEditOpen={isModalCategoryEditOpen}
          setIsModalCategoryEditOpen={setIsModalCategoryEditOpen}
          onCategoryEditSuccess={onCategoryEditSuccess}
        />
        <HeroBannerSearchLayout
          subtitle="Exhibits categories separate items into logical entities that provide similarity grouping and indexing of the visualizations. Certain filters can be selected for any given category and the same applies for numeric groups."
          title="Categories"
          hideClearFields={true}
        >
          <Grid container spacing={8} className={`grid-container`}>
            <Grid item xs={4}>
              <div className="left-wrap">
                <nav>
                  <h4
                    onClick={() => setActiveCategoryTree([])}
                  >{`Tree View`}</h4>
                  <IconButton onClick={onIconCategoryAddClick}>
                    <PostAdd />
                  </IconButton>
                </nav>
                <CategoriesTreeView
                  categories={categories}
                  onTreeItemClick={onTreeItemClick}
                  loading={loading}
                />
              </div>
              <div className="spacer"></div>
            </Grid>
            <Grid item xs={8}>
              <div className="right-wrap">
                <>
                  <header>
                    <div className="row">
                      <h2 className="breadcrumb">
                        {formatCategoryTreeTitle([...activeCategoryTree])}
                      </h2>
                      <div>
                        <IconButton onClick={handleMoreClick}>
                          <MoreVert />
                        </IconButton>
                      </div>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleMoreClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <div className="more-popover-wrap">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={onPopoverMoreButtonCategoryEditClick}
                          >
                            {`Edit Category`}
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={onPopoverMoreButtonCategoryDeleteClick}
                          >
                            {`Delete Category`}
                          </Button>
                        </div>
                      </Popover>
                    </div>
                    <header className="sub-heading">
                      {[
                        ["Exhibits", activeCategoryTree[0]?.Exhibits?.length],
                        ["Filters", activeCategoryTree[0]?.Filters?.length],
                        [
                          "Numerics",
                          activeCategoryTree[0]?.NumericGroups?.length,
                        ],
                        ["Level", (activeCategoryTree[0]?.level || 0) + 1],
                        ["Sort", activeCategoryTree[0]?.sort],
                        ["ID", activeCategoryTree[0]?.id],
                      ].map((el) => (
                        <div
                          className={`${loading ? "sub-heading-loading" : ""}`}
                        >
                          <h4>{el[0]}</h4>
                          <p>{el[1]}</p>
                        </div>
                      ))}
                    </header>
                    <div className="row"></div>
                  </header>
                  <div>
                    <CategoryFilters
                      category={activeCategoryTree[0]}
                      filters={filters}
                      onSuccess={loadCategories}
                      setLoading={setLoading}
                      parentLoading={loading}
                    />
                    <CategoryNumericGroups
                      category={activeCategoryTree[0]}
                      numericGroups={numericGroups}
                      onSuccess={loadCategories}
                      setLoading={setLoading}
                      parentLoading={loading}
                    />
                  </div>
                </>
              </div>
            </Grid>
          </Grid>
        </HeroBannerSearchLayout>
      </main>
    </>
  );
};

export default Categories;
