import "./ExhibitCreate.style.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Backdrop, Box, CircularProgress } from "@mui/material";
import HeroBannerSearchLayout from "../../components/HeroBannerSearchLayout/HeroBannerSearchLayout";
import NavSteps from "../../components/NavSteps/NavSteps";
import { Category, Exhibit, Filter, Location, NumericGroup } from "../../types";
import api from "../../utils/api";
import MainInformation from "./components/1_MainInformation/MainInformation";
import ImageListUpload from "./components/2_ImageListUpload/ImageListUpload";
import Map from "./components/3_Map/Map";
import Categories from "./components/4_Categories/Categories";
import ExhibitCreateFilters from "./components/5_ExhibitCreateFilters/ExhibitCreateFilters";
import NumericFields from "./components/6_NumericFields/NumericFields";
import ExternalLinks from "./components/7_ExternalLinks/ExternalLinks";
import RelatedExhibits from "./components/8_RelatedExhibits/RelatedExhibits";
import {
  utilComputeParsedFilterOptions,
  utilComputeParsedNumericGroupOptionsValues,
  utilGetImagesField,
  utilGetImagesPromises
} from "./ExhibitCreate.utils";

interface Error {
  msg: string;
}

const ExhibitCreate = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeStepNumber, setActiveStepNumber] = useState(1);
  const [title, setTitle] = useState("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [categoriesIterationsCounter, setCategoriesIterationsCounter] =
    useState(1);
  const [categoriesIteration_Ids, setCategoriesIteration_Ids] = useState<
    number[][]
  >([[]]);
  const [externalLinksCounter, setExternalLinksCounter] = useState<number>(1);
  const [externalLinksValues, setExternalLinksValues] = useState<string[]>([]);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [errors, setErrors] = useState<Error[]>([]);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [numericGroups, setNumericGroups] = useState<NumericGroup[]>([]);
  const [numericGroupOptionsValues, setNumericGroupOptionsValues] =
    useState<any>({});
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [location, setLocation] = useState<Location>({
    country: "",
    region: "",
    place: "",
    district: "",
    locality: "",
    postcode: "",
  });
  const [categoryEnabledFilters, setCategoryEnabledFilters] = useState<{
    [key: string]: boolean;
  }>({});
  const [categoryEnabledNumericGroups, setCategoryEnabledNumericGroups] =
    useState<{
      [key: string]: boolean;
    }>({});
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<{
    [key: string]: string[];
  }>({});
  const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);
  const [selectedImageFileSizes, setSelectedImageFileSizes] = useState<
    string[][]
  >([]);
  const [imagesAttributions, setImagesAttributions] = useState<string[]>([]);
  const [subtitle, setSubtitle] = useState("");
  const [yearCompleted, setYearCompleted] = useState("");
  const [icon, setIcon] = useState("");
  const [validatedSteps, setValidateSteps] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    true,
  ]);
  const [tableSelectedExhibits, setTableSelectedExhibits] = useState<Exhibit[]>(
    []
  );

  useEffect(() => {
    let validated = !!title && !!yearCompleted && !!subtitle && !!icon;
    setValidateSteps([validated, ...validatedSteps.slice(1, 8)]);
  }, [title, yearCompleted, subtitle, icon]);

  useEffect(() => {
    let validated = selectedImageFiles.length > 0;
    setValidateSteps([
      ...validatedSteps.slice(0, 1),
      validated,
      ...validatedSteps.slice(2, 8),
    ]);
  }, [selectedImageFiles]);

  useEffect(() => {
    let validated = !!longitude && !!latitude;
    setValidateSteps([
      ...validatedSteps.slice(0, 2),
      validated,
      ...validatedSteps.slice(3, 8),
    ]);
  }, [longitude, latitude]);

  useEffect(() => {
    let validated = categoriesIteration_Ids.every((el) => el.length === 3);
    setValidateSteps([
      ...validatedSteps.slice(0, 3),
      validated,
      ...validatedSteps.slice(4, 8),
    ]);
  }, [categoriesIteration_Ids]);

  useEffect(() => {
    let validated = Object.values(selectedFilterOptions).some(
      (el) => el.length > 0
    );
    setValidateSteps([
      ...validatedSteps.slice(0, 4),
      validated,
      ...validatedSteps.slice(5, 8),
    ]);
  }, [selectedFilterOptions]);

  useEffect(() => {
    let validated = Object.values(numericGroupOptionsValues).some(
      (el: any) => !isNaN(el)
    );
    setValidateSteps([
      ...validatedSteps.slice(0, 5),
      validated,
      ...validatedSteps.slice(6, 8),
    ]);
  }, [numericGroupOptionsValues]);

  useEffect(() => {
    let validated = externalLinksValues.some(
      (el) => el.replace(/<.+?>/gm, "").length > 0
    );
    setValidateSteps([
      ...validatedSteps.slice(0, 6),
      validated,
      ...validatedSteps.slice(7, 8),
    ]);
  }, [externalLinksValues]);

  useEffect(() => {
    (async () => {
      try {
        const [JSONCategories, JSONFilters, JSONNumericGroups] =
          await Promise.all([
            api.get("/categories"),
            api.get("/filters"),
            api.get("/numericGroups"),
          ]);
        setCategories(JSONCategories?.data?.data);
        setFilters(JSONFilters?.data?.data);
        setNumericGroups(JSONNumericGroups?.data?.data);
        const filtersIdsMap: { [key: string]: boolean } = {};
        const filterIdsOptionsMap: { [key: string]: string[] } = {};
        (JSONFilters?.data?.data || []).forEach((el: Filter) => {
          filtersIdsMap[el.id] = false;
          filterIdsOptionsMap[el.id] = [];
        });
        const numericGroupsIdsMap: { [key: string]: boolean } = {};
        (JSONNumericGroups?.data?.data || []).forEach((el: NumericGroup) => {
          numericGroupsIdsMap[el.id] = false;
        });
        setCategoryEnabledNumericGroups(numericGroupsIdsMap);
        setCategoryEnabledFilters(filtersIdsMap);
        setSelectedFilterOptions(filterIdsOptionsMap);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const onSubmit = async () => {
    setErrors([]);
    const filterOptions = utilComputeParsedFilterOptions({
      filters,
      selectedFilterOptions,
    });
    let JSONRequest: any = {
      categories: [...new Set(categoriesIteration_Ids.flat(1))],
      externalLinks: externalLinksValues,
      filterOptions,
      icon,
      lat: latitude,
      location,
      lng: longitude,
      numericGroupOptionsValues: utilComputeParsedNumericGroupOptionsValues(
        numericGroupOptionsValues
      ),
      subtitle,
      title,
      yearCompleted: Number(yearCompleted),
      relatedExhibitsIds: tableSelectedExhibits.map((el) => el.id),
    };
    setLoading(true);
    try {
      const JSONResponse = await api.post("/exhibit/create", JSONRequest);
      const { UUID } = JSONResponse?.data?.exhibitCreated || {};
      const imagePromises = await utilGetImagesPromises({
        UUID,
        selectedImageFiles,
        selectedImageFileSizes,
      });
      const imageResults = await Promise.all(imagePromises);
      const imagesField = utilGetImagesField(imageResults);
      await api.post("/exhibit/update", {
        exhibitUUID: UUID,
        images: imagesField,
        imagesAttributions,
      });
      navigate(
        `/exhibitPreview?exhibit=${UUID}&lat=${latitude}&lng=${longitude}&zoom=${4}`
      );
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <main id={`page-exhibit-create`}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <HeroBannerSearchLayout
        buttonActionTitle={`Create`}
        onButtonCreateClick={onSubmit}
        subtitle="The Misty Map library includes all exhibits registered since 2022. You may search for information concerning exhibits and titles which are still in force or have been withdrawn or terminated."
        title="Create Exhibit"
      >
        <NavSteps
          activeStepNumber={activeStepNumber}
          validatedSteps={validatedSteps}
          setActiveStepNumber={setActiveStepNumber}
        />
        {activeStepNumber === 1 ? (
          <MainInformation
            icon={icon}
            setIcon={setIcon}
            title={title}
            setTitle={setTitle}
            subtitle={subtitle}
            setSubtitle={setSubtitle}
            yearCompleted={yearCompleted}
            setYearCompleted={setYearCompleted}
          />
        ) : null}
        {activeStepNumber === 2 ? (
          <ImageListUpload
            imagesAttributions={imagesAttributions}
            setImagesAttributions={setImagesAttributions}
            selectedImageFiles={selectedImageFiles}
            setSelectedImageFiles={setSelectedImageFiles}
            selectedImageFileSizes={selectedImageFileSizes}
            setSelectedImageFileSizes={setSelectedImageFileSizes}
          />
        ) : null}
        {activeStepNumber === 3 ? (
          <Map
            location={location}
            latitude={latitude}
            longitude={longitude}
            setLocation={setLocation}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
          />
        ) : null}
        {activeStepNumber === 4 ? (
          <Categories
            categories={categories}
            categoriesIterationsCounter={categoriesIterationsCounter}
            categoriesIteration_Ids={categoriesIteration_Ids}
            setCategories={setCategories}
            setCategoriesIterationsCounter={setCategoriesIterationsCounter}
            setCategoriesIteration_Ids={setCategoriesIteration_Ids}
            setTimestamp={setTimestamp}
            setLoading={setLoading}
          />
        ) : null}
        {activeStepNumber === 5 ? (
          <ExhibitCreateFilters
            categories={categories}
            categoryEnabledFilters={categoryEnabledFilters}
            categoriesIteration_Ids={categoriesIteration_Ids}
            filters={filters}
            selectedFilterOptions={selectedFilterOptions}
            setCategoryEnabledFilters={setCategoryEnabledFilters}
            setFilters={setFilters}
            setLoading={setLoading}
            setSelectedFilterOptions={setSelectedFilterOptions}
            timestamp={timestamp}
          />
        ) : null}
        {activeStepNumber === 6 ? (
          <NumericFields
            categories={categories}
            categoryEnabledNumericGroups={categoryEnabledNumericGroups}
            categoriesIteration_Ids={categoriesIteration_Ids}
            numericGroups={numericGroups}
            numericGroupOptionsValues={numericGroupOptionsValues}
            setCategoryEnabledNumericGroups={setCategoryEnabledNumericGroups}
            setNumericGroupOptionsValues={setNumericGroupOptionsValues}
            setNumericGroups={setNumericGroups}
            setLoading={setLoading}
            setTimestamp={setTimestamp}
            timestamp={timestamp}
          />
        ) : null}
        {activeStepNumber === 7 ? (
          <ExternalLinks
            externalLinksCounter={externalLinksCounter}
            externalLinksValues={externalLinksValues}
            setExternalLinksCounter={setExternalLinksCounter}
            setExternalLinksValues={setExternalLinksValues}
          />
        ) : null}
        {activeStepNumber === 8 ? (
          <RelatedExhibits
            tableSelectedExhibits={tableSelectedExhibits}
            setTableSelectedExhibits={setTableSelectedExhibits}
          />
        ) : null}
        <div style={{ marginTop: 100 }}>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
          </Box>
        </div>
        <NavSteps
          activeStepNumber={activeStepNumber}
          hideStepNumbers={true}
          validatedSteps={validatedSteps}
          setActiveStepNumber={setActiveStepNumber}
        />
      </HeroBannerSearchLayout>
    </main>
  );
};

export default ExhibitCreate;
