import { Location } from "../types";

export const formatLocation = (location: Location) => {
  let prefix = location.place || location.region;
  let postfix = location.country;
  let arr = [prefix, postfix].filter((el) => !!el);
  return `${arr.join(", ")}`;
};

export const keyToName = (key: string) => {
  return capitalizeFirstLetter(key.replace(/([a-z])([A-Z])/g, "$1 $2"));
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatExhibitLocation = (location: Location | undefined) => {
  if (!location) {
    return "";
  }
  let preFix = "";
  location?.place && (preFix = location?.place);
  !preFix && location?.region && (preFix = location?.region);
  return `${preFix ? `${preFix}, ` : ""}${location?.country}`;
};
