import "./ImageList.style.scss";
import { useEffect, useRef, useState } from "react";
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import NavStepHeader from "../../../../components/reusable/NavStepHeader/NavStepHeader";
import { ExhibitImages } from "../../../../types";
import { computeImageURLs } from "../../../../utils/URL";

interface ImageListProps {
  UUID: string;
  deletedOriginalIndices: number[];
  finalImagesKeys: string[];
  imagesAttributions: string[];
  originalImages: ExhibitImages | [];
  setImagesAttributions: any;
  selectedImageFiles: File[];
  setSelectedImageFiles: any;
  setFinalImagesKeys: any;
  setDeletedOriginalIndices: any;
}

const ImageList = ({
  UUID,
  deletedOriginalIndices,
  finalImagesKeys,
  imagesAttributions,
  originalImages,
  setImagesAttributions,
  selectedImageFiles,
  setSelectedImageFiles,
  setFinalImagesKeys,
  setDeletedOriginalIndices,
}: ImageListProps) => {
  const inputChooseFileRef = useRef<HTMLInputElement>(null);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const thumbnailURLs = computeImageURLs(originalImages, UUID, "sm");

  useEffect(() => {
    if (!selectedImageFiles) {
      setImagePreviews([]);
      return;
    }
    const objectsURLs: string[] = [];
    [...selectedImageFiles].map((selectedFile) =>
      objectsURLs.push(URL.createObjectURL(selectedFile))
    );
    setImagePreviews(objectsURLs);
    return () => {
      objectsURLs.forEach((objectURL) => {
        URL.revokeObjectURL(objectURL);
      });
    };
  }, [selectedImageFiles]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setSelectedImageFiles([...(selectedImageFiles || []), e.target.files[0]]);
    let greaterUpdateImageInserted: any = (finalImagesKeys || []).filter((el) =>
      el.startsWith("UPDATE")
    );
    greaterUpdateImageInserted = greaterUpdateImageInserted.length
      ? greaterUpdateImageInserted
          .map((el: any) => parseInt(el.replace("UPDATE_", "")))
          .sort()
          .pop()
      : -1;
    setFinalImagesKeys([
      ...(finalImagesKeys || []),
      `UPDATE_${greaterUpdateImageInserted + 1}`,
    ]);
    setImagesAttributions([...(imagesAttributions || []), ""]);
    inputChooseFileRef.current!.value = "";
  };

  const onSetImageAttribution = (val: string, i: number) => {
    const updatedImagesAttributions = [...(imagesAttributions || [])];
    updatedImagesAttributions[i] = val;
    setImagesAttributions(updatedImagesAttributions);
  };

  const onIconButtonRemoveClick = (imageKey: string, imageKeyIndex: number) => {
    let indx: any = (imageKey || "").split("_").pop();
    if (imageKey.startsWith("ORIGINAL")) {
      setDeletedOriginalIndices([...deletedOriginalIndices, parseInt(indx)]);
      let updatedImageKeys = [...finalImagesKeys];
      updatedImageKeys.splice(imageKeyIndex, 1);
      setFinalImagesKeys(updatedImageKeys);
    } else {
      const newPreviews = [...(imagePreviews || [])];
      newPreviews.splice(indx, 1);
      const newFiles = [...(selectedImageFiles || [])];
      newFiles.splice(indx, 1);
      setImagePreviews(newPreviews);
      setSelectedImageFiles(newFiles);
      //re-index update images keys
      const updatedImagesKeys: string[] = [];
      finalImagesKeys.forEach((key: string) => {
        if (key.startsWith("ORIGINAL")) {
          updatedImagesKeys.push(key);
        } else {
          let indexToReIndex = parseInt(`${key.split("_").pop()}`);
          if (indexToReIndex < indx) {
            updatedImagesKeys.push(key);
          } else if (indexToReIndex > indx) {
            updatedImagesKeys.push(`UPDATE_${indexToReIndex - 1}`);
          }
        }
      });
      setFinalImagesKeys(updatedImagesKeys);
    }
    let updatedImagesAttributions = [...imagesAttributions];
    updatedImagesAttributions.splice(imageKeyIndex, 1);
    setImagesAttributions(updatedImagesAttributions);
  };

  const onIconButtonLeftClick = (imageKeyIndex: number) => {
    let updatedImageKeys = [
      ...[...finalImagesKeys].splice(0, imageKeyIndex - 1),
      finalImagesKeys[imageKeyIndex],
      finalImagesKeys[imageKeyIndex - 1],
      ...[...finalImagesKeys].splice(imageKeyIndex + 1),
    ];
    let updatedImagesAttributions = [
      ...[...imagesAttributions].splice(0, imageKeyIndex - 1),
      imagesAttributions[imageKeyIndex],
      imagesAttributions[imageKeyIndex - 1],
      ...[...imagesAttributions].splice(imageKeyIndex + 1),
    ];
    setFinalImagesKeys(updatedImageKeys);
    setImagesAttributions(updatedImagesAttributions);
  };

  const onIconButtonRightClick = (imageKeyIndex: number) => {
    let updatedImageKeys = [
      ...[...finalImagesKeys].splice(0, imageKeyIndex),
      finalImagesKeys[imageKeyIndex + 1],
      finalImagesKeys[imageKeyIndex],
      ...[...finalImagesKeys].splice(imageKeyIndex + 2),
    ];
    let updatedImagesAttributions = [
      ...[...imagesAttributions].splice(0, imageKeyIndex),
      imagesAttributions[imageKeyIndex + 1],
      imagesAttributions[imageKeyIndex],
      ...[...imagesAttributions].splice(imageKeyIndex + 2),
    ];
    setFinalImagesKeys(updatedImageKeys);
    setImagesAttributions(updatedImagesAttributions);
  };

  return (
    <section>
      <NavStepHeader stepNumber={2} />
      <div id="exhibit-details-image-list-wrap">
        <div className="exhibit-details-input-file-choose-wrap">
          <input
            type="file"
            ref={inputChooseFileRef}
            onChange={onInputChange}
          />
        </div>
        <div className="exhibit-details-image-previews-wrap">
          {(finalImagesKeys || []).map(
            (imageKey: string, imageKeyIndex: number) => {
              let indx: any = (imageKey || "").split("_").pop();
              let imageURL = imageKey.startsWith("ORIGINAL")
                ? thumbnailURLs[indx]
                : imagePreviews[indx];
              return (
                <div className="exhibit-details-image-wrapper">
                  <img
                    className="exhibit-details-img"
                    src={imageURL}
                    style={{ height: "auto" }}
                  />
                  <div className="exhibit-details-action-buttons">
                    {imageKeyIndex > 0 ? (
                      <>
                        <div className="exhibit-details-action-buttons-left">
                          <IconButton
                            sx={{ color: "white" }}
                            onClick={() => onIconButtonLeftClick(imageKeyIndex)}
                          >
                            <ChevronLeft />
                          </IconButton>
                          <IconButton
                            disabled={
                              imageKeyIndex === finalImagesKeys.length - 1
                            }
                            sx={{ color: "white" }}
                            onClick={() =>
                              onIconButtonRightClick(imageKeyIndex)
                            }
                          >
                            <ChevronRight />
                          </IconButton>
                        </div>
                        <div className="exhibit-details-action-buttons-right">
                          <IconButton
                            sx={{ color: "white" }}
                            onClick={() =>
                              onIconButtonRemoveClick(imageKey, imageKeyIndex)
                            }
                          >
                            <Close />
                          </IconButton>
                        </div>
                      </>
                    ) : (
                      <div
                        className="exhibit-details-action-buttons-left"
                        style={{
                          color: "white",
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: 15,
                        }}
                      >
                        Selected thumbnail
                      </div>
                    )}
                  </div>
                  <div className="exhibit-details-image-key-index">
                    {imageKeyIndex + 1}
                  </div>
                </div>
              );
            }
          )}
        </div>
        <div className="exhibit-details-image-attributions-wrap">
          <h3>Image Attributions</h3>
          {(imagesAttributions || []).map((el, i) => (
            <div className="exhibit-details-image-attributions-line">
              <TextField
                fullWidth={true}
                label={`Image Attribution ${i + 1}`}
                multiline={true}
                onChange={(e: any) =>
                  onSetImageAttribution(e?.target?.value, i)
                }
                value={imagesAttributions[i]}
                variant="outlined"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ImageList;
