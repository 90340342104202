import "./CategoryNumericGroups.style.scss";
import { useState } from "react";
import { Close, Search } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Category, NumericGroup } from "../../../types";
import api from "../../../utils/api";

interface CategoryNumericGroupsProps {
  category: Category;
  numericGroups: NumericGroup[];
  onSuccess: any;
  setLoading: any;
  parentLoading: boolean;
}

const CategoryNumericGroups = ({
  category,
  numericGroups,
  onSuccess,
  setLoading,
  parentLoading,
}: CategoryNumericGroupsProps) => {
  const [errors, setErrors] = useState([]);
  const categoryNumericGroupsIds = category?.NumericGroups.map((el) => el.id);
  const [localLoading, setLocalLoading] = useState<number | false>(false);
  const [searchValue, setSearchValue] = useState("");

  const onCheckboxClick = async (id: number, i: number) => {
    setErrors([]);
    let d1 = Date.now();
    setLocalLoading(i);
    // setLoading(true);
    try {
      const isChecked = (categoryNumericGroupsIds || []).includes(id);
      if (isChecked) {
        await api.post("/categoryNumericGroup/delete", {
          categoryId: category.id,
          numericGroupId: id,
        });
      } else {
        await api.post("/categoryNumericGroup/create", {
          categoryId: category.id,
          numericGroupId: id,
        });
      }
      onSuccess();
      let d2 = Date.now();
      if (d2 - d1 > 500) {
        setLocalLoading(false);
      } else {
        setTimeout(() => {
          setLocalLoading(false);
        }, 500 - (d2 - d1));
      }
    } catch (error: any) {
      // setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  return (
    <div className="categories-numeric-groups-wrap">
      <header style={{ marginBottom: 10 }}>
        <Typography variant="h3" sx={{ mb: 1 }}>{`Numeric Groups`}</Typography>
        <TextField
          value={searchValue}
          onChange={({ target: { value } }) => setSearchValue(value)}
          variant="standard"
          type={`text`}
          fullWidth={true}
          placeholder={`Type to search numeric..`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <div className="adornment-wrap">
                  {searchValue ? (
                    <button
                      className="icon-close-wrap"
                      onClick={() => setSearchValue("")}
                    >
                      <Close />
                    </button>
                  ) : null}
                  <Search />
                </div>
              </InputAdornment>
            ),
          }}
        />
      </header>
      <div style={{ minHeight: 150 }}>
        <Grid container spacing={1}>
          {numericGroups.map(({ URLSlug, id, title }: NumericGroup, i) => {
            if (
              searchValue &&
              !title.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              return null;
            }
            return (
              <Grid item xs={4} key={`numeric-${URLSlug}`}>
                <FormGroup
                  className={`${parentLoading ? "checkbox-wrap-loading" : ""}`}
                >
                  <FormControlLabel
                    control={
                      localLoading !== false && i === localLoading ? (
                        <div
                          style={{
                            transform: "scale(0.55)",
                            overflow: "hidden",
                            width: 42,
                            height: 42,
                          }}
                        >
                          <CircularProgress size={42} />
                        </div>
                      ) : (
                        <Checkbox
                          checked={(categoryNumericGroupsIds || []).includes(
                            id
                          )}
                          onClick={() => onCheckboxClick(id, i)}
                        />
                      )
                    }
                    label={
                      <>
                        {`${title}`}
                        <span
                          style={{ color: "gray", marginLeft: 5, fontSize: 11 }}
                        >
                          ID: {id}
                        </span>
                      </>
                    }
                  />
                </FormGroup>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default CategoryNumericGroups;
