import React, { createContext, useState } from "react";
import { Exhibit } from "../types";

export type ContextType = {
  currentGlobalExhibit: Exhibit | null;
  setCurrentGlobalExhibit: any;
  setExhibit: any;
};

const ModalsContext = createContext<ContextType | null>(null);

const ModalsProvider = (props: any) => {
  const [currentGlobalExhibit, setCurrentGlobalExhibit] =
    useState<Exhibit | null>(null);

  const modalsContextValue = {
    currentGlobalExhibit,
    setCurrentGlobalExhibit,
  };

  return <ModalsContext.Provider value={modalsContextValue} {...props} />;
};

const useModals = () => React.useContext(ModalsContext);

export { ModalsProvider, useModals };
