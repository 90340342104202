import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Alert, Box, Button, Drawer, TextField } from "@mui/material";
import api from "../../../utils/api";

interface Error {
  msg: string;
}

interface FiltersModalFilterDeleteProps {
  filterId: number;
  filterTitle: string;
  isModalFilterDeleteOpen: boolean;
  onFilterDeleteSuccess: () => void;
  setIsModalFilterDeleteOpen: (value: boolean) => void;
}

const ModalFilterDelete = ({
  filterId,
  filterTitle,
  isModalFilterDeleteOpen,
  onFilterDeleteSuccess,
  setIsModalFilterDeleteOpen,
}: FiltersModalFilterDeleteProps) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);

  useEffect(() => {
    setErrors([]);
  }, [isModalFilterDeleteOpen]);

  const onButtonDeleteFilterClick = async () => {
    try {
      setErrors([]);
      setLoading(true);
      await api.post("/filter/delete", {
        filterId,
      });
      setLoading(false);
      onFilterDeleteSuccess();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalFilterDeleteOpen}
      onClose={() => setIsModalFilterDeleteOpen(false)}
    >
      <div className="modal-wrap">
        <header>
          <h2>{`Delete Filter Option`}</h2>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <h3>{`Are you sure you want to delete the Filter: ${filterTitle}`}</h3>
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonDeleteFilterClick}
              disabled={loading}
            >
              {`Delete Filter`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalFilterDelete;
