import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import api from "../../../utils/api";

interface Error {
  msg: string;
}

const ModalFilterCreate = ({
  isModalCreateFilterOpen,
  setIsModalCreateFilterOpen,
  onFilterCreateSuccess,
}: any) => {
  const titleRef = useRef<HTMLInputElement>(null);
  const sortRef = useRef<HTMLInputElement>(null);
  const [inputType, setInputType] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);

  useEffect(() => {
    setErrors([]);
  }, [isModalCreateFilterOpen]);

  const onButtonFilterCreateClick = async () => {
    try {
      setErrors([]);
      setLoading(true);
      await api.post("/filter/create", {
        title: titleRef.current!.value,
        sort: parseInt(sortRef.current!.value),
        inputType,
      });
      setLoading(false);
      onFilterCreateSuccess();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalCreateFilterOpen}
      onClose={() => setIsModalCreateFilterOpen(false)}
    >
      <div className="modal-wrap">
        <header>
          <h2>{`Create Filter`}</h2>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Title"
              variant="outlined"
              inputRef={titleRef}
              fullWidth={true}
              type={`text`}
              autoFocus
            />
            <TextField
              label="Sort"
              variant="outlined"
              inputRef={sortRef}
              fullWidth={true}
              type={`number`}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Input Type</InputLabel>
              <Select
                labelId="input-type"
                id="input-type"
                value={inputType}
                label="Input Type"
                onChange={({ target: { value } }) => setInputType(value)}
              >
                <MenuItem value={`checkbox`}>{`Checkbox`}</MenuItem>
                <MenuItem value={`radio`}>{`Radio`}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonFilterCreateClick}
              disabled={loading}
            >
              {`Create Filter`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalFilterCreate;
