import {
  getURLParamsObjectByLocation,
  getURLSearchByParamsObject,
} from "../../utils/URL";
import {
  DEFAULT_SEARCH_FIELDS_STATE,
  DEFAULT_SEARCH_PAGINATION_STATE,
} from "./Exhibits";

export const getPaginationStateFromQueryParams = (
  location: any,
  defaultPagination?: any
) => {
  const paginationState: any = {
    pageNumber: 1,
    pageSize: defaultPagination?.defaultPageSize || 10,
    order: "id",
    reverse: false,
  };
  Object.keys(paginationState).forEach((key) => {
    if (location.search.includes(key)) {
      paginationState[key] = location.search
        .split(`${key}=`)
        .pop()
        ?.split("&")[0];
    }
    ["pageNumber", "pageSize"].forEach(
      (key) => (paginationState[key] = parseInt(paginationState[key]))
    );
    paginationState["reverse"] = Boolean(paginationState["reverse"]);
  });
  return paginationState;
};

export const getFieldsStateFromQueryParams = (location: any) => {
  const fieldsState: any = {
    URL: "",
    UUID: "",
    category: "",
    location: "",
    title: "",
    yearFrom: "",
    yearTo: "",
  };

  Object.keys(fieldsState).forEach((key) => {
    if (location.search.includes(key)) {
      fieldsState[key] = location.search.split(`${key}=`).pop()?.split("&")[0];
    }
  });
  return fieldsState;
};

type TPaginationState = typeof DEFAULT_SEARCH_PAGINATION_STATE;

export const computeNextURL = ({
  searchPaginationState,
  searchFieldsState,
  defaultSearchPaginationState,
}: {
  searchPaginationState?: typeof DEFAULT_SEARCH_PAGINATION_STATE;
  searchFieldsState?: typeof DEFAULT_SEARCH_FIELDS_STATE;
  defaultSearchPaginationState?: Partial<TPaginationState>;
}) => {
  const currentURLParams = getURLParamsObjectByLocation(window.location);
  const updatedKeyValuePairs: any = {};
  if (searchPaginationState) {
    Object.entries(searchPaginationState).forEach(([key, value]) => {
      let conditionToCheck =
        defaultSearchPaginationState &&
        defaultSearchPaginationState[
          key as keyof typeof DEFAULT_SEARCH_PAGINATION_STATE
        ]
          ? defaultSearchPaginationState[
              key as keyof typeof DEFAULT_SEARCH_PAGINATION_STATE
            ] !== value
          : DEFAULT_SEARCH_PAGINATION_STATE[
              key as keyof typeof DEFAULT_SEARCH_PAGINATION_STATE
            ] !== value;
      conditionToCheck
        ? (updatedKeyValuePairs[key] = value)
        : delete currentURLParams[key];
    });
  }
  if (searchFieldsState) {
    Object.entries(searchFieldsState).forEach(([key, value]) =>
      DEFAULT_SEARCH_FIELDS_STATE[
        key as keyof typeof DEFAULT_SEARCH_FIELDS_STATE
      ] !== value
        ? (updatedKeyValuePairs[key] = value)
        : delete currentURLParams[key]
    );
  }
  let updatedURLParamsObject = {
    ...currentURLParams,
    ...updatedKeyValuePairs,
  };
  return getURLSearchByParamsObject(updatedURLParamsObject);
};
