import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { ITableRow } from "./Table";
import { Column, HeadCell, Order } from "./Table.types";

const prepareColumns = (columnsArray: Column[]): HeadCell[] => {
  return (columnsArray || []).map(
    ({ title, id }, i): HeadCell => ({
      id,
      numeric: false,
      label: title,
    })
  );
};

interface EnhancedTableProps {
  columns: Column[];
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ITableRow
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, columns } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {prepareColumns(columns).map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className="visually-hidden">
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
