import "./ExhibitResultsRow.style.scss";
import { Link, useNavigate } from "react-router-dom";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { Checkbox, IconButton } from "@mui/material";
import { Exhibit, ExhibitSearch } from "../../../types";
import { formatLocation } from "../../../utils/format";
import { computeImageURL } from "../../../utils/URL";

interface ExhibitRowProps {
  exhibit: ExhibitSearch;
  fields: string[];
  pageLoaded: boolean;
  selectable?: boolean;
  selectedExhibits?: Exhibit[];
  setSelectedExhibits: any;
  hideActionButtons?: boolean;
}

const ExhibitRow = ({
  exhibit,
  fields,
  pageLoaded,
  selectable,
  selectedExhibits,
  setSelectedExhibits,
  hideActionButtons,
}: ExhibitRowProps) => {
  const navigate = useNavigate();
  const { UUID, URLSlug, id, icon, images, location, title, yearCompleted } =
    exhibit;
  const thumbnailURL = computeImageURL(images, UUID, "sm");

  const onRowClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (selectable) {
      e.preventDefault();
      let updatedSelectedExhibits: ExhibitSearch[] = [];
      let rowAlreadyChecked = selectedExhibits?.find(
        (el: ExhibitSearch) => el.id === exhibit.id
      );
      if (rowAlreadyChecked) {
        let updatedSelectedExhibits = [...(selectedExhibits || [])];
        updatedSelectedExhibits.splice(
          updatedSelectedExhibits?.findIndex(
            (el: ExhibitSearch) => el.id === exhibit.id
          ) || 0,
          1
        );
        setSelectedExhibits(
          updatedSelectedExhibits.sort((a, b) => a.id - b.id)
        );
      } else {
        updatedSelectedExhibits = [...(selectedExhibits || []), exhibit];
        setSelectedExhibits(
          updatedSelectedExhibits.sort((a, b) => a.id - b.id)
        );
      }
    }
  };

  const isCheckboxChecked = !!(selectedExhibits || []).find(
    (el: ExhibitSearch) => el.id === exhibit.id
  );

  const onIconButtonPreviewClick = (e: any) => {
    e.preventDefault();
    const { lat, lng, UUID } = exhibit;
    navigate(`/exhibitPreview?exhibit=${UUID}&lat=${lat}&lng=${lng}&zoom=${4}`);
    console.log(exhibit, "exhibit");
  };

  return (
    <Link
      to={`/exhibitDetails?exhibitUUID=${UUID}`}
      className="exhibit-row-link"
      onClick={onRowClick}
    >
      <div
        className={`exhibit-row-wrap ${isCheckboxChecked ? "selected" : ""}`}
      >
        {selectable ? (
          <div
            className={`exhibit-row-cell id ${!pageLoaded ? "loading" : ""}`}
          >
            <Checkbox
              {...{ inputProps: { "aria-label": "Checkbox demo" } }}
              checked={isCheckboxChecked}
            />
          </div>
        ) : null}
        {fields.includes("id") ? (
          <div
            className={`exhibit-row-cell id ${!pageLoaded ? "loading" : ""}`}
          >
            <span>{id}</span>
          </div>
        ) : null}
        {fields.includes("image") ? (
          <div
            className={`exhibit-row-cell image ${!pageLoaded ? "loading" : ""}`}
          >
            {pageLoaded ? (
              <img src={thumbnailURL} alt={title} className="front" />
            ) : (
              <div className="img-loading-wrap">
                <img src={thumbnailURL} alt={title} />
              </div>
            )}
          </div>
        ) : null}
        {fields.includes("title") ? (
          <div
            className={`exhibit-row-cell title ${!pageLoaded ? "loading" : ""}`}
          >
            <span>{title}</span>
          </div>
        ) : null}
        {fields.includes("location") ? (
          <div
            className={`exhibit-row-cell location ${
              !pageLoaded ? "loading" : ""
            }`}
          >
            <span>{formatLocation(location)}</span>
          </div>
        ) : null}
        {fields.includes("yearCompleted") ? (
          <div
            className={`exhibit-row-cell yearCompleted ${
              !pageLoaded ? "loading" : ""
            }`}
          >
            <span>{yearCompleted}</span>
          </div>
        ) : null}
        {fields.includes("UUID") ? (
          <div
            className={`exhibit-row-cell UUID ${!pageLoaded ? "loading" : ""}`}
          >
            <span>{UUID}</span>
          </div>
        ) : null}
        {fields.includes("URLSlug") ? (
          <div
            className={`exhibit-row-cell URLSlug ${
              !pageLoaded ? "loading" : ""
            }`}
          >
            <span>{URLSlug}</span>
          </div>
        ) : null}
        {fields.includes("icon") ? (
          <div
            className={`exhibit-row-cell icon ${!pageLoaded ? "loading" : ""}`}
          >
            <span>{icon}</span>
          </div>
        ) : null}
        {hideActionButtons ? null : (
          <div
            className={`exhibit-row-cell actions ${
              !pageLoaded ? "loading" : ""
            }`}
          >
            <IconButton onClick={onIconButtonPreviewClick}>
              <Visibility />
            </IconButton>
            <IconButton>
              <Edit />
            </IconButton>
            <IconButton>
              <Delete />
            </IconButton>
          </div>
        )}
      </div>
    </Link>
  );
};

export default ExhibitRow;
