import React, { createContext, useState } from "react";
import { AlertColor } from "@mui/material";

interface Alert {
  severity: AlertColor;
  title: string;
  message: string;
  id: number;
}

export type ContextType = {
  alerts: Alert[];
  addAlert: (alert: Alert) => void;
  removeAlert: (id: number) => void;
};

const AlertContext = createContext<ContextType | null>(null);

const AlertProvider = (props: any) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const addAlert = (alert: Alert) => {
    setAlerts([...alerts, alert]);
  };

  const removeAlert = (id: number) => {
    const newArr = [...alerts].filter((object) => {
      return object.id !== id;
    });
    setAlerts([...newArr]);
  };

  const alertContextValue = {
    alerts,
    addAlert,
    removeAlert,
  };

  return <AlertContext.Provider value={alertContextValue} {...props} />;
};

const useAlert = () => React.useContext(AlertContext);

export { AlertProvider, useAlert };
