import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Drawer } from "@mui/material";
import api from "../../../utils/api";

//TODO refactor global error type
interface Error {
  msg: string;
}

const ModalCategoryDelete = ({
  activeCategoryTree,
  isModalCategoryDeleteOpen,
  onCategoryDeleteSuccess,
  setIsModalCategoryDeleteOpen,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);

  useEffect(() => {
    setErrors([]);
  }, [isModalCategoryDeleteOpen]);

  const onButtonDeleteCategoryClick = async () => {
    console.log("activeCategoryTree", activeCategoryTree);
    try {
      setErrors([]);
      setLoading(true);
      await api.post("/category/delete", {
        categoryId: activeCategoryTree[0].id,
      });
      setLoading(false);
      onCategoryDeleteSuccess();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
      }
    }
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalCategoryDeleteOpen}
      onClose={() => setIsModalCategoryDeleteOpen(false)}
    >
      <div className="modal-wrap">
        <header>
          <h2>{`Delete Filter Option`}</h2>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <h3>{`Are you sure you want to delete the category: ${
              activeCategoryTree?.length && activeCategoryTree[0]?.title
            }`}</h3>
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonDeleteCategoryClick}
              disabled={loading}
              color="error"
            >
              {`Delete Category`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalCategoryDelete;
