import "./RelatedExhibits.style.scss";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Close, Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import NavStepHeader from "../../../../components/reusable/NavStepHeader/NavStepHeader";
import { Exhibit, ExhibitSearch } from "../../../../types";
import api from "../../../../utils/api";
import ExhibitsTable from "../../../Exhibits/components/ExhibitsTable";
import SKELETON_EXHIBITS from "../../../Exhibits/DATA/SKELETON_EXHIBITS.json";
import {
  computeNextURL,
  getFieldsStateFromQueryParams,
  getPaginationStateFromQueryParams,
} from "../../../Exhibits/Exhibit.utils";
import {
  DEFAULT_SEARCH_FIELDS_STATE,
  DEFAULT_SEARCH_PAGINATION_STATE,
} from "../../../Exhibits/Exhibits";
import RelatedExhibitsAccordion from "./RelatedExhibitsAccordion";

const skeletonExhibits: ExhibitSearch[] = SKELETON_EXHIBITS.data;

interface RelatedExhibitsProps {
  tableSelectedExhibits: Exhibit[];
  setTableSelectedExhibits: any;
}

const RelatedExhibits = ({
  tableSelectedExhibits,
  setTableSelectedExhibits,
}: RelatedExhibitsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [exhibits, setExhibits] = useState<ExhibitSearch[]>(skeletonExhibits);
  const [exhibitsCount, setExhibitsCount] = useState<number>(0);
  const [searchPaginationState, setSearchPaginationState] = useState<
    typeof DEFAULT_SEARCH_PAGINATION_STATE
  >(getPaginationStateFromQueryParams(location, { defaultPageSize: 5 }));
  const [searchFieldsState, setSearchFieldsState] = useState<
    typeof DEFAULT_SEARCH_FIELDS_STATE
  >(getFieldsStateFromQueryParams(location));

  useEffect(() => {
    console.log("current selected exhibits", tableSelectedExhibits);
  }, [tableSelectedExhibits]);

  useEffect(() => {
    let nextURL = computeNextURL({
      searchPaginationState,
      defaultSearchPaginationState: {
        pageSize: 5,
      },
    });
    navigate(`${window.location.pathname}?${nextURL}`);
  }, [searchPaginationState]);

  useEffect(() => {
    const searchExhibits = async (query: string) => {
      try {
        const exhibitsResults = await api.get(
          `/exhibits/searchAdmin${
            query ? `${query}&screen=exhibitCreate` : "?screen=exhibitCreate"
          }`
        );
        setExhibits(exhibitsResults?.data?.data);
        setExhibitsCount(exhibitsResults?.data?.count);
        if (!pageLoaded) {
          setPageLoaded(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
    searchExhibits(location.search);
  }, [location.search]);

  const onTextFieldChange = ({
    field,
    value,
  }: {
    field: string;
    value: string;
  }) => {
    setSearchFieldsState((searchFieldsState) => ({
      ...searchFieldsState,
      [field]: value,
    }));
  };

  const onSearch = () => {
    setSearchPaginationState({
      ...DEFAULT_SEARCH_PAGINATION_STATE,
      pageSize: 5,
    });
    let nextURL = computeNextURL({
      searchFieldsState,
    });
    navigate(`/exhibitCreate?${nextURL}`);
  };

  const renderSectionSearch = () => {
    const tableViewFields = ["image", "title", "location", "yearCompleted"];
    return (
      <div className="related-exhibits-wrap">
        <TextField
          label="Search term"
          variant="outlined"
          value={searchFieldsState.title}
          onChange={({ target: { value } }) =>
            onTextFieldChange({ field: "title", value })
          }
          autoComplete={"off"}
          fullWidth={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <div className="adornment-wrap">
                  {searchFieldsState.title ? (
                    <button
                      className="icon-close-wrap"
                      onClick={() =>
                        onTextFieldChange({ field: "title", value: "" })
                      }
                    >
                      <Close />
                    </button>
                  ) : null}
                  <Search />
                </div>
              </InputAdornment>
            ),
          }}
          sx={{ mb: 2 }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onSearch();
            }
          }}
        />
        <ExhibitsTable
          exhibits={exhibits}
          exhibitsCount={exhibitsCount}
          pageLoaded={pageLoaded}
          searchPaginationState={searchPaginationState}
          setSearchPaginationState={setSearchPaginationState}
          tableViewFields={tableViewFields}
          selectable={true}
          selectedExhibits={tableSelectedExhibits}
          setSelectedExhibits={setTableSelectedExhibits}
          hideActionButtons={true}
        />
      </div>
    );
  };

  return (
    <section>
      <NavStepHeader stepNumber={8} />
      <RelatedExhibitsAccordion
        renderSectionSearch={renderSectionSearch}
        selectedExhibits={tableSelectedExhibits}
        setSelectedExhibits={setTableSelectedExhibits}
      />
    </section>
  );
};

export default RelatedExhibits;
