import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Drawer, TextField } from "@mui/material";
import { NumericGroup } from "../../../types";
import api from "../../../utils/api";

interface Error {
  msg: string;
}

interface ModalNumericGroupEditProps {
  numericGroup: any;
  isModalOpen: any;
  setIsModalOpen: any;
  onSuccess: () => void;
}

const ModalNumericGroupEdit = ({
  numericGroup,
  isModalOpen,
  setIsModalOpen,
  onSuccess,
}: ModalNumericGroupEditProps) => {
  const [URLSlug, setURLSlug] = useState("");
  const [sort, setSort] = useState(0);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);

  useEffect(() => {
    setURLSlug(numericGroup?.URLSlug || "");
    setSort(numericGroup?.sort || 0);
    setTitle(numericGroup?.title || "");
  }, [numericGroup]);

  useEffect(() => {
    setErrors([]);
  }, [isModalOpen]);

  const onButtonEditClick = async () => {
    try {
      setErrors([]);
      setLoading(true);
      await api.post("/numericGroup/update", {
        URLSlug,
        numericGroupId: numericGroup.id,
        sort,
        title,
      });
      setLoading(false);
      onSuccess();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <div className="modal-wrap">
        <header>
          <h2>{`Edit Numeric Group`}</h2>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Title"
              variant="outlined"
              value={title}
              onChange={({ target: { value } }) => setTitle(value)}
              fullWidth={true}
              type={`text`}
              autoFocus
            />
            <TextField
              label="URL slag"
              variant="outlined"
              value={URLSlug}
              onChange={({ target: { value } }) => setURLSlug(value)}
              fullWidth={true}
              type={`text`}
            />
            <TextField
              label="Sort"
              variant="outlined"
              value={sort}
              onChange={({ target: { value } }) => setSort(parseInt(value))}
              fullWidth={true}
              type={`number`}
            />
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonEditClick}
              disabled={loading}
            >
              {`Edit Numeric Group`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalNumericGroupEdit;
