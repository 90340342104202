import "./HeroBannerSearchLayout.style.scss";
import { Add, Search } from "@mui/icons-material";
import { Button, Container, Link, Paper } from "@mui/material";
import HeroBanner from "../HeroBanner/HeroBanner";

interface HeroBannerSearchLayoutProps {
  subtitle?: string;
  buttonActionTitle?: string;
  title: string;
  children: any;
  onButtonSearchClick?: () => any;
  onButtonCreateClick?: () => any;
  onLinkClearFieldsClick?: (e: any) => any;
  hideClearFields?: boolean;
  buttonActionIcon?: any;
}

const HeroBannerSearchLayout = ({
  subtitle,
  buttonActionTitle,
  buttonActionIcon,
  title,
  children,
  onButtonSearchClick,
  onButtonCreateClick,
  onLinkClearFieldsClick,
  hideClearFields,
}: HeroBannerSearchLayoutProps) => {
  return (
    <div>
      <HeroBanner subtitle={subtitle} title={title} />
      <Container maxWidth="lg">
        <div className="wrap-search">
          <Paper>
            <div className="wrap-search-fields">{children}</div>
            <footer className="wrap-search-footer">
              {hideClearFields ? null : (
                <Link href="#" onClick={onLinkClearFieldsClick} color="inherit">
                  {`Clear fields`}
                </Link>
              )}
              {onButtonSearchClick ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onButtonSearchClick}
                  startIcon={<Search />}
                >
                  {`Search`}
                </Button>
              ) : null}
              {onButtonCreateClick ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onButtonCreateClick}
                  startIcon={buttonActionIcon || <Add />}
                >
                  {buttonActionTitle || `Create`}
                </Button>
              ) : null}
            </footer>
          </Paper>
        </div>
      </Container>
    </div>
  );
};

export default HeroBannerSearchLayout;
