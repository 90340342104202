import { Navigate, Outlet } from "react-router-dom";
import { User } from "../../types";
import Loader from "../reusable/Loader/Loader";

interface PrivateRouteProps {
  user: User | undefined;
  loading: boolean | undefined;
  redirectPath?: string;
  children?: any;
}

const PrivateRoute = ({
  user,
  loading,
  redirectPath = "/login",
  children,
}: PrivateRouteProps) => {
  if (loading) {
    return <Loader />;
  }
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};

export default PrivateRoute;
