import "./HeroBanner.style.scss";
import { Info } from "@mui/icons-material";
import { Button, Container } from "@mui/material";
import BackgroundCategories from "../../assets/images/categories.jpg";
import BackgroundExhibitCreate from "../../assets/images/exhibit_create.jpg";
import BackgroundExhibitDetails from "../../assets/images/exhibit_details.jpg";
import BackgroundExhibits from "../../assets/images/exhibits.jpg";
import BackgroundFilters from "../../assets/images/filters.jpg";
import BackgroundNumerics from "../../assets/images/numerics.jpg";

const TITLE_TO_BACKGROUND_IMAGE: any = {
  "Create Exhibit": BackgroundExhibitCreate,
  Exhibits: BackgroundExhibits,
  Filters: BackgroundFilters,
  Numerics: BackgroundNumerics,
  Categories: BackgroundCategories,
  "Exhibit Details": BackgroundExhibitDetails,
};

interface HeroBannerProps {
  title: string;
  subtitle?: string;
}

const HeroBanner = ({ title, subtitle }: HeroBannerProps) => {
  return (
    <div
      className="wrap-hero-banner"
      style={{ backgroundImage: `url(${TITLE_TO_BACKGROUND_IMAGE[title]})` }}
    >
      <div className="hero-banner-inner">
        <Container maxWidth="lg">
          <h1>{title}</h1>
          {subtitle ? <p className="subtitle">{subtitle}</p> : null}
          <div className="hero-version">
            <Button
              variant="text"
              color="inherit"
              onClick={() => null}
              startIcon={<Info />}
              style={{
                textShadow: "-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000",
              }}
            >
              {`version 1.3.54`}
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default HeroBanner;
