import { Grid, MenuItem, TextField } from "@mui/material";
import NavStepHeader from "../../../../components/reusable/NavStepHeader/NavStepHeader";
import ICON_OPTIONS from "../../../../data/ICON_OPTIONS";

const MainInformation = ({
  icon,
  setIcon,
  subtitle,
  title,
  yearCompleted,
  setTitle,
  setSubtitle,
  setYearCompleted,
}: any) => {
  return (
    <section>
      <NavStepHeader stepNumber={1} />
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={8}>
          <TextField
            label="Title"
            variant="outlined"
            value={title}
            onChange={({ target: { value } }) => setTitle(value)}
            fullWidth={true}
            required={true}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Year Completed"
            variant="outlined"
            value={yearCompleted}
            onChange={({ target: { value } }) => setYearCompleted(value)}
            fullWidth={true}
            type={`number`}
            required={true}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            label="Subtitle"
            variant="outlined"
            value={subtitle}
            onChange={({ target: { value } }) => setSubtitle(value)}
            fullWidth={true}
            multiline={true}
            required={true}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={icon}
            variant="outlined"
            onChange={(e) => setIcon(e.target.value)}
            select
            label={`Icon`}
            fullWidth={true}
            required={true}
            autoComplete="off"
          >
            {(ICON_OPTIONS || []).map(({ title, id }: any) => {
              return (
                <MenuItem key={id} value={id}>
                  {title}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
      </Grid>
    </section>
  );
};

export default MainInformation;
