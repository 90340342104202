import React from "react";
import { Exhibit, ExhibitSearch } from "../../../types";
import { DEFAULT_SEARCH_PAGINATION_STATE } from "../Exhibits";
import ExhibitPaginationRow from "./ExhibitPaginationRow";
import ExhibitResultsRow from "./ExhibitResultsRow";
import ExhibitsHeader from "./ExhibitsHeader";

type TPaginationState = typeof DEFAULT_SEARCH_PAGINATION_STATE;

interface ExhibitsTableProps {
  exhibitsCount: number;
  searchPaginationState: TPaginationState;
  pageLoaded: boolean;
  setSearchPaginationState: any;
  setIsFieldChooserOpen?: any;
  tableViewFields: any;
  exhibits: ExhibitSearch[];
  showButtonDownloadExcel?: boolean;
  selectable?: boolean;
  selectedExhibits?: Exhibit[];
  setSelectedExhibits?: any;
  hideActionButtons?: boolean;
}

const ExhibitsTable = ({
  exhibitsCount,
  searchPaginationState,
  pageLoaded,
  setSearchPaginationState,
  setIsFieldChooserOpen,
  tableViewFields,
  exhibits,
  showButtonDownloadExcel,
  selectable,
  selectedExhibits,
  setSelectedExhibits,
  hideActionButtons,
}: ExhibitsTableProps) => {
  const onIconButtonPageFirstClick = () =>
    setSearchPaginationState({
      ...searchPaginationState,
      pageNumber: 1,
    });

  const onIconButtonPageLastClick = () =>
    setSearchPaginationState({
      ...searchPaginationState,
      pageNumber: exhibitsCount
        ? Math.trunc(exhibitsCount / searchPaginationState.pageSize)
        : 1,
    });

  const onIconButtonPageNextClick = () =>
    setSearchPaginationState(({ pageNumber }: { pageNumber: any }) => ({
      ...searchPaginationState,
      pageNumber: pageNumber + 1,
    }));

  const onIconButtonPagePreviousClick = () =>
    setSearchPaginationState(({ pageNumber }: { pageNumber: any }) => ({
      ...searchPaginationState,
      pageNumber: pageNumber - 1,
    }));

  const onPageSizeChange = (pageSize: string) =>
    setSearchPaginationState({
      ...searchPaginationState,
      pageSize: parseInt(pageSize),
    });

  const onOpenModalFieldChooser = () =>
    setIsFieldChooserOpen ? setIsFieldChooserOpen(true) : null;

  const renderPaginationRow = () => (
    <ExhibitPaginationRow
      exhibitsCount={exhibitsCount}
      currentPageNumber={searchPaginationState.pageNumber}
      onIconButtonPageFirstClick={onIconButtonPageFirstClick}
      onIconButtonPageNextClick={onIconButtonPageNextClick}
      onIconButtonPagePreviousClick={onIconButtonPagePreviousClick}
      onIconButtonPageLastClick={onIconButtonPageLastClick}
      onOpenModalFieldChooser={
        setIsFieldChooserOpen ? onOpenModalFieldChooser : undefined
      }
      onPageSizeChange={onPageSizeChange}
      pageSize={searchPaginationState.pageSize}
      pageLoaded={pageLoaded}
      showButtonDownloadExcel={showButtonDownloadExcel}
    />
  );

  return (
    <section className="exhibit-results-table">
      {renderPaginationRow()}
      <ExhibitsHeader
        fields={tableViewFields}
        order={searchPaginationState.order}
        reverse={searchPaginationState.reverse}
        setSearchPaginationState={setSearchPaginationState}
        searchPaginationState={searchPaginationState}
        selectable={selectable}
        hideActionButtons={hideActionButtons}
      />
      {exhibits.map((exhibit) => (
        <React.Fragment key={exhibit.id}>
          <ExhibitResultsRow
            exhibit={exhibit}
            fields={tableViewFields}
            pageLoaded={pageLoaded}
            selectable={selectable}
            selectedExhibits={selectedExhibits}
            setSelectedExhibits={setSelectedExhibits}
            hideActionButtons={hideActionButtons}
          />
        </React.Fragment>
      ))}
      {renderPaginationRow()}
    </section>
  );
};

export default ExhibitsTable;
