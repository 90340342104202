import { Category } from "../../types";

export const findCategoriesIterationsIds = (
  categories: Category[]
): number[][] => {
  let iterationsIds: number[][] = [];
  if (categories.length) {
    let categoriesRows = Math.ceil(categories.length / 3);
    Array(categoriesRows)
      .fill(true)
      .forEach(() => {
        iterationsIds.push([0, 0, 0]);
      });
  }
  let level2CategoriesIds = categories
    .filter((el) => el.level === 2)
    .map((el) => el.id);
  let level2CategoriesParentIds = categories
    .filter((el) => el.level === 2)
    .map((el) => el.parentId);
  iterationsIds.forEach(
    (_, i) => (iterationsIds[i][2] = level2CategoriesIds[i])
  );
  iterationsIds.forEach((_, i) => {
    let findParent = categories.find(
      (el) => el.id === level2CategoriesParentIds[i]
    );
    let findParentId = findParent?.id || -1;
    let findParentParentId = findParent?.parentId || -1;
    iterationsIds[i][1] = findParentId;
    iterationsIds[i][0] = findParentParentId;
  });
  return iterationsIds;
};
