import React, { useEffect, useRef, useState } from "react";
import { Alert, Box, Button, Drawer, TextField } from "@mui/material";
import api from "../../../utils/api";

interface Error {
  msg: string;
}

const ModalCategoryEdit = ({
  category,
  isModalCategoryEditOpen,
  onCategoryEditSuccess,
  setIsModalCategoryEditOpen,
}: any) => {
  const [title, setTitle] = useState("");
  const [sort, setSort] = useState(0);
  const [URLSlug, setURLSlug] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);

  useEffect(() => {
    setTitle(category?.title);
    setSort(category?.sort);
    setURLSlug(category?.URLSlug);
  }, [category]);

  useEffect(() => {
    setErrors([]);
  }, [onCategoryEditSuccess]);

  const onButtonEditCategoryClick = async () => {
    try {
      setErrors([]);
      setLoading(true);
      await api.post("/category/update", {
        categoryId: category.id,
        sort,
        title,
        URLSlug,
      });
      setLoading(false);
      onCategoryEditSuccess();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalCategoryEditOpen}
      onClose={() => setIsModalCategoryEditOpen(false)}
    >
      <div className="modal-wrap">
        <header>
          <h2>{`Update Category`}</h2>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Title"
              onChange={({ target: { value } }) => setTitle(value)}
              variant="outlined"
              value={title}
              fullWidth={true}
              type={`text`}
              autoFocus={true}
            />
            <TextField
              label="URL Slug"
              onChange={({ target: { value } }) => setURLSlug(value)}
              variant="outlined"
              value={URLSlug}
              fullWidth={true}
              type={`text`}
            />
            <TextField
              label="Sort"
              onChange={({ target: { value } }) => setSort(parseInt(value))}
              variant="outlined"
              value={sort}
              fullWidth={true}
              type={`number`}
            />
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonEditCategoryClick}
              disabled={loading}
            >
              {`Update Category`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalCategoryEdit;
