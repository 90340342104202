import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import api from "../../../utils/api";

interface Error {
  msg: string;
}

const ModalFilterEdit = ({
  filter,
  isModalFilterEditOpen,
  setIsModalFilterEditOpen,
  onFilterEditSuccess,
}: any) => {
  const [URLSlug, setURLSlug] = useState("");
  const [sort, setSort] = useState(0);
  const [title, setTitle] = useState("");
  const [inputType, setInputType] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);

  useEffect(() => {
    setURLSlug(filter?.URLSlug || "");
    setInputType(filter?.inputType || "");
    setSort(filter?.sort || 0);
    setTitle(filter?.title || "");
  }, [filter]);

  useEffect(() => {
    setErrors([]);
  }, [isModalFilterEditOpen]);

  const onButtonFilterEditClick = async () => {
    try {
      setErrors([]);
      setLoading(true);
      await api.post("/filter/update", {
        URLSlug,
        filterId: filter.id,
        inputType,
        sort,
        title,
      });
      setLoading(false);
      onFilterEditSuccess();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalFilterEditOpen}
      onClose={() => setIsModalFilterEditOpen(false)}
    >
      <div className="modal-wrap">
        <header>
          <h2>{`Edit Filter`}</h2>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Title"
              variant="outlined"
              value={title}
              onChange={({ target: { value } }) => setTitle(value)}
              fullWidth={true}
              type={`text`}
              autoFocus
            />
            <TextField
              label="URL slag"
              variant="outlined"
              value={URLSlug}
              onChange={({ target: { value } }) => setURLSlug(value)}
              fullWidth={true}
              type={`text`}
            />
            <TextField
              label="Sort"
              variant="outlined"
              value={sort}
              onChange={({ target: { value } }) => setSort(parseInt(value))}
              fullWidth={true}
              type={`number`}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Input Type</InputLabel>
              <Select
                labelId="input-type"
                id="input-type"
                value={inputType}
                label="Input Type"
                onChange={({ target: { value } }) => setInputType(value)}
              >
                <MenuItem value={`checkbox`}>{`Checkbox`}</MenuItem>
                <MenuItem value={`radio`}>{`Radio`}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonFilterEditClick}
              disabled={loading}
            >
              {`Edit Filter`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalFilterEdit;
