import React from "react";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeItem, TreeView } from "@mui/lab";

const CategoriesTreeView = ({ categories, onTreeItemClick, loading }: any) => {
  const treeItemProps = (category: any) => {
    const { title, id } = category || {};
    return {
      key: `${id}`,
      nodeId: `${id}`,
      label: title,
      className: `tree-item`,
      onClick: () => onTreeItemClick(id),
    };
  };

  const treeCallback = (categoryId: any) => {
    return categories.filter((el: any) => el.parentId === categoryId);
  };

  if (loading) {
    return (
      <div>
        {Array(3)
          .fill(true)
          .map((_, i) => (
            <div
              key={`loading-bar-${i}`}
              className="tree-loading-bar"
            >{`Iconic Structures`}</div>
          ))}
      </div>
    );
  }

  return (
    <div>
      <TreeView
        aria-label="file system navigator"
        className="tree"
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
      >
        {treeCallback(0).map((category: any) => (
          <TreeItem {...treeItemProps(category)}>
            {treeCallback(category.id).map((category: any) => (
              <TreeItem {...treeItemProps(category)}>
                {treeCallback(category.id).map((category: any) => (
                  <TreeItem {...treeItemProps(category)}>
                    {treeCallback(category.id).map((category: any) => (
                      <TreeItem {...treeItemProps(category)} />
                    ))}
                  </TreeItem>
                ))}
              </TreeItem>
            ))}
          </TreeItem>
        ))}
      </TreeView>
    </div>
  );
};

export default CategoriesTreeView;
