import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import TheLayout from "../../containers/Layout";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const AppRouter = () => {
  return (
    <Router>
      <React.Suspense fallback={loading}>
        <TheLayout />
      </React.Suspense>
    </Router>
  );
};

export default AppRouter;
