import { Location } from "../../../../types";

export const prepareLocation = (locationData: any): Location => {
  let location = {
    country: "",
    region: "",
    place: "",
    district: "",
    locality: "",
    postcode: "",
  };
  (locationData || []).features.forEach((feature: any) => {
    if (feature.id.includes("country")) location.country = feature.text;
    if (feature.id.includes("region")) location.region = feature.text;
    if (feature.id.includes("place")) location.place = feature.text;
    if (feature.id.includes("district")) location.district = feature.text;
    if (feature.id.includes("locality")) location.locality = feature.text;
    if (feature.id.includes("postcode")) location.postcode = feature.text;
  });
  return location;
};
