import "./Map.geocoder.style.scss";
import "./Map.mapbox.style.scss";
import "./Map.style.scss";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef } from "react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import NavStepHeader from "../../../../components/reusable/NavStepHeader/NavStepHeader";
import { Location } from "../../../../types";
import { prepareLocation } from "./Map.utils";

// @ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "";

interface MapProps {
  location: Location | null;
  latitude: number;
  longitude: number;
  setLocation: any;
  setLatitude: any;
  setLongitude: any;
}

const Map = ({
  location,
  latitude,
  longitude,
  setLocation,
  setLatitude,
  setLongitude,
}: MapProps) => {
  const mapContainer = useRef<any>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const marker = useRef<any>(null);

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v10",
      center: [21, 46],
      zoom: 0.5,
    });
    marker.current = new mapboxgl.Marker();
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      })
    );
    map.current.on("click", addMarker);
    map.current.on("load", () => {
      if (map.current) return;
      map.current!.resize();
    });
  });

  useEffect(() => {
    if (marker && marker.current && latitude && longitude) {
      marker.current
        .setLngLat({ lat: latitude, lng: longitude })
        .addTo(map.current);
      updateLocationLiteral();
    }
  }, [marker, latitude, longitude]);

  const addMarker = (event: any) => {
    var coordinates = event.lngLat;
    marker.current.setLngLat(coordinates).addTo(map.current);
    setLatitude(coordinates.lat);
    setLongitude(coordinates.lng);
  };

  const onLongitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLongitude(parseFloat(e.target.value));
    const obj = marker.current.getLngLat();
    marker.current
      .setLngLat({
        lng: e.target.value,
        lat: obj?.lat ? obj.lat : 0,
      })
      .addTo(map.current);
  };

  const onLatitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLatitude(parseFloat(e.target.value));
    const obj = marker.current.getLngLat();
    marker.current
      .setLngLat({
        lng: obj?.lng ? obj.lng : 0,
        lat: e.target.value,
      })
      .addTo(map.current);
  };

  const updateLocationLiteral = () => {
    if (!latitude || !longitude) return;
    const requestLocationLiteral = async () => {
      try {
        let location = await axios.get(
          `${process.env.REACT_APP_MAPBOX_PLACES_API}/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
        );
        return location?.data;
      } catch (error) {
        console.error(error);
      }
    };
    (async () => {
      const location = await requestLocationLiteral();
      setLocation(prepareLocation(location));
    })();
  };

  return (
    <section>
      <NavStepHeader stepNumber={3} />
      <div ref={mapContainer} className="map-container" />
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h3>Location Field</h3>
                </TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ tableLayout: "auto" }}>
              {Object.entries(location || {}).map(([key, value]) => {
                return (
                  <TableRow key={key}>
                    <TableCell>
                      <span style={{ textTransform: "capitalize" }}>{key}</span>
                    </TableCell>
                    <TableCell width={300}>
                      <b>{value}</b>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6}>
          <h3 style={{ marginTop: 15 }}>Marker Coordinates</h3>
          <Box
            component="form"
            sx={{
              margin: "15px auto",
              "& > :not(style)": { mr: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Latitude"
              variant="outlined"
              value={latitude || ""}
              onChange={onLatitudeChange}
              type={"number"}
              InputLabelProps={{ shrink: true }}
              required={true}
            />
            <TextField
              label="Longitude"
              variant="outlined"
              value={longitude || ""}
              onChange={onLongitudeChange}
              type={"number"}
              InputLabelProps={{ shrink: true }}
              required={true}
            />
          </Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default Map;
