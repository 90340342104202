import "./NavBarAuth.style.scss";
import React from "react";
import {
  NavLink,
  useLoaderData,
  useLocation,
  useNavigate
} from "react-router-dom";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  Square
} from "@mui/icons-material";
import { Button, Container } from "@mui/material";
import { useAuth } from "../context/authContext";
import { useModals } from "../context/modalsContext";
import { getURLParamsObjectByLocation } from "../utils/URL";

const LinkActive = ({ to, children }: { to: string; children: any }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
    >
      {children}
    </NavLink>
  );
};

const NavBarAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentGlobalExhibit } = useModals() || {};
  const { logout, user } = useAuth() || {};

  const onButtonTogglePreviewClick = () => {
    const URLObjectParams = getURLParamsObjectByLocation(location);
    console.log(URLObjectParams, "params");
    const buttonPreviewIsChecked = location.pathname === "/exhibitPreview";
    const { lat, lng, UUID } = currentGlobalExhibit || {};
    if (buttonPreviewIsChecked) {
      navigate(`/exhibitDetails?exhibitUUID=${UUID}`);
    } else {
      navigate(`exhibitPreview?exhibit=${UUID}&lat=${lat}&lng=${lng}&zoom=4`);
    }
  };

  const showExhibitPreviewButton = [
    "/exhibitPreview",
    "/exhibitDetails",
  ].includes(location.pathname);
  const buttonPreviewIsChecked = location.pathname === "/exhibitPreview";

  return (
    <nav className="navbar-wrap">
      <Container maxWidth="xl">
        <div className="navbar-auth">
          <div className="left">
            <div className="logo">Misty Map</div>
            <div className="links">
              <LinkActive to="/exhibits">{`Exhibits`}</LinkActive>
              <LinkActive to="/exhibitCreate">{`New Exhibit`}</LinkActive>
              <LinkActive to="/categories">{`Categories`}</LinkActive>
              <LinkActive to="/filters">{`Filters`}</LinkActive>
              <LinkActive to="/numerics">{`Numerics`}</LinkActive>
              <LinkActive to="/settings">{`Settings`}</LinkActive>
            </div>
            {showExhibitPreviewButton ? (
              <div className="button-preview-wrap">
                <Button
                  variant="text"
                  color="inherit"
                  onClick={onButtonTogglePreviewClick}
                  startIcon={
                    buttonPreviewIsChecked ? (
                      <CheckBoxOutlined />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )
                  }
                  style={{
                    textShadow:
                      "-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000",
                  }}
                >
                  {`Exhibit Preview`}
                </Button>
              </div>
            ) : null}
          </div>
          <div className="right">
            <strong>{user?.firstName}</strong>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => logout && logout()}
            >
              Logout
            </Button>
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default NavBarAuth;
