import { useState } from "react";
import { Button } from "@mui/material";
import api from "../../utils/api";

const Settings = () => {
  const [timeToReIndexCommonFilters, setTimeToReIndexCommonFilters] =
    useState(0);

  const onReIndexCommonFiltersAndFiltersOptions = async () => {
    setTimeToReIndexCommonFilters(0);
    try {
      const JSONResponse: any = (
        await api.get("/v1/navigation/filtersByCategory")
      )?.data;
      const { timeToCompute } = JSONResponse || {};
      console.log(JSONResponse, "JSONResponse");
      setTimeToReIndexCommonFilters(timeToCompute || 0);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main id="page-settings">
      <h1>Settings</h1>
      <section>
        <h2>Cache</h2>
        <div>
          <p style={{ marginBottom: 10 }}>
            1. Common Filters / Filter options numbers per Selected Categories
            combination
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={onReIndexCommonFiltersAndFiltersOptions}
          >
            {`Re-index common filters`}
          </Button>
          {timeToReIndexCommonFilters ? (
            <p style={{ marginTop: 10 }}>
              Time to re-index: {timeToReIndexCommonFilters}
            </p>
          ) : null}
        </div>
      </section>
    </main>
  );
};

export default Settings;
