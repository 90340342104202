import "./Exhibits.style.scss";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Grid, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import HeroBannerSearchLayout from "../../components/HeroBannerSearchLayout/HeroBannerSearchLayout";
import ICON_OPTIONS from "../../data/ICON_OPTIONS";
import { ExhibitSearch } from "../../types";
import api from "../../utils/api";
import ExhibitsTable from "./components/ExhibitsTable";
import DEFAULT_FIELDS from "./DATA/DEFAULT_FIELDS.json";
import SKELETON_EXHIBITS from "./DATA/SKELETON_EXHIBITS.json";
import {
  computeNextURL,
  getFieldsStateFromQueryParams,
  getPaginationStateFromQueryParams,
} from "./Exhibit.utils";
import FieldsChooser from "./modals/FieldsChooser";

const skeletonExhibits: ExhibitSearch[] = SKELETON_EXHIBITS.data;

export const DEFAULT_SEARCH_PAGINATION_STATE = {
  pageNumber: 1,
  pageSize: 10,
  order: "id",
  reverse: false,
};

export const DEFAULT_SEARCH_FIELDS_STATE = {
  URL: "",
  UUID: "",
  category: "",
  location: "",
  title: "",
  yearFrom: "",
  yearTo: "",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const categoriesNames = ICON_OPTIONS.map((el) => el.title);

const Exhibits = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [tableViewFields, setTableViewFields] = useState(DEFAULT_FIELDS.fields);
  const [exhibits, setExhibits] = useState<ExhibitSearch[]>(skeletonExhibits);
  const [exhibitsCount, setExhibitsCount] = useState<number>(0);
  const [searchFieldsState, setSearchFieldsState] = useState<
    typeof DEFAULT_SEARCH_FIELDS_STATE
  >(getFieldsStateFromQueryParams(location));
  const [searchPaginationState, setSearchPaginationState] = useState<
    typeof DEFAULT_SEARCH_PAGINATION_STATE
  >(getPaginationStateFromQueryParams(location));
  const [isFieldChooserOpen, setIsFieldChooserOpen] = useState(false);

  const findIdArrayFromTitleArray = (titlesArray: string[]): string[] => {
    return titlesArray.map(
      (el) => ICON_OPTIONS.find((el2: any) => el2.title === el)?.id || ""
    );
  };

  const findTitleArrayFromIdArray = (idsArray: string[]): string[] => {
    return idsArray.map(
      (el) => ICON_OPTIONS.find((el2: any) => el2.id === el)?.title || ""
    );
  };

  const handleCategoryChange = (
    event: SelectChangeEvent<string | string[]>
  ) => {
    const {
      target: { value },
    } = event;
    setSearchFieldsState((searchFieldsState) => ({
      ...searchFieldsState,
      category:
        typeof value === "string"
          ? value
          : findIdArrayFromTitleArray(value).join(","),
    }));
  };

  useEffect(() => {
    let nextURL = computeNextURL({
      searchPaginationState,
    });
    navigate(`/exhibits?${nextURL}`);
  }, [searchPaginationState]);

  useEffect(() => {
    const searchExhibits = async (query: string) => {
      try {
        let d1 = Date.now();
        const exhibitsResults = await api.get(
          `/exhibits/searchAdmin${query ? query : ""}`
        );
        setExhibits(exhibitsResults?.data?.data);
        setExhibitsCount(exhibitsResults?.data?.count);
        let d2 = Date.now();
        if (d2 - d1 > 1500) {
          setPageLoaded(true);
        } else {
          setTimeout(() => {
            setPageLoaded(true);
          }, 1500 - (d2 - d1));
        }
      } catch (error) {
        console.error(error);
      }
    };
    searchExhibits(location.search);
  }, [location.search]);

  const onTextFieldChange = ({
    field,
    value,
  }: {
    field: string;
    value: string;
  }) => {
    setSearchFieldsState((searchFieldsState) => ({
      ...searchFieldsState,
      [field]: value,
    }));
  };

  const onSearch = () => {
    setSearchPaginationState(DEFAULT_SEARCH_PAGINATION_STATE);
    let nextURL = computeNextURL({
      searchFieldsState,
    });
    navigate(`/exhibits?${nextURL}`);
  };

  const onLinkClearFieldsClick = (e: any) => {
    e.preventDefault();
    setSearchFieldsState(DEFAULT_SEARCH_FIELDS_STATE);
  };

  const categoryValues = findTitleArrayFromIdArray(
    searchFieldsState.category.split(",")
  ).filter((el) => !!el);

  const onCloseModalFieldChooser = () => setIsFieldChooserOpen(false);

  const onButtonFieldChooserClick = (field: string) => {
    setTableViewFields(
      tableViewFields.includes(field)
        ? tableViewFields.filter((el) => el !== field)
        : [...tableViewFields, field]
    );
  };

  const onButtonFieldChooserReset = () =>
    setTableViewFields(DEFAULT_FIELDS.fields);

  return (
    <main className="search-fields-exhibits">
      <FieldsChooser
        open={isFieldChooserOpen}
        onButtonFieldChooserClick={onButtonFieldChooserClick}
        onButtonFieldChooserReset={onButtonFieldChooserReset}
        onClose={onCloseModalFieldChooser}
        tableViewFields={tableViewFields}
      />
      <HeroBannerSearchLayout
        onButtonSearchClick={onSearch}
        onLinkClearFieldsClick={onLinkClearFieldsClick}
        subtitle="The Misty Map library includes all exhibits registered since 2022. You may search for information concerning exhibits and titles which are still in force or have been withdrawn or terminated."
        title="Exhibits"
      >
        <>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Title"
                fullWidth={true}
                variant="outlined"
                autoComplete="off"
                value={searchFieldsState.title}
                onChange={({ target: { value } }) =>
                  onTextFieldChange({ field: "title", value })
                }
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                className="search-field-year search-year-from"
                label="Year From"
                onChange={({ target: { value } }) =>
                  onTextFieldChange({ field: "yearFrom", value })
                }
                autoComplete="off"
                variant="outlined"
                value={searchFieldsState.yearFrom}
                fullWidth={true}
                type={`number`}
              />
            </Grid>
            <Grid item xs={2} className="wrap-search-field-year">
              <TextField
                className="search-field-year search-year-to"
                label="Year To"
                onChange={({ target: { value } }) =>
                  onTextFieldChange({ field: "yearTo", value })
                }
                autoComplete="off"
                variant="outlined"
                value={searchFieldsState.yearTo}
                fullWidth={true}
                type={`number`}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="URL"
                fullWidth={true}
                autoComplete="off"
                variant="outlined"
                value={searchFieldsState.URL}
                onChange={({ target: { value } }) =>
                  onTextFieldChange({ field: "URL", value })
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 0 }}>
            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Category
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={categoryValues}
                  onChange={handleCategoryChange}
                  input={<OutlinedInput label="Category" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {categoriesNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={categoryValues.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Location"
                fullWidth={true}
                autoComplete="off"
                variant="outlined"
                value={searchFieldsState.location}
                onChange={({ target: { value } }) =>
                  onTextFieldChange({ field: "location", value })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="UUID"
                onChange={({ target: { value } }) =>
                  onTextFieldChange({ field: "UUID", value })
                }
                autoComplete="off"
                variant="outlined"
                value={searchFieldsState.UUID}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </>
      </HeroBannerSearchLayout>
      <Container maxWidth="xl">
        <div className="table-wrap" style={{ marginBottom: 80 }}>
          <ExhibitsTable
            exhibits={exhibits}
            exhibitsCount={exhibitsCount}
            pageLoaded={pageLoaded}
            searchPaginationState={searchPaginationState}
            setIsFieldChooserOpen={setIsFieldChooserOpen}
            setSearchPaginationState={setSearchPaginationState}
            showButtonDownloadExcel={true}
            tableViewFields={tableViewFields}
          />
        </div>
      </Container>
    </main>
  );
};

export default Exhibits;
