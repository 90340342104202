import "./Login.style.scss";
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Logo from "../../assets/images/logo512_dark.png";
import { useAlert } from "../../context/alertContext";
import { useAuth } from "../../context/authContext";
import api from "../../utils/api";

const Login = () => {
  const navigate = useNavigate();
  const { addAlert } = useAlert() || {};
  const { login } = useAuth() || {};
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const onSubmit = async () => {
    const email = emailRef.current!.value;
    const password = passwordRef.current!.value;
    try {
      const JSONResponse = await api.post("/user/login", {
        email,
        password,
      });
      if (JSONResponse?.data?.data === "OK") {
        login && (await login());
        navigate("/exhibits");
      } else {
        addAlert &&
          addAlert({
            severity: "error",
            id: Date.now(),
            title: "Error",
            message: "Something went wrong. Please try again later",
          });
      }
    } catch (error) {
      addAlert &&
        addAlert({
          severity: "error",
          id: Date.now(),
          title: "Error",
          message: "Something went wrong. Please try again later",
        });
    }
  };

  return (
    <main id="page-login">
      <div className="inner-wrap">
        <div className="logo-wrap">
          <img src={Logo} alt="logo" />
        </div>
        <h1>{`Sign in to Misty Map`}</h1>
        <div className="login-form-wrap">
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              width: 300,
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              variant="outlined"
              id="email"
              label="Email"
              type="email"
              inputRef={emailRef}
              fullWidth={true}
            />
            <TextField
              variant="outlined"
              id="password"
              label="Password"
              type="password"
              autoComplete="new-password"
              inputRef={passwordRef}
              fullWidth={true}
            />
            <Button
              variant="contained"
              onClick={() => onSubmit()}
              fullWidth={true}
            >
              {`Submit`}
            </Button>
          </Box>
        </div>
        <div className="sign-up-wrap">
          <span>{`Need admin access?`}</span>
          <Link to="/signup">{`Create an account`}</Link>
        </div>
        <div className="footer-links">
          <Link to="/signup">{`Reset password`}</Link>
          <Link to="/signup">{`Security`}</Link>
          <Link to="/signup">{`Contact MistyMap`}</Link>
        </div>
      </div>
    </main>
  );
};

export default Login;
