import "./NavSteps.style.scss";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const stepTitles = [
  "Main Information",
  "Image List",
  "Location",
  "Categories",
  "Filters",
  "Numeric Fields",
  "External Links",
  "Related Exhibits",
];

interface NavStepsProps {
  activeStepNumber: number;
  hideStepNumbers?: boolean;
  validatedSteps: boolean[];
  setActiveStepNumber: (stepNumber: number) => any;
}

const NavSteps = ({
  activeStepNumber,
  hideStepNumbers,
  validatedSteps,
  setActiveStepNumber,
}: NavStepsProps) => {
  const onNavigationNumberClick = (stepNumber: number) =>
    setActiveStepNumber(stepNumber);

  const onButtonPreviousClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setActiveStepNumber(activeStepNumber - 1);
  };

  const onButtonNextClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setActiveStepNumber(activeStepNumber + 1);
  };

  return (
    <nav className="exhibit-navigation">
      <button
        className="exhibit-navigation-arrow-button left"
        disabled={activeStepNumber < 2}
        onClick={onButtonPreviousClick}
      >
        <KeyboardArrowLeft />
        Previous
      </button>
      {!hideStepNumbers ? (
        <div className="exhibit-navigation-numbers-wrap">
          {stepTitles.map((el, i) => (
            <div
              className={`exhibit-navigation-number-wrap ${
                activeStepNumber === i + 1 ? "active" : ""
              } ${validatedSteps[i] === true ? "validated" : "not-validated"}`}
            >
              <button
                className="exhibit-navigation-number"
                onClick={() => onNavigationNumberClick(i + 1)}
              >{`0${i + 1}`}</button>
              <span className="exhibit-navigation-number-hover">{`0${i + 1}. ${
                stepTitles[i]
              }`}</span>
            </div>
          ))}
        </div>
      ) : null}
      <button
        className="exhibit-navigation-arrow-button right"
        disabled={activeStepNumber > 7}
        onClick={onButtonNextClick}
      >
        Next <KeyboardArrowRight />
      </button>
    </nav>
  );
};

export default NavSteps;
