import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Drawer, TextField } from "@mui/material";
import api from "../../../utils/api";

const ModalNumericGroupOptionDelete = ({
  numericGroupOption,
  isModalOpen,
  onSuccess,
  setIsModalOpen,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);
  const { title } = numericGroupOption || {};

  useEffect(() => {
    setErrors([]);
  }, [isModalOpen]);

  const onButtonDeleteFilterOptionClick = async () => {
    try {
      setErrors([]);
      setLoading(true);
      await api.post("/numericGroupOption/delete", {
        numericGroupOptionId: numericGroupOption.id,
      });
      setLoading(false);
      onSuccess();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      key={JSON.stringify(isModalOpen)}
    >
      <div className="modal-wrap">
        <header>
          <h2>{`Delete Numeric Option`}</h2>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <h3>{`Are you sure you want to delete the Numeric Group Option: ${title}`}</h3>
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {errors.map(({ msg }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonDeleteFilterOptionClick}
              disabled={loading}
              color="error"
            >
              {`Delete Numeric Option`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalNumericGroupOptionDelete;
