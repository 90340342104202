import { blue, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  palette: {
    primary: blue,
    secondary: pink,
  },
  typography: {
    h2: {
      fontSize: "1.9rem",
    },
    h3: {
      fontSize: "1.6rem",
    },
  },
});
