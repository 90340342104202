type ImageSize = "xs" | "md" | "sm" | "lg" | "xl";

interface URLparams {
  [key: string]: string;
}

export const computeImageURL = (images: any, UUID: string, size: ImageSize) => {
  const { dir, keys, sizes } = images || {};
  let URL = "";
  let indexXs = (sizes || []).findIndex((el: any) => el.includes(size));
  if (indexXs !== -1) {
    let key = keys[indexXs];
    let direction = dir[indexXs];
    const viewImageKey = `${key}-${direction}-${size}`;
    URL = `${process.env.REACT_APP_IMAGES_PATH}/${UUID}/${viewImageKey}`;
  }
  return URL;
};

export const computeImageURLs = (
  images: any,
  UUID: string,
  size: ImageSize
) => {
  const { dir, keys, sizes } = images || {};
  let smURLs: string[] = [];
  if (!keys.length) {
    return [];
  }
  (keys || []).forEach((key: string, i: number) => {
    const viewImageKey = `${key}-${dir[i]}-sm`;
    let URL = `${process.env.REACT_APP_IMAGES_PATH}/${UUID}/${viewImageKey}`;
    smURLs.push(URL);
  });
  return smURLs;
};

export const getURLParamsObjectByLocation = (location: any) => {
  const URLparams: URLparams = {};
  location.search &&
    location.search
      .split("?")
      .pop()
      .split("&")
      .forEach((keyEqualValue: string) => {
        let keyValue = keyEqualValue.split("=");
        URLparams[keyValue[0]] = keyValue[1];
      });
  return URLparams;
};

export const getURLSearchByParamsObject = (URLParamsObject: any) => {
  const keyEqualValues: string[] = [];
  Object.entries(URLParamsObject).forEach(([key, value]: [string, any]) => {
    if (value) {
      keyEqualValues.push(`${key}=${value}`);
    }
  });
  return keyEqualValues.join("&");
};

export const getNavigateStringToEditedURLParamsObject = (
  URLParamsObject: URLparams
) => {
  return `${window?.location.pathname}?${getURLSearchByParamsObject(
    URLParamsObject
  )}`;
};

export const getCoordsFromURL = (): {
  lng: number;
  lat: number;
  zoom: number;
} => {
  const URLParamsObject = getURLParamsObjectByLocation(window.location);
  let obj: any = {
    lng: 0,
    lat: 0,
    zoom: 0,
  };
  ["lng", "lat", "zoom"].forEach((key) => {
    if (URLParamsObject[key]) {
      obj[key] = parseFloat(URLParamsObject[key]);
    }
  });
  return obj;
};

export const getCurrentViewImageFromURL = (): {
  currentViewImage: number;
  URLParamsObject: any;
} => {
  const URLParamsObject = getURLParamsObjectByLocation(window.location);
  let currentViewImage = 0;
  if (URLParamsObject.viewImage) {
    currentViewImage = parseInt(URLParamsObject.viewImage[0]);
  }
  return {
    currentViewImage,
    URLParamsObject,
  };
};
