import "./FooterAuth.style.scss";
import { Link } from "react-router-dom";
import { Container, Link as MuiLink } from "@mui/material";

const FooterAuth = () => {
  return (
    <footer id="app-footer-auth">
      <Container maxWidth="xl">
        <div className="copy-wrap">© {new Date().getFullYear()} Misty Map</div>
        <div className="links">
          <Link to="/exhibits">
            <MuiLink color="inherit">Exhibits</MuiLink>
          </Link>
          <Link to="/exhibitCreate">
            <MuiLink color="inherit">New Exhibit</MuiLink>
          </Link>
          <Link to="/categories">
            <MuiLink color="inherit">Categories</MuiLink>
          </Link>
          <Link to="/filters">
            <MuiLink color="inherit">Filters</MuiLink>
          </Link>
          <Link to="/numerics">
            <MuiLink color="inherit">Numerics</MuiLink>
          </Link>
          <Link to="/settings">
            <MuiLink color="inherit">Settings</MuiLink>
          </Link>
          <Link to="/about">
            <MuiLink color="inherit">About</MuiLink>
          </Link>
        </div>
        <div className="right-wrap">
          <span
            style={{
              marginRight: 5,
              letterSpacing: "2px",
              fontWeight: "bold",
              fontSize: 13,
            }}
          >{`</>`}</span>
          <a href="https://merianos.dev">merianos.dev</a>
        </div>
      </Container>
    </footer>
  );
};

export default FooterAuth;
