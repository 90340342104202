import React, { createContext, useEffect, useState } from "react";
import { User } from "../types";
import api from "../utils/api";

export type ContextType = {
  user: User;
  loading: boolean;
  logout: () => void;
  login: () => void;
};

const AuthContext = createContext<ContextType | null>(null);

const AuthProvider = (props: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      //TODO: check logic for anonymous routes
      try {
        const JSONResponse = await api.get("/user");
        if (JSONResponse?.data?.user) {
          setUser({
            ...JSONResponse.data.user,
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const login = async () => {
    try {
      const JSONResponse = await api.get("/user");
      if (JSONResponse?.data?.user) {
        setUser({
          ...JSONResponse.data.user,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const logout = () => {
    (async () => {
      try {
        const JSONResponse = await api.get("/user/logout");
        if (JSONResponse.data.data === "OK") {
          setUser(null);
        }
      } catch (error) {
        console.error(error, "the error");
      }
    })();
  };

  const authContextValue = {
    login,
    user,
    loading,
    logout,
  };

  return <AuthContext.Provider value={authContextValue} {...props} />;
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
