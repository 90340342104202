import { FilterOption } from "../../../../types";

export const ADDITIONAL_INFO_UNITS: any = {
  height: "m",
  length: "m",
  yearStarted: "",
};

export const computeFiltersAndFilterOptions = (
  FilterOptions: FilterOption[] | undefined
) => {
  const filtersAndFilterOptions: any = {};
  (FilterOptions || []).forEach((filterOption) => {
    const { Filter, title: filterOptionTitle } = filterOption || {};
    const { title: filterTitle } = Filter || {};
    if (!filtersAndFilterOptions[filterTitle || ""]) {
      filtersAndFilterOptions[filterTitle || ""] = [];
    }
    filtersAndFilterOptions[filterTitle || ""].push(filterOptionTitle);
  });
  return filtersAndFilterOptions;
};
