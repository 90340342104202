import { TableFooter, TablePagination, TableRow } from "@mui/material";

interface CustomTableFooterProps {
  data: any;
  rowsPerPage: number;
  handleChangePage: any;
  handleChangeRowsPerPage: any;
  page: number;
  loading: boolean;
}

const CustomTableFooter = ({
  data,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  loading,
}: CustomTableFooterProps) => {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ page, count, from, to }) => {
            return loading ? (
              <>
                {`1-5 of `}
                <span
                  style={{
                    color: "#eee7e7",
                    background: "#eee7e7",
                    minWidth: 25,
                    display: "inline-block",
                  }}
                >
                  340
                </span>
              </>
            ) : (
              <>
                {`${from}-${to} of `}
                <span style={{ minWidth: 25, display: "inline-block" }}>
                  {count}
                </span>
              </>
            );
          }}
          showFirstButton={true}
          showLastButton={true}
          labelRowsPerPage={<span>Rows:</span>}
        />
      </TableRow>
    </TableFooter>
  );
};

export default CustomTableFooter;
