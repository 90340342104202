import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Alert, Box, Button, Drawer, TextField } from "@mui/material";
import api from "../../../utils/api";

interface Error {
  msg: string;
}

interface ModalNumericGroupDeleteProps {
  numericGroupId: number;
  title: string;
  isModalOpen: boolean;
  onSuccess: () => void;
  setIsModalOpen: (value: boolean) => void;
}

const ModalNumericGroupDelete = ({
  numericGroupId,
  title,
  isModalOpen,
  onSuccess,
  setIsModalOpen,
}: ModalNumericGroupDeleteProps) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);

  useEffect(() => {
    setErrors([]);
  }, [isModalOpen]);

  const onButtonDeleteFilterClick = async () => {
    try {
      setErrors([]);
      setLoading(true);
      await api.post("/numericGroup/delete", {
        numericGroupId,
      });
      setLoading(false);
      onSuccess();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <div className="modal-wrap">
        <header>
          <h2>{`Delete Filter Option`}</h2>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <h3>{`Are you sure you want to delete the Numeric Group: ${title}`}</h3>
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonDeleteFilterClick}
              disabled={loading}
            >
              {`Delete Numeric Group`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalNumericGroupDelete;
