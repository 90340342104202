import "./ImageListUpload.style.scss";
import React, { useEffect, useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
} from "@mui/material";
import NavStepHeader from "../../../../components/reusable/NavStepHeader/NavStepHeader";

interface ImageListUploadProps {
  imagesAttributions: string[];
  setImagesAttributions: any;
  selectedImageFiles: File[];
  selectedImageFileSizes: string[][];
  setSelectedImageFiles: any;
  setSelectedImageFileSizes: any;
}

const ImageListUpload = ({
  imagesAttributions,
  setImagesAttributions,
  selectedImageFiles,
  selectedImageFileSizes,
  setSelectedImageFiles,
  setSelectedImageFileSizes,
}: ImageListUploadProps) => {
  const inputChooseFileRef = useRef<HTMLInputElement>(null);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  useEffect(() => {
    if (!selectedImageFiles) {
      setImagePreviews([]);
      return;
    }
    const objectsURLs: string[] = [];
    [...selectedImageFiles].map((selectedFile) =>
      objectsURLs.push(URL.createObjectURL(selectedFile))
    );
    setImagePreviews(objectsURLs);
    return () => {
      objectsURLs.forEach((objectURL) => {
        URL.revokeObjectURL(objectURL);
      });
    };
  }, [selectedImageFiles]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImageFiles(undefined);
      return;
    }
    setSelectedImageFiles([...(selectedImageFiles || []), e.target.files[0]]);
    if (!selectedImageFileSizes.length) {
      setSelectedImageFileSizes([["xs", "sm", "lg"]]);
    } else {
      setSelectedImageFileSizes([...selectedImageFileSizes, ["sm", "lg"]]);
    }
    setImagesAttributions([...(imagesAttributions || []), ""]);
    inputChooseFileRef.current!.value = "";
  };

  const onButtonRemoveImageClick = (i: number) => {
    const newPreviews = [...(imagePreviews || [])];
    newPreviews.splice(i, 1);
    const newFiles = [...(selectedImageFiles || [])];
    newFiles.splice(i, 1);
    setImagePreviews(newPreviews);
    setSelectedImageFiles(newFiles);
    const newSelectedImagesFileSizes = [...selectedImageFileSizes];
    newSelectedImagesFileSizes.splice(i, 1);
    setSelectedImageFileSizes(newSelectedImagesFileSizes);
    const updatedImagesAttributions = [...imagesAttributions];
    updatedImagesAttributions.splice(i, 1);
    setImagesAttributions(updatedImagesAttributions);
  };

  const onCheckboxSizeClick = (size: string, i: number) => {
    const currentImageFileSizes = [...selectedImageFileSizes[i]];
    if (currentImageFileSizes.includes(size)) {
      currentImageFileSizes.splice(
        currentImageFileSizes.findIndex((el) => el === size),
        1
      );
    } else {
      currentImageFileSizes.push(size);
    }
    const newImageFileSizes = [...selectedImageFileSizes];
    newImageFileSizes.splice(i, 1, currentImageFileSizes);
    setSelectedImageFileSizes(newImageFileSizes);
  };

  const onSetImageAttribution = (val: string, i: number) => {
    const updatedImagesAttributions = [...imagesAttributions];
    updatedImagesAttributions[i] = val;
    setImagesAttributions(updatedImagesAttributions);
  };

  return (
    <section>
      <NavStepHeader stepNumber={2} />
      <div className="image-list-upload-wrap">
        <div className="input-file-choose-wrap">
          <input
            type="file"
            ref={inputChooseFileRef}
            onChange={onInputChange}
          />
        </div>
        <div className="image-list-wrap" style={{ display: "flex" }}>
          <form
            action=""
            encType="multipart/form-data"
            className="avatar"
            id="user-avatar-form"
          >
            <div className="image-previews-wrap">
              {(imagePreviews || []).map((preview, i) => (
                <div className="image-wrapper">
                  <img src={preview} style={{ height: "auto" }} />
                  <div className="action-buttons">
                    <div className="sizes-wrap">
                      <span className="title">Thumbnails:</span>
                      <FormGroup>
                        {["xs", "sm", "lg"].map((size) => {
                          return (
                            <FormControlLabel
                              key={size}
                              control={
                                <Checkbox
                                  checked={selectedImageFileSizes[i].includes(
                                    size
                                  )}
                                  onClick={() => onCheckboxSizeClick(size, i)}
                                />
                              }
                              label={size}
                            />
                          );
                        })}
                      </FormGroup>
                    </div>
                    <IconButton
                      sx={{ color: "white" }}
                      onClick={() => onButtonRemoveImageClick(i)}
                    >
                      <Close />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          </form>
        </div>
        <div className="image-attributions-wrap">
          <h3>Image Attributions</h3>
          {selectedImageFiles.map((_, i) => {
            return (
              <div
                className="image-attributions-line"
                key={`image-attribution-${i}`}
              >
                <TextField
                  fullWidth={true}
                  label={`Image Attribution ${i + 1}`}
                  multiline={true}
                  onChange={(e) => onSetImageAttribution(e?.target?.value, i)}
                  value={imagesAttributions[i]}
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ImageListUpload;
