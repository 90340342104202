import "./NavStepHeader.style.scss";
import { Cached } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";

const HEADERS: any = {
  "1": {
    title: "Main Information",
    subtitle:
      "Provide the basic information that appear in the map preview of the exhibit. 'Year completed' field is utilized in the Time Shift component to query exhibits within a specified range. ",
  },
  "2": {
    title: "Image List",
    subtitle:
      "Upload multiple images and automatic thumbnails will be created for different screen sizes for mobiles devices, tablets, laptops and desktops.",
  },
  "3": {
    title: "Location",
    subtitle:
      "Latitude and longitude of the location will be used to display the exhibit on the preview map of the Misty Map visualization.",
  },
  "4": {
    title: "Categories",
    subtitle:
      "Categorize the exhibit into theme sections and discover others with the same attributes in the side navigation. Multiple categories may apply as well. For the exhibits to be categorized properly, re-indexing is required from the Settings panel.",
  },
  "5": {
    title: "Filters",
    subtitle:
      "Filters provide indexing and querying exhibits with the same filter options and depend on the selected category. Attribute values are represented as string and multiple options can be selected for a certain filter.",
  },
  "6": {
    title: "Numeric Fields",
    subtitle:
      "Numeric fields are arithmetic attributes an exhibit may have and they are organized into logical groups e.g. Specifications etc",
  },
  "7": {
    title: "External Links",
    subtitle:
      "You can provide links that are used to synthesize this exhibit. Can be used at a later point to include additional information and as a reference.",
  },
  "8": {
    title: "Related Exhibits",
    subtitle:
      "Select one or many items that are related to the current exhibit in terms of locality, theme, common filters etc.",
  },
};

interface NavStepHeaderProps {
  stepNumber: number;
  onButtonReloadClick?: any;
}

const NavStepHeader = ({
  stepNumber,
  onButtonReloadClick,
}: NavStepHeaderProps) => {
  return (
    <>
      <header className="nav-step-header-wrap">
        <Typography variant="h2" sx={{ mb: 1 }}>
          0{stepNumber}. {HEADERS[stepNumber].title}
        </Typography>
        {onButtonReloadClick ? (
          <IconButton onClick={onButtonReloadClick}>
            <Cached />
          </IconButton>
        ) : null}
      </header>
      {HEADERS[stepNumber]?.subtitle ? (
        <Typography variant="subtitle1" sx={{ mb: 3 }}>
          {HEADERS[stepNumber].subtitle}
        </Typography>
      ) : null}
    </>
  );
};

export default NavStepHeader;
