import "./CategoryFilters.style.scss";
import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Category, Filter } from "../../../types";
import api from "../../../utils/api";

interface CategoryFiltersProps {
  category: Category;
  filters: Filter[];
  onSuccess: any;
  setLoading: any;
  parentLoading: boolean;
}

const CategoryFilters = ({
  category,
  filters,
  onSuccess,
  setLoading,
  parentLoading,
}: CategoryFiltersProps) => {
  const [errors, setErrors] = useState([]);
  const categoryFiltersIds = category?.Filters.map((el) => el.id);
  const [localLoading, setLocalLoading] = useState<number | false>(false);
  const [searchValue, setSearchValue] = useState("");

  const onCheckboxClick = async (filter: Filter, i: number) => {
    setErrors([]);
    let d1 = Date.now();
    setLocalLoading(i);
    try {
      const isChecked = categoryFiltersIds.includes(filter.id);
      if (isChecked) {
        await api.post("/categoryFilter/delete", {
          categoryId: category.id,
          filterId: filter.id,
        });
      } else {
        await api.post("/categoryFilter/create", {
          categoryId: category.id,
          filterId: filter.id,
        });
      }
      onSuccess();
      let d2 = Date.now();
      if (d2 - d1 > 500) {
        setLocalLoading(false);
      } else {
        setTimeout(() => {
          setLocalLoading(false);
        }, 500 - (d2 - d1));
      }
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  return (
    <section className="category-filters">
      <header style={{ marginBottom: 10 }}>
        <Typography
          variant="h3"
          sx={{ mb: 1 }}
        >{`Category Filters`}</Typography>
        <TextField
          value={searchValue}
          onChange={({ target: { value } }) => setSearchValue(value)}
          variant="standard"
          type={`text`}
          fullWidth={true}
          placeholder={`Type to search filter..`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </header>
      <div style={{ minHeight: 300 }}>
        <Grid container spacing={1}>
          {filters.map((filter, i) => {
            if (
              searchValue &&
              !filter.title.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              return null;
            }
            return (
              <Grid item xs={4} key={`filter-${filter.id}`}>
                <FormGroup
                  className={`${parentLoading ? "checkbox-wrap-loading" : ""}`}
                >
                  <FormControlLabel
                    control={
                      localLoading !== false && i === localLoading ? (
                        <div
                          style={{
                            transform: "scale(0.55)",
                            overflow: "hidden",
                            width: 42,
                            height: 42,
                          }}
                        >
                          <CircularProgress size={42} />
                        </div>
                      ) : (
                        <Checkbox
                          checked={(categoryFiltersIds || []).includes(
                            filter?.id
                          )}
                          onClick={() => onCheckboxClick(filter, i)}
                        />
                      )
                    }
                    label={
                      <>
                        {`${filter.title}`}
                        <span
                          style={{ color: "gray", marginLeft: 5, fontSize: 11 }}
                        >
                          ID: {filter.id}
                        </span>
                      </>
                    }
                  />
                </FormGroup>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </section>
  );
};

export default CategoryFilters;
