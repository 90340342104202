interface ArrowRightProps {
  height?: number;
  width?: number;
  size?: number;
}

export default ({ size }: ArrowRightProps) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height={`${size ? `${size}px` : "1em"}`}
      width={`${size ? `${size}px` : "1em"}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 5h5V3H3v7h2zm5 14H5v-5H3v7h7zm11-5h-2v5h-5v2h7zm-2-4h2V3h-7v2h5z"></path>
    </svg>
  );
};
