import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material";
import App from "./components/App/App";
import { AlertProvider } from "./context/alertContext";
import { AuthProvider } from "./context/authContext";
import { ModalsProvider } from "./context/modalsContext";
import reportWebVitals from "./reportWebVitals";
import { appTheme } from "./themes/theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AuthProvider>
    <AlertProvider>
      <ThemeProvider theme={appTheme}>
        <ModalsProvider>
          <App />
        </ModalsProvider>
      </ThemeProvider>
    </AlertProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
