import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Exhibit } from "../../../../types";
import RelatedExhibitSelectedItem from "./RelatedExhibitsSelectedItem";

interface SimpleAccordionProps {
  renderSectionSearch: any;
  selectedExhibits: Exhibit[];
  setSelectedExhibits: any;
}

export default function SimpleAccordion({
  renderSectionSearch,
  selectedExhibits,
  setSelectedExhibits,
}: SimpleAccordionProps) {
  return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Search Related Exhibits</Typography>
        </AccordionSummary>
        <AccordionDetails>{renderSectionSearch()}</AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h6">Selected exhibits</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4} className={`grid-container`}>
            {selectedExhibits.map((exhibit) => (
              <RelatedExhibitSelectedItem
                exhibit={exhibit}
                selectedExhibits={selectedExhibits}
                setSelectedExhibits={setSelectedExhibits}
              />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
