import "./MapPopupExhibitImages.style.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../../../../assets/icons/ArrowLeft";
import ArrowRight from "../../../../assets/icons/ArrowRight";
import FullScreen from "../../../../assets/icons/FullScreen";
import { useModals } from "../../../../context/modalsContext";
import { Exhibit } from "../../../../types";
import {
  getCurrentViewImageFromURL,
  getNavigateStringToEditedURLParamsObject,
  getURLParamsObjectByLocation
} from "../../../../utils/URL";

interface MapPopupExhibitImagesProps {
  browserLocation: any;
  exhibit: Exhibit;
}

const MapExhibitImage = ({ exhibit, viewImageCount, viewImageKey }: any) => {
  const { UUID, title }: Exhibit = exhibit || {};
  return (
    <div className={`exhibit-image-cover img-${viewImageCount}`}>
      <img
        className="map-exhibit-image-front"
        src={`${process.env.REACT_APP_IMAGES_PATH}/${UUID}/${viewImageKey}`}
        alt={title}
      />
      <img
        className="map-exhibit-image-background"
        src={`${process.env.REACT_APP_IMAGES_PATH}/${UUID}/${viewImageKey}`}
        alt={title}
      />
    </div>
  );
};

const MapPopupExhibitImages = ({
  exhibit,
  browserLocation,
}: MapPopupExhibitImagesProps) => {
  const navigate = useNavigate();
  const { setCurrentGlobalExhibit } = useModals() || {};
  // const [currentViewImage, setCurrentViewImage] = useState(0);
  const { images } = exhibit || {};
  let { currentViewImage } = getCurrentViewImageFromURL();
  const keyViewImage = `${(images?.keys || [])[currentViewImage]}-${
    (images?.dir || [])[currentViewImage]
  }-sm`;
  const hasMultipleImages = images?.keys?.length > 1;

  const onPrevClick = () => {
    const imagesLength = images?.keys?.length;
    const { currentViewImage, URLParamsObject } = getCurrentViewImageFromURL();
    if (currentViewImage === 0) {
      URLParamsObject.viewImage = [`${imagesLength - 1}`];
      // setCurrentViewImage(imagesLength - 1);
    } else {
      if (currentViewImage - 1 === 0) {
        delete URLParamsObject.viewImage;
        // setCurrentViewImage(0);
      } else {
        URLParamsObject.viewImage = [`${currentViewImage - 1}`];
        // setCurrentViewImage(currentViewImage - 1);
      }
    }
    navigate(getNavigateStringToEditedURLParamsObject(URLParamsObject));
  };

  const onNextClick = (e: any) => {
    e.preventDefault();
    const imagesLength = images?.keys?.length;
    const { currentViewImage, URLParamsObject } = getCurrentViewImageFromURL();
    if (currentViewImage === imagesLength - 1) {
      delete URLParamsObject.viewImage;
      // setCurrentViewImage(0);
    } else {
      URLParamsObject.viewImage = [`${currentViewImage + 1}`];
      // setCurrentViewImage(currentViewImage + 1);
    }
    navigate(getNavigateStringToEditedURLParamsObject(URLParamsObject));
  };

  const onFullScreenClick = () => {
    const URLParamsObject = getURLParamsObjectByLocation(browserLocation);
    // URLParamsObject.exhibit = [`${exhibit.UUID}`];
    // URLParamsObject.fullScreen = [`1`];
    URLParamsObject.fullScreen = `${exhibit.UUID}`;
    if (currentViewImage) {
      URLParamsObject.fsImage = `${currentViewImage}`;
    }
    navigate(getNavigateStringToEditedURLParamsObject(URLParamsObject));
    console.log("click", exhibit, setCurrentGlobalExhibit);
    setCurrentGlobalExhibit(exhibit);
  };

  return (
    <>
      {hasMultipleImages ? (
        <button
          aria-label="Previous"
          className="map-exhibit-image-button prev"
          onClick={() => onPrevClick()}
        >
          <ArrowLeft size={24} />
        </button>
      ) : null}
      <MapExhibitImage
        exhibit={exhibit}
        viewImageCount={currentViewImage}
        viewImageKey={keyViewImage}
      />
      {hasMultipleImages ? (
        <button
          aria-label="Next"
          className="map-exhibit-image-button next"
          onClick={(e) => onNextClick(e)}
        >
          <ArrowRight size={25} />
        </button>
      ) : null}
      <button
        aria-label="Full screen"
        className="map-exhibit-image-button full-screen"
        onClick={() => onFullScreenClick()}
      >
        <FullScreen size={20} />
      </button>
    </>
  );
};

export default MapPopupExhibitImages;
