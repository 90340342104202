import "react-quill/dist/quill.snow.css";
import "./ExternalLinks.style.scss";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import { Add, Remove } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import NavStepHeader from "../../../../components/reusable/NavStepHeader/NavStepHeader";

const ExternalLinks = ({
  externalLinksCounter,
  externalLinksValues,
  setExternalLinksCounter,
  setExternalLinksValues,
}: any) => {
  const onButtonAddExternalLinkClick = () => {
    setExternalLinksCounter(externalLinksCounter + 1);
  };

  const onButtonRemoveExternalLinkClick = (i: number) => {
    const prevExternalLinksValues = [...externalLinksValues];
    setExternalLinksCounter(externalLinksCounter - 1);
    prevExternalLinksValues.splice(i, 1);
    setExternalLinksValues(prevExternalLinksValues);
  };

  const setValue = (value: any, i: number) => {
    const prevExternalLinksValues = [...externalLinksValues];
    prevExternalLinksValues.splice(i, 1, value);
    setExternalLinksValues(prevExternalLinksValues);
  };

  return (
    <section>
      <NavStepHeader stepNumber={7} />
      <div className="external-links-wrap">
        {Array(externalLinksCounter)
          .fill(1)
          .map((_, i) => {
            return (
              <div className="editor-wrap" key={`counter-${i}`}>
                <Grid container spacing={2} className={`grid-container`}>
                  <Grid item xs={10}>
                    <ReactQuill
                      key={`quill-${i}`}
                      theme="snow"
                      value={externalLinksValues[i]}
                      onChange={(value) => setValue(value, i)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {externalLinksCounter > 1 ? (
                      <IconButton
                        size="medium"
                        className="icon-add-wrap"
                        onClick={() => onButtonRemoveExternalLinkClick(i)}
                      >
                        <Remove fontSize="medium" />
                      </IconButton>
                    ) : null}
                  </Grid>
                </Grid>
              </div>
            );
          })}
        <div className="button-add-wrap">
          <IconButton
            size="medium"
            className="icon-add-wrap"
            onClick={() => onButtonAddExternalLinkClick()}
          >
            <Add fontSize="medium" />
          </IconButton>
        </div>
      </div>
    </section>
  );
};

export default ExternalLinks;
