import "./ModalExhibitFullScreen.style.scss";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../../../../assets/icons/ArrowLeft";
import ArrowRight from "../../../../assets/icons/ArrowRight";
import IconClose from "../../../../assets/icons/Close";
import { useModals } from "../../../../context/modalsContext";
import { computeCategoriesTitlesArrayFromCategories } from "../../../../utils/compute";
import { formatExhibitLocation } from "../../../../utils/format";
import {
  getNavigateStringToEditedURLParamsObject,
  getURLParamsObjectByLocation,
  getURLSearchByParamsObject
} from "../../../../utils/URL";
import {
  ADDITIONAL_INFO_UNITS,
  computeFiltersAndFilterOptions
} from "./ModalExhibitFullScreen.utils";

interface ModalExhibitImagesFullScreenProps {
  browserLocation: any;
}

const ModalExhibitImagesFullScreen = ({
  browserLocation,
}: ModalExhibitImagesFullScreenProps) => {
  const { currentGlobalExhibit } = useModals() || {};
  const navigate = useNavigate();
  let URLParamsObject = getURLParamsObjectByLocation(browserLocation);
  const { fullScreen, fsImage, exhibit } = URLParamsObject || {};

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (exhibit && Array.isArray(exhibit)) {
      // let exhibitUUID = exhibit[0];
      // console.log(exhibitUUID, "exhibitUUID");
      // let results: any = await fetch(
      //   `${process.env.REACT_APP_BASE_URL}/findExhibitByUUID?UUID=${exhibitUUID}`
      // );
      // results = await results.json();
      // const { exhibit } = results || {};
    }
  }, [exhibit]);

  if (!fullScreen) {
    return null;
  }

  const {
    Categories,
    FilterOptions,
    UUID,
    images,
    imagesAttributions,
    location,
    subtitle,
    title,
    yearCompleted,
  } = currentGlobalExhibit || {};

  const onButtonIconClose = () => {
    const URLParams = getURLParamsObjectByLocation(browserLocation);
    ["fullScreen", "fsImage"].forEach((key) => {
      delete URLParams[key];
    });
    // if (URLParams.searchQuery) {
    //   delete URLParams.exhibit;
    // }
    navigate(
      `${browserLocation.pathname}?${getURLSearchByParamsObject(URLParams)}`
    );
  };

  const onPrevClick = () => {
    const imagesLength = images?.keys?.length || 0;
    const { currentViewImage, URLParamsObject } = getCurrentViewImageFromURL();
    if (currentViewImage === 0) {
      URLParamsObject.fsImage = `${imagesLength - 1}`;
    } else {
      if (currentViewImage - 1 === 0) {
        delete URLParamsObject.fsImage;
      } else {
        URLParamsObject.fsImage = [`${currentViewImage - 1}`];
      }
    }
    navigate(getNavigateStringToEditedURLParamsObject(URLParamsObject));
  };

  const onNextClick = () => {
    const imagesLength = images?.keys?.length || 0;
    const { currentViewImage, URLParamsObject } = getCurrentViewImageFromURL();
    if (currentViewImage === imagesLength - 1) {
      delete URLParamsObject.fsImage;
    } else {
      URLParamsObject.fsImage = [`${currentViewImage + 1}`];
    }
    navigate(getNavigateStringToEditedURLParamsObject(URLParamsObject));
  };

  const imagesURLS = computeImagesURLS(UUID, images);
  const hasMultipleImages = imagesURLS.length > 1;
  const currentImage = parseInt((fsImage || [])[0]) || 0;
  const loading = !currentGlobalExhibit;
  const additionalInfo = prepareAdditionalInfo(
    currentGlobalExhibit?.additionalInfo
  );
  const filtersWithFilterOptions =
    computeFiltersAndFilterOptions(FilterOptions);
  const categoriesTitles =
    computeCategoriesTitlesArrayFromCategories(Categories);

  return (
    <section id="modal-exhibit-full-screen-wrap">
      <div className="modal-exhibit-full-screen-left">
        <div className="modal-exhibit-full-screen-left-images-wrap">
          {hasMultipleImages ? (
            <button
              aria-label="Previous"
              className="modal-exhibit-full-screen-button prev"
              onClick={() => onPrevClick()}
            >
              <ArrowLeft size={24} />
            </button>
          ) : null}
          {!loading && (
            <img
              alt={`${title}`}
              className={`modal-exhibit-full-screen-left-img`}
              src={imagesURLS[currentImage]}
            />
          )}
          {hasMultipleImages ? (
            <button
              aria-label="Next"
              className="modal-exhibit-full-screen-button next"
              onClick={() => onNextClick()}
            >
              <ArrowRight size={25} />
            </button>
          ) : null}
        </div>
        <div className="modal-exhibit-full-screen-left-attribution-line">
          {imagesAttributions ? (
            <div
              dangerouslySetInnerHTML={{
                __html: imagesAttributions[currentImage],
              }}
            />
          ) : null}
        </div>
      </div>
      <div className="modal-exhibit-full-screen-right">
        <h2>{title}</h2>
        <p>
          {formatExhibitLocation(location)} · {yearCompleted}
        </p>
        <p className="modal-exhibit-full-screen-subtitle">{subtitle}</p>
        <ul className="modal-exhibit-full-screen-category-list">
          {categoriesTitles.map((categoryTitle, i: number) => (
            <li
              className="modal-exhibit-full-screen-category-item"
              key={categoryTitle}
            >
              {categoryTitle}
              {categoriesTitles.length > 1 &&
              i < categoriesTitles.length - 1 ? (
                <ArrowRight />
              ) : null}
            </li>
          ))}
        </ul>
        {Object.keys(additionalInfo || {}).length ? (
          <>
            <h3 className="modal-exhibit-full-screen-h3">
              Additional Information
            </h3>
            <div>
              {Object.keys(additionalInfo || {}).map((key: string) => {
                return (
                  <li key={key}>
                    {key}: {additionalInfo[key]}
                  </li>
                );
              })}
            </div>
          </>
        ) : null}
        <div>
          {Object.keys(filtersWithFilterOptions).map((key: string) => {
            return (
              <div key={key}>
                <h3 className="modal-exhibit-full-screen-h3">{key}</h3>
                {filtersWithFilterOptions[key].map((value: string) => (
                  <li key={value}>{value}</li>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      <button
        className="button-icon-close modal-exhibit-full-screen-button-close"
        onClick={() => onButtonIconClose()}
      >
        <IconClose className="modal-exhibit-full-screen-icon-close-svg" />
      </button>
    </section>
  );
};

const getCurrentViewImageFromURL = (): {
  currentViewImage: number;
  URLParamsObject: any;
} => {
  const URLParamsObject = getURLParamsObjectByLocation(window.location);
  let currentViewImage = 0;
  if (URLParamsObject.fsImage) {
    currentViewImage = parseInt(URLParamsObject.fsImage[0]);
  }
  return {
    currentViewImage,
    URLParamsObject,
  };
};

const computeImagesURLS = (UUID: string | undefined, images: any): string[] => {
  let imagesURLs: string[] = [];
  (images?.keys || []).forEach((key: string, i: number) => {
    let availableSize = "";
    ["xl", "lg", "md", "sm"].forEach((size) => {
      if (!availableSize && (images?.sizes[i] || []).includes(size)) {
        availableSize = size;
      }
    });
    const viewImageKey = `${key}-${(images?.dir || [])[i]}-${availableSize}`;
    let URL = `${process.env.REACT_APP_IMAGES_PATH}/${UUID}/${viewImageKey}`;
    imagesURLs.push(URL);
  });
  return imagesURLs;
};

const prepareAdditionalInfo = (additionalInfo: any) => {
  if (!additionalInfo || !Object.keys(additionalInfo).length) {
    return {};
  }
  const parsedAdditionalInfo: any = {};
  Object.keys(additionalInfo).forEach((key) => {
    let parseKey = capitalizeFirstLetter(
      key.replace(/([a-z])([A-Z])/g, "$1 $2")
    );
    parsedAdditionalInfo[parseKey] = `${additionalInfo[key]}${
      ADDITIONAL_INFO_UNITS[key] ? ` ${ADDITIONAL_INFO_UNITS[key]}` : ""
    }`;
  });
  return parsedAdditionalInfo;
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default ModalExhibitImagesFullScreen;
