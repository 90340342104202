import "./Filters.style.scss";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import HeroBannerSearchLayout from "../../components/HeroBannerSearchLayout/HeroBannerSearchLayout";
import Table, { ITableRow } from "../../components/Table/Table";
import { Filter, FilterOption } from "../../types";
import api from "../../utils/api";
import { getURLParamsObjectByLocation } from "../../utils/URL";
import SKELETON_FILTER_OPTIONS from "./data/SKELETON_FILTER_OPTIONS.json";
import SKELETON_FILTER from "./data/SKELETON_FILTERS.json";
import { computeNextURL, getFieldsStateFromQueryParams } from "./Filters.utils";
import ModalFilterCreate from "./modals/ModalFilterCreate";
import ModalFilterDelete from "./modals/ModalFilterDelete";
import ModalFilterEdit from "./modals/ModalFilterEdit";
import ModalFilterOptionAdd from "./modals/ModalFilterOptionAdd";
import ModalFilterOptionDelete from "./modals/ModalFilterOptionDelete";

const skeletonFilters: Filter = SKELETON_FILTER;
const skeletonFilterOptions: ITableRow[] = SKELETON_FILTER_OPTIONS.data;

export const DEFAULT_SEARCH_FIELDS_STATE = {
  title: "",
  id: "",
  category: "",
};

const convertFilterOptionsToTableRows = (
  filterOptions: FilterOption[]
): ITableRow[] => {
  return filterOptions.map(
    ({ URLSlug, id, sort, title, Filter }: FilterOption) => ({
      id,
      filterTitle: Filter?.title || "",
      sort,
      title,
      URLSlug,
    })
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ICON_OPTIONS = [
  { title: "Keywords", id: "keywords" },
  { title: "Artist", id: "artist" },
  { title: "Material", id: "material" },
  { title: "Culture", id: "culture" },
  { title: "Condition", id: "condition" },
  { title: "Architect", id: "architect" },
  { title: "Engineering design", id: "engineering-design" },
  { title: "Religion", id: "religion" },
  { title: "Builder", id: "builder" },
  { title: "Dedicated to", id: "dedicated-to" },
  { title: "Emperor", id: "emperor" },
  { title: "City", id: "City" },
  { title: "In the reign of", id: "in-the-reign-of" },
  { title: "Mausoleum for", id: "mausoleum-for" },
];

const categoriesNames = ICON_OPTIONS.map((el) => el.title);

const Filters = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchFieldsState, setSearchFieldsState] = useState<
    typeof DEFAULT_SEARCH_FIELDS_STATE
  >(getFieldsStateFromQueryParams(location));

  const [filters, setFilters] = useState<Filter[]>([skeletonFilters]);
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [filteredFilterOptions, setFilteredFilterOptions] = useState<
    ITableRow[]
  >(skeletonFilterOptions);
  const [isModalCreateFilterOpen, setIsModalCreateFilterOpen] = useState(false);
  const [isModalFilterEditOpen, setIsModalFilterEditOpen] = useState(false);
  const [filterIdToEdit, setFilterIdToEdit] = useState(0);
  const [filterIdToDelete, setFilterIdToDelete] = useState(0);
  const [isModalFilterDeleteOpen, setIsModalFilterDeleteOpen] = useState(false);
  const [isModalAddFilterOptionOpen, setIsModalAddFilterOptionOpen] =
    useState(false);
  const [isModalDeleteFilterOptionOpen, setIsModalDeleteFilterOptionOpen] =
    useState(false);

  useEffect(() => {
    loadFilters();
  }, []);

  useEffect(() => {
    let nextURL = computeNextURL({ searchFieldsState });
    navigate(`/filters?${nextURL}`);
  }, [searchFieldsState]);

  useEffect(() => {
    const paramsObject: any = getURLParamsObjectByLocation(location);
    const { title, category } = paramsObject || {};
    let filteredFilterOptionsData: FilterOption[] = [...filterOptions];
    if (title) {
      filteredFilterOptionsData = filterOptions.filter(
        (el) =>
          el.title.toLowerCase().includes(title.toLowerCase()) ||
          (el?.Filter?.title || "").toLowerCase().includes(title.toLowerCase())
      );
    }
    if (category) {
      let categories = category.split(",");
      filteredFilterOptionsData = filteredFilterOptionsData.filter((el) =>
        categories.includes(el?.Filter?.URLSlug || "")
      );
    }
    setFilteredFilterOptions(
      convertFilterOptionsToTableRows(filteredFilterOptionsData)
    );
  }, [location]);

  const loadFilters = async (delay?: number) => {
    let delayInterval = delay || 1500;
    try {
      setLoading(true);
      let d1 = Date.now();
      const JSONFilterOptions: FilterOption[] =
        (await api.get("/filterOptions"))?.data?.data || [];
      const parsedData = convertFilterOptionsToTableRows(JSONFilterOptions);

      setFilterOptions(JSONFilterOptions);
      setFilteredFilterOptions(parsedData);
      let d2 = Date.now();
      if (d2 - d1 > delayInterval) {
        setLoading(false);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, delayInterval - (d2 - d1));
      }
    } catch (error) {
      console.log(error, "the error");
    }
  };

  const onFilterCreateSuccess = () => {
    setIsModalCreateFilterOpen(false);
    loadFilters(500);
  };

  const onFilterOptionCreateSuccess = () => {
    setIsModalAddFilterOptionOpen(false);
    loadFilters(500);
  };

  const onFilterOptionDeleteSuccess = () => {
    setIsModalDeleteFilterOptionOpen(false);
    loadFilters(500);
  };

  const onButtonDeleteFilterOptionClick = (row: any) =>
    setIsModalDeleteFilterOptionOpen(row);

  const onPopoverMoreButtonFilterDeleteClick = (filterId: number) => {
    setIsModalFilterDeleteOpen(true);
    setFilterIdToDelete(filterId);
  };

  const onPopoverMoreButtonFilterEditClick = (filterId: number) => {
    setIsModalFilterEditOpen(true);
    setFilterIdToEdit(filterId);
  };

  const onFilterDeleteSuccess = () => {
    setIsModalFilterDeleteOpen(false);
    loadFilters(500);
  };

  const onFilterEditSuccess = () => {
    setIsModalFilterEditOpen(false);
    loadFilters(500);
  };

  const onButtonCreateNumericGroup = () => {};

  const onLinkClearFieldsClick = (e: any) => {
    e.preventDefault();
    setSearchFieldsState(DEFAULT_SEARCH_FIELDS_STATE);
  };

  const onTextFieldChange = ({
    field,
    value,
  }: {
    field: string;
    value: string;
  }) => {
    setSearchFieldsState((searchFieldsState) => ({
      ...searchFieldsState,
      [field]: value,
    }));
  };

  const findIdArrayFromTitleArray = (titlesArray: string[]): string[] => {
    return titlesArray.map(
      (el) => ICON_OPTIONS.find((el2: any) => el2.title === el)?.id || ""
    );
  };

  const findTitleArrayFromIdArray = (idsArray: string[]): string[] => {
    return idsArray.map(
      (el) => ICON_OPTIONS.find((el2: any) => el2.id === el)?.title || ""
    );
  };

  const categoryValues = findTitleArrayFromIdArray(
    searchFieldsState.category.split(",")
  ).filter((el) => !!el);

  const handleCategoryChange = (
    event: SelectChangeEvent<string | string[]>
  ) => {
    const {
      target: { value },
    } = event;
    setSearchFieldsState((searchFieldsState) => ({
      ...searchFieldsState,
      category:
        typeof value === "string"
          ? value
          : findIdArrayFromTitleArray(value).join(","),
    }));
  };

  return (
    <>
      <ModalFilterCreate
        isModalCreateFilterOpen={isModalCreateFilterOpen}
        onFilterCreateSuccess={onFilterCreateSuccess}
        setIsModalCreateFilterOpen={setIsModalCreateFilterOpen}
      />
      <ModalFilterDelete
        filterTitle={
          (filters || []).find((el: Filter) => el.id === filterIdToDelete)
            ?.title || ""
        }
        filterId={filterIdToDelete}
        isModalFilterDeleteOpen={isModalFilterDeleteOpen}
        onFilterDeleteSuccess={onFilterDeleteSuccess}
        setIsModalFilterDeleteOpen={setIsModalFilterDeleteOpen}
      />
      <ModalFilterEdit
        filter={(filters || []).find((el: any) => el.id === filterIdToEdit)}
        isModalFilterEditOpen={isModalFilterEditOpen}
        onFilterEditSuccess={onFilterEditSuccess}
        setIsModalFilterEditOpen={setIsModalFilterEditOpen}
      />
      <ModalFilterOptionAdd
        filterOptions={filterOptions}
        isModalAddFilterOpen={isModalAddFilterOptionOpen}
        setIsModalAddFilterOpen={setIsModalAddFilterOptionOpen}
        onFilterOptionCreateSuccess={onFilterOptionCreateSuccess}
      />
      <ModalFilterOptionDelete
        isModalDeleteFilterOpen={!!isModalDeleteFilterOptionOpen}
        filterOption={isModalDeleteFilterOptionOpen}
        onFilterOptionDeleteSuccess={onFilterOptionDeleteSuccess}
        setIsModalDeleteFilterOpen={setIsModalDeleteFilterOptionOpen}
      />
      <main id="page-filters">
        <HeroBannerSearchLayout
          buttonActionTitle={`Filter Option`}
          onButtonCreateClick={() => setIsModalAddFilterOptionOpen(true)}
          onLinkClearFieldsClick={onLinkClearFieldsClick}
          subtitle="Filter options enables filtering attributes that have a text value. Examples can be a common material, the name of the artist and the same architectural design style the several exhibit may have."
          title="Filters"
        >
          <>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  label="Title"
                  fullWidth={true}
                  variant="outlined"
                  value={searchFieldsState.title}
                  onChange={({ target: { value } }) =>
                    onTextFieldChange({ field: "title", value })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={categoryValues}
                    onChange={handleCategoryChange}
                    input={<OutlinedInput label="Category" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {categoriesNames.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={categoryValues.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        </HeroBannerSearchLayout>
        <Container maxWidth="xl">
          {filters.map((filter) => (
            <section key={filter.title} className="section-table-wrap">
              <Table
                filterOptions={filteredFilterOptions}
                loading={loading}
                onButtonAddFilterOptionClick={setIsModalAddFilterOptionOpen}
                onButtonDeleteFilterOptionClick={
                  onButtonDeleteFilterOptionClick
                }
                onPopoverMoreButtonFilterDeleteClick={
                  onPopoverMoreButtonFilterDeleteClick
                }
                onPopoverMoreButtonFilterEditClick={
                  onPopoverMoreButtonFilterEditClick
                }
              />
            </section>
          ))}
        </Container>
      </main>
    </>
  );
};

export default Filters;
