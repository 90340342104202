import {
  getURLParamsObjectByLocation,
  getURLSearchByParamsObject,
} from "../../utils/URL";
import { DEFAULT_SEARCH_FIELDS_STATE } from "./Filters";

export const getFieldsStateFromQueryParams = (location: any) => {
  const fieldsState: any = {
    URL: "",
    UUID: "",
    category: "",
    location: "",
    title: "",
    yearFrom: "",
    yearTo: "",
  };

  Object.keys(fieldsState).forEach((key) => {
    if (location.search.includes(key)) {
      fieldsState[key] = location.search.split(`${key}=`).pop()?.split("&")[0];
    }
  });
  return fieldsState;
};

export const computeNextURL = ({
  searchFieldsState,
}: {
  searchFieldsState?: typeof DEFAULT_SEARCH_FIELDS_STATE;
}) => {
  const currentURLParams = getURLParamsObjectByLocation(window.location);
  const updatedKeyValuePairs: any = {};
  if (searchFieldsState) {
    Object.entries(searchFieldsState).forEach(([key, value]) =>
      DEFAULT_SEARCH_FIELDS_STATE[
        key as keyof typeof DEFAULT_SEARCH_FIELDS_STATE
      ] !== value
        ? (updatedKeyValuePairs[key] = value)
        : delete currentURLParams[key]
    );
  }

  let updatedURLParamsObject = {
    ...currentURLParams,
    ...updatedKeyValuePairs,
  };
  return getURLSearchByParamsObject(updatedURLParamsObject);
};
