import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../components/routing/PrivateRoute";
import PublicRoute from "../components/routing/PublicRoute";
import { useAuth } from "../context/authContext";
import Categories from "../routes/Categories/Categories";
import ExhibitCreate from "../routes/ExhibitCreate/ExhibitCreate";
import ExhibitDetails from "../routes/ExhibitDetails/ExhibitDetails";
import ExhibitPreview from "../routes/ExhibitPreview/ExhibitPreview";
import Exhibits from "../routes/Exhibits/Exhibits";
import Filters from "../routes/Filters/Filters";
import Login from "../routes/Login/Login";
import Numerics from "../routes/Numerics/Numerics";
import Settings from "../routes/Settings/Settings";
import SignUp from "../routes/SignUp/SignUp";

const Content = () => {
  const { user, loading } = useAuth() || {};

  return (
    <Routes>
      <Route element={<PublicRoute user={user} loading={loading} />}>
        <Route element={<Login />} path="/login" />
        <Route element={<SignUp />} path="/signup" />
      </Route>
      <Route element={<PrivateRoute user={user} loading={loading} />}>
        <Route element={<Exhibits />} path="/exhibits" />
        <Route element={<ExhibitCreate />} path="/exhibitCreate" />
        <Route element={<ExhibitDetails />} path="/exhibitDetails" />
        <Route element={<ExhibitPreview />} path="/exhibitPreview" />
        <Route element={<Filters />} path="/filters" />
        <Route element={<Categories />} path="/categories" />
        <Route element={<Numerics />} path="/numerics" />
        <Route element={<Settings />} path="/settings" />
      </Route>
    </Routes>
  );
};

export default Content;
