import React, { useEffect, useRef, useState } from "react";
import { Alert, Box, Button, Drawer, TextField } from "@mui/material";
import api from "../../../utils/api";

interface Error {
  msg: string;
}

const ModalCategoryAdd = ({
  parentCategory,
  isModalCategoryAddOpen,
  onCategoryAddSuccess,
  setIsModalCategoryAddOpen,
}: any) => {
  const titleRef = useRef<HTMLInputElement>(null);
  const sortRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);

  useEffect(() => {
    setErrors([]);
  }, [isModalCategoryAddOpen]);

  const onButtonCreateCategoryClick = async () => {
    try {
      setErrors([]);
      let level = !parentCategory ? 0 : parentCategory.parentId === 0 ? 1 : 2;
      setLoading(true);
      await api.post("/category/create", {
        level,
        parentId: parentCategory?.id || null,
        sort: parseInt(sortRef.current!.value),
        title: titleRef.current!.value,
      });
      setLoading(false);
      onCategoryAddSuccess();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalCategoryAddOpen}
      onClose={() => setIsModalCategoryAddOpen(false)}
    >
      <div className="modal-wrap">
        <header>
          <h2>Add Category</h2>
          <h3>
            {parentCategory
              ? `Subcategory of ${parentCategory.title}`
              : `Main Category`}
          </h3>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Title"
              variant="outlined"
              inputRef={titleRef}
              fullWidth={true}
              type={`text`}
              autoFocus={true}
            />
            <TextField
              label="Sort"
              variant="outlined"
              inputRef={sortRef}
              fullWidth={true}
              type={`number`}
            />
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonCreateCategoryClick}
              disabled={loading}
            >
              {`Create Category`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalCategoryAdd;
