import "./FieldsChooser.style.scss";
import * as React from "react";
import { Check, Close, Refresh } from "@mui/icons-material";
import { DialogContent, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import RESULT_FIELDS from "../DATA/RESULTS_FIELDS.json";

export interface SimpleDialogProps {
  open: boolean;
  tableViewFields: string[];
  onClose: () => void;
  onButtonFieldChooserClick: (field: string) => any;
  onButtonFieldChooserReset: () => any;
}

const RESULT_FIELDS_ALL = RESULT_FIELDS.fields;

function SimpleDialog(props: SimpleDialogProps) {
  const {
    onClose,
    tableViewFields,
    open,
    onButtonFieldChooserClick,
    onButtonFieldChooserReset,
  } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md">
      <DialogTitle>{`Fields Chooser`}</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <div className="wrap-modal-body-field-chooser">
          <main>
            <Grid container spacing={2}>
              {RESULT_FIELDS_ALL.map((field) => {
                let isActive = tableViewFields.includes(field);
                return (
                  <Grid item xs={4} key={field}>
                    <Button
                      variant={isActive ? "contained" : "outlined"}
                      color="inherit"
                      startIcon={isActive ? <Check /> : null}
                      style={{ width: "100%", fontWeight: "bold" }}
                      onClick={() => onButtonFieldChooserClick(field)}
                    >
                      {field}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </main>
          <footer>
            <div className="left"></div>
            <div className="right">
              <Button
                variant={"outlined"}
                color="info"
                onClick={onButtonFieldChooserReset}
                startIcon={<Refresh />}
                style={{ marginRight: 10 }}
              >
                {`default settings`}
              </Button>
              <Button
                variant={"outlined"}
                color="inherit"
                onClick={handleClose}
                startIcon={<Close />}
                style={{ marginRight: 10 }}
              >
                {`Close`}
              </Button>
            </div>
          </footer>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SimpleDialog;
