export default [
  { title: "Temple", id: "temple" },
  { title: "Christian temple", id: "christian-temple" },
  { title: "Bridge", id: "bridge" },
  { title: "Tower", id: "tower" },
  { title: "Skyscraper", id: "skyscraper" },
  { title: "Museum", id: "museum" },
  { title: "Amphitheatre", id: "amphitheatre" },
  { title: "Theatre", id: "theatre" },
  { title: "Obelisk", id: "obelisk" },
  { title: "Arch", id: "arch" },
  { title: "Mausoleum", id: "mausoleum" },
  { title: "Pyramid", id: "pyramid" },
  { title: "Sculpture", id: "sculpture" },
];
