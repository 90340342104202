import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Drawer, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAlert } from "../../../context/alertContext";
import { NumericGroupOption } from "../../../types";
import api from "../../../utils/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ICON_OPTIONS = [
  { title: "Common", id: "1" },
  { title: "Dimensions", id: "2" },
  { title: "Ungrouped", id: "3" },
  { title: "Statistics", id: "4" },
  { title: "Characteristics", id: "5" },
  { title: "Height", id: "6" },
  { title: "Technical details", id: "7" },
  { title: "Specifications", id: "8" },
];

const categoriesNames = ICON_OPTIONS.map((el) => el.title);

interface ModalNumericGroupOptionAddProps {
  numericGroupOptions: NumericGroupOption[];
  isModalOpen: boolean;
  setIsModalOpen: any;
  onSuccess: any;
}

const ModalNumericGroupOptionAdd = ({
  numericGroupOptions,
  isModalOpen,
  setIsModalOpen,
  onSuccess,
}: ModalNumericGroupOptionAddProps) => {
  const [title, setTitle] = useState("");
  const [sort, setSort] = useState("");
  const [unit, setUnit] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);
  const [selectedNumericGroupTitle, setSelectedNumericGroupTitle] =
    useState("");
  const { addAlert, removeAlert } = useAlert() || {};

  useEffect(() => {
    setErrors([]);
    setTitle("");
    setSort("");
    setUnit("");
    setSelectedNumericGroupTitle("");
  }, [isModalOpen]);

  const onButtonOptionAddClick = async () => {
    try {
      setErrors([]);
      setLoading(true);
      let numericGroupId = parseInt(
        ICON_OPTIONS.find((el) => el.title === selectedNumericGroupTitle)?.id ||
          ""
      );
      await api.post("/numericGroupOption/create", {
        numericGroupId,
        sort: parseInt(sort),
        title,
        unit,
      });
      setLoading(false);
      let d1 = Date.now();
      addAlert &&
        addAlert({
          id: d1,
          message: "Numeric option created successfully",
          severity: "success",
          title: "Success",
        });
      setTimeout(() => {
        removeAlert && removeAlert(d1);
      }, 3000);
      onSuccess();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        setErrors(error?.response?.data?.errors);
        console.log(error?.response?.data?.errors, "the errors");
      }
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setSelectedNumericGroupTitle(value);
    let numericGroupId = parseInt(
      ICON_OPTIONS.find((el) => el.title === value)?.id || ""
    );
    const optionsWithTheSameNumericGroup = numericGroupOptions.filter(
      (el) => el.numericGroupId === numericGroupId
    );
    const maxFilterOption =
      optionsWithTheSameNumericGroup
        .map((el: any) => el.sort)
        .sort((a: any, b: any) => a - b)
        .pop() || 0;
    setSort(maxFilterOption + 1);
  };

  return (
    <Drawer
      anchor={`right`}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <div className="modal-wrap">
        <header>
          <h2>{`Add Numeric Option`}</h2>
        </header>
        <main>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">
                {`Numeric Group`}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={selectedNumericGroupTitle}
                onChange={handleCategoryChange}
                input={<OutlinedInput label="Numeric Group" />}
                MenuProps={MenuProps}
              >
                {categoriesNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Numeric Option"
              variant="outlined"
              value={title}
              onChange={({ target: { value } }) => setTitle(value)}
              fullWidth={true}
              type={`text`}
            />
            <TextField
              label="Sort"
              variant="outlined"
              value={sort}
              onChange={({ target: { value: sort } }) => setSort(sort)}
              fullWidth={true}
              type={`number`}
            />
            <TextField
              label="Unit"
              variant="outlined"
              value={unit}
              onChange={({ target: { value: unit } }) => setUnit(unit)}
              fullWidth={true}
              type={`text`}
            />
          </Box>
        </main>
        <footer>
          <Box
            component="form"
            sx={{
              margin: "0 auto",
              "& > :not(style)": { mb: 2 },
            }}
            noValidate
            autoComplete="off"
          >
            {(errors || []).map(({ msg }: any) => (
              <Alert severity="error">
                This was an error — <strong>{msg}</strong>
              </Alert>
            ))}
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onButtonOptionAddClick}
              disabled={loading}
            >
              {`Add Numeric Option`}
            </Button>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
};

export default ModalNumericGroupOptionAdd;
