import "./Leaflet.style.scss";
import "./ExhibitPreview.style.scss";
import { divIcon } from "leaflet";
import { Suspense, useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import { useLocation, useNavigate } from "react-router-dom";
import { useModals } from "../../context/modalsContext";
import { Exhibit } from "../../types";
import api from "../../utils/api";
import {
  getCoordsFromURL,
  getNavigateStringToEditedURLParamsObject,
  getURLParamsObjectByLocation
} from "../../utils/URL";
import MapPopupExhibit from "./MapPopupExhibit/MapPopupExhibit";

const ExhibitPreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setCurrentGlobalExhibit } = useModals() || {};
  const [popupX, setPopupX] = useState(0);
  const [popupY, setPopupY] = useState(0);
  const [exhibitUUID, setExhibitUUID] = useState("");
  const [currentExhibitUUID, setCurrentExhibitUUID] = useState<any>("");
  const [map, setMap] = useState<any>(null);
  const [exhibit, setExhibit] = useState<Exhibit | null>(null);
  const { lng, lat, zoom } = getCoordsFromURL();

  useEffect(() => {
    window.scrollTo(0, 0);
    const { exhibit } = getURLParamsObjectByLocation(window.location);
    (async () => {
      let JSONResult = await api.get(`/v1/findExhibitByUUID?UUID=${exhibit}`);
      let exhibitResult: Exhibit = JSONResult?.data?.exhibit;
      setExhibit(exhibitResult);
      setCurrentGlobalExhibit(exhibitResult);
    })();
    return () => {
      setCurrentGlobalExhibit(null);
    };
  }, []);

  useEffect(() => {
    const URLParamsObject = getURLParamsObjectByLocation(window.location);
    let exhibitUUID = URLParamsObject && URLParamsObject.exhibit;

    if (!exhibitUUID) {
      setPopupX(0);
      setPopupY(0);
      setCurrentExhibitUUID("");
    }
    if (exhibitUUID && exhibitUUID !== currentExhibitUUID) {
      if (exhibit && map) {
        setExhibitUUID(exhibit.UUID);
        setCurrentExhibitUUID(exhibit.UUID);
        const clickedPointCoords = map?.latLngToContainerPoint([
          exhibit.lat,
          exhibit.lng,
        ]);
        const { x, y }: any = clickedPointCoords || {};
        console.log({ x, y, lat, lng, URLParamsObject, clickedPointCoords });
        const POPUP_WIDTH = 370;
        const POPUP_HEIGHT = 470;
        let moveY = 0;
        let moveX = 0;
        if (POPUP_HEIGHT + 100 > y) {
          moveY = -POPUP_HEIGHT - 100 + y;
        }
        setTimeout(
          () => {
            setPopupX(x - moveX - POPUP_WIDTH / 2);
            setPopupY(y - moveY - POPUP_HEIGHT);
          },
          moveX || moveY ? 550 : 0
        );
        map?.panBy([moveX, moveY]);
      }
    }
  }, [exhibit, map, location]);

  const renderIcon = (title: string, UUID: string, icon: string | null) =>
    divIcon({
      className: "map-marker",
      html: `<div class="icon" id="${UUID}"><img alt="${title} icon" height="48" src="/icons/${
        icon ? icon : "icon48_1"
      }.png" width="48" /><div>${title}</div></div>`,
    });

  const MapEvents = () => {
    useMapEvents({
      moveend: (e) => {
        const zoom = e.target.getZoom();
        const { lat, lng } = e.target.getCenter();
        const URLParamsObjects = getURLParamsObjectByLocation(window.location);
        URLParamsObjects.lat = `${lat}`;
        URLParamsObjects.lng = `${lng}`;
        URLParamsObjects.zoom = `${zoom}`;
        setTimeout(() => {
          navigate(getNavigateStringToEditedURLParamsObject(URLParamsObjects));
        }, 300);
      },
    });
    return null;
  };

  const onMapClick = (e: any) => {
    let URLParamsObject = getURLParamsObjectByLocation(window.location);
    if (URLParamsObject.exhibit) {
      var els = [];
      let a = e.target;
      while (a) {
        els.unshift(a);
        a = a.parentNode;
      }
      els.reverse();
      let clickedOutsideOfMapExhibit = !els.find((el) =>
        [...(el?.classList || [])].some((el: any) =>
          el.startsWith("map-exhibit")
        )
      );
      let clickedOnMarker = !!els.find((el) =>
        [...(el?.classList || [])].some((el: any) =>
          el.startsWith("leaflet-marker-pane")
        )
      );
      let clickedOnModalExhibitFullScreen = !!els.find((el) =>
        [...(el?.classList || [])].some((el: any) =>
          el.startsWith("modal-exhibit-full-screen")
        )
      );
      if (
        clickedOutsideOfMapExhibit &&
        !clickedOnMarker &&
        !clickedOnModalExhibitFullScreen
      ) {
        delete URLParamsObject.exhibit;
        navigate(getNavigateStringToEditedURLParamsObject(URLParamsObject));
      }
    }
  };

  return (
    <>
      <div onMouseDown={(e) => onMapClick(e)}>
        <MapContainer
          attributionControl={false}
          center={[lat || 22.35, lng || -10.9]}
          zoom={zoom || 3}
          scrollWheelZoom={true}
          style={{ height: "calc(100vh - 65px)" }}
          closePopupOnClick={true}
          ref={(e) => {
            setMap(e);
          }}
        >
          <MapEvents />
          <TileLayer
            crossOrigin={true}
            url={`https://api.mapbox.com/styles/v1/ioanmeri/clawvfhrm007t15mwi2v2yv7o/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaW9hbm1lcmkiLCJhIjoiY2w4NW5rdGE5MHFubTN1bGtqemZ0cDdqOCJ9.rGE2PHLjUzAprnRkrHbgaQ`}
            tileSize={256}
            // minZoom={4}
            // maxZoom={16}
          />
          {exhibit ? (
            <Marker
              key={`${Date.now()}_${exhibit.title}`}
              title={exhibit.title}
              // ref={(el) => (itemsRef.current[i] = el)}
              position={[exhibit.lat, exhibit.lng]}
              icon={renderIcon(exhibit.title, exhibit.UUID, exhibit.icon)}
              eventHandlers={{
                click: (e) => {
                  const URLParamsObjects = getURLParamsObjectByLocation(
                    window.location
                  );
                  delete URLParamsObjects.viewImage;
                  URLParamsObjects.exhibit = `${exhibit.UUID}`;
                  navigate(
                    getNavigateStringToEditedURLParamsObject(URLParamsObjects)
                  );
                },
              }}
            ></Marker>
          ) : null}
        </MapContainer>
      </div>
      {popupX || popupY ? (
        <Suspense fallback={<div />}>
          <MapPopupExhibit
            exhibitUUID={exhibitUUID}
            left={popupX}
            location={window.location}
            top={popupY}
          />
        </Suspense>
      ) : null}
    </>
  );
};

export default ExhibitPreview;
