import { Category } from "../types";

export const computeCategoriesTitlesArrayFromCategories = (
  Categories: Category[] | undefined
): string[] => {
  if (!Categories) {
    return [];
  }
  let TRIM_LENGTH = 40;
  if (window.innerWidth < 440) {
    TRIM_LENGTH = 24;
  }
  if (window.innerWidth < 400) {
    TRIM_LENGTH = 20;
  }
  const categoriesTitles = Categories.sort(
    (a: Category, b: Category) => a.level - b.level
  ).map((el) => el.title);
  let joinedTitlesLength = Categories.map((el) => el.title).join("").length;
  if (joinedTitlesLength > TRIM_LENGTH) {
    return categoriesTitles.splice(0, 2);
  }
  return categoriesTitles;
};
