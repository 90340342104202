import axios from "axios";
import { Filter } from "../../types";
import { getHeightAndWidthFromDataUrl } from "../../utils/file";

export const utilComputeParsedFilterOptions = ({
  filters,
  selectedFilterOptions,
}: {
  filters: Filter[];
  selectedFilterOptions: any;
}) => {
  const parsedFilterOptions: any = {};
  Object.keys(selectedFilterOptions).forEach((key) => {
    let filter = filters.find((el) => el.id === parseInt(key));
    parsedFilterOptions[key] = [];
    selectedFilterOptions[key].forEach((filterOptionTitle: string) => {
      let filterOptionId = (
        (filter?.filterOptions || []).find(
          (el) => el.title === filterOptionTitle
        ) || {}
      ).id;
      parsedFilterOptions[key].push(filterOptionId);
    });
  });
  return Object.values(parsedFilterOptions).flat();
};

export const utilComputeParsedNumericGroupOptionsValues = (
  numericGroupOptionsValues: any
) => {
  let parsed: any = {};
  Object.keys(numericGroupOptionsValues).forEach((numericGroupOptionId) => {
    let value = numericGroupOptionsValues[numericGroupOptionId];
    if (value && !isNaN(value)) {
      parsed[numericGroupOptionId] = value;
    }
  });
  return parsed;
};

export const utilGetImagesPromises = async ({
  UUID,
  selectedImageFiles,
  selectedImageFileSizes,
}: {
  UUID: string;
  selectedImageFiles: File[];
  selectedImageFileSizes: string[][];
}) => {
  return selectedImageFiles?.map(async (file, i) => {
    var objectUrl = window.URL.createObjectURL(file);
    const dimensions: any = await getHeightAndWidthFromDataUrl(objectUrl);
    const { width, height } = dimensions;
    const formData = new FormData();
    formData.append("image", file);
    return axios.post(
      `${
        process.env.REACT_APP_BASE_URL
      }/api/exhibit/imageUpload?width=${width}&height=${height}&sizes=${selectedImageFileSizes[
        i
      ].join(",")}&UUID=${UUID}`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
  });
};

export const utilGetImagesField = (imageResults: any) => {
  const keysArr: string[] = [];
  const sizesArr: string[] = [];
  const dirArr: string[] = [];
  (imageResults || []).forEach((imageResult: any) => {
    const { key, sizes, dir } = imageResult?.data || {};
    keysArr.push(key);
    sizesArr.push(sizes);
    dirArr.push(dir);
  });
  return {
    dir: dirArr,
    keys: keysArr,
    sizes: sizesArr,
  };
};
