import "./ExhibitsHeader.style.scss";
import { ArrowUpward } from "@mui/icons-material";
import { keyToName } from "../../../utils/format";
import RESULT_FIELDS from "../DATA/RESULTS_FIELDS.json";

interface ExhibitsHeaderProps {
  fields: string[];
  setSearchPaginationState: any;
  searchPaginationState: any;
  order: string;
  reverse: boolean;
  selectable?: boolean;
  hideActionButtons?: boolean;
}

const RESULT_FIELDS_ALL = RESULT_FIELDS.fields;

const ExhibitsHeader = ({
  fields,
  setSearchPaginationState,
  searchPaginationState,
  order,
  reverse,
  selectable,
  hideActionButtons,
}: ExhibitsHeaderProps) => {
  const onOrderFieldClick = (field: string) =>
    setSearchPaginationState(
      ({ order, reverse }: { order: any; reverse: any }) => ({
        ...searchPaginationState,
        order: field,
        reverse: order === field ? !reverse : false,
        pageNumber: 1,
      })
    );

  return (
    <div className="exhibits-header">
      {selectable ? (
        <div className={`exhibits-header-cell selectable`}></div>
      ) : null}
      {RESULT_FIELDS_ALL.filter((el) => fields.includes(el)).map((field) => (
        <div
          className={`exhibits-header-cell ${field} ${
            order === field ? "icon-active" : ""
          } ${reverse ? "icon-reverse" : ""}`}
          key={field}
          onClick={() => onOrderFieldClick(field)}
        >
          <span className="title">{keyToName(field)}</span>
          <span className={`icon`}>
            <ArrowUpward fontSize="small" />
          </span>
        </div>
      ))}
      {hideActionButtons ? null : (
        <div className={`exhibits-header-cell actions`} />
      )}
    </div>
  );
};

export default ExhibitsHeader;
