import "./Table.style.scss";
import React, { useEffect } from "react";
import { Add, Delete, MoreVert } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { FilterOption } from "../../types";
import { Column, Order, TableProps } from "./Table.types";
import { getComparator, stableSort } from "./Table.utils";
import TableFooter from "./TableFooter";
import TableHead from "./TableHead";
import TableRowsLoading from "./TableRowsLoading";

const TABLE_COLUMNS_FILTERS: {
  title: string;
  id: keyof ITableRow;
  width: number;
}[] = [
  { title: "ID", id: "id", width: 60 },
  { title: "Filter", id: "filterTitle", width: 150 },
  { title: "Option", id: "title", width: 150 },
  { title: "URL Slag", id: "URLSlug", width: 150 },
  { title: "Sort Order", id: "sort", width: 60 },
];

export interface ITableRow {
  id: number;
  filterTitle: string;
  sort: number;
  title: string;
  URLSlug: string;
}

export default function CustomTable({
  filterOptions,
  loading,
  onButtonAddFilterOptionClick,
  onButtonDeleteFilterOptionClick,
  onPopoverMoreButtonFilterDeleteClick,
  onPopoverMoreButtonFilterEditClick,
}: TableProps) {
  // const {
  //   id: filterId,
  //   filterOptions: rowsData,
  //   sort: filterSort,
  //   title,
  // } = filter || {};
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof ITableRow>("title");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [data, setData] = React.useState<ITableRow[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  useEffect(() => {
    setData([...(filterOptions || [])]);
  }, [filterOptions]);

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ITableRow
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    console.log("order", order, orderBy);
  };

  const handleMoreClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onPopoverMoreButtonFilterDelete = () => {
    handleMoreClose();
    // onPopoverMoreButtonFilterDeleteClick(filterId);
  };

  const onPopoverMoreButtonFilterEdit = () => {
    handleMoreClose();
    // onPopoverMoreButtonFilterEditClick(filterId);
  };

  const emptyRows = data.length < 5 ? 5 - data.length : 0;

  return (
    <div className={`table-wrap  ${loading ? "loading" : ""}`}>
      <TableContainer component={Paper}>
        <div className="pagination-top-wrap">
          <TableFooter
            data={data}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            loading={loading}
          />
        </div>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead
            columns={TABLE_COLUMNS_FILTERS}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {loading ? (
              <TableRowsLoading columns={TABLE_COLUMNS_FILTERS} />
            ) : null}
            {!loading
              ? stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={`${row.filterTitle}-${row.id}-${row.URLSlug}`}
                    >
                      {TABLE_COLUMNS_FILTERS.map(({ id, width }) => (
                        <TableCell component="th" scope="row" width={width}>
                          {row[id]}
                        </TableCell>
                      ))}
                      <TableCell align="right" width={60}>
                        <div className="row-actions">
                          <IconButton
                            onClick={() => onButtonDeleteFilterOptionClick(row)}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
              : null}
            {!loading && emptyRows > 0 && (
              <TableRow style={{ height: 57 * emptyRows }}>
                {TABLE_COLUMNS_FILTERS.map(({ id, width }) => (
                  <TableCell key={`cell-${id}`} width={width} />
                ))}
                <TableCell align="right" width={60} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter
            data={data}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            loading={loading}
          />
        </Table>
      </TableContainer>
    </div>
  );
}
