import "./Numerics.style.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import HeroBannerSearchLayout from "../../components/HeroBannerSearchLayout/HeroBannerSearchLayout";
import { NumericGroup, NumericGroupOption } from "../../types";
import api from "../../utils/api";
import { getURLParamsObjectByLocation } from "../../utils/URL";
import ModalNumericGroupCreate from "./modals/ModalNumericGroupCreate";
import ModalNumericGroupDelete from "./modals/ModalNumericGroupDelete";
import ModalNumericGroupEdit from "./modals/ModalNumericGroupEdit";
import ModalNumericGroupOptionAdd from "./modals/ModalNumericGroupOptionAdd";
import ModalNumericGroupOptionDelete from "./modals/ModalNumericGroupOptionDelete";
import {
  computeNextURL,
  getFieldsStateFromQueryParams,
} from "./Numerics.utils";
import NumericsSectionTable from "./NumericsSectionTable";

export const DEFAULT_SEARCH_FIELDS_STATE = {
  title: "",
  id: "",
  category: "",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ICON_OPTIONS = [
  { title: "Common", id: "common" },
  { title: "Ungrouped", id: "ungrouped" },
  { title: "Dimensions", id: "dimensions" },
  { title: "Statistics", id: "statistics" },
  { title: "Height", id: "height" },
  { title: "Technical details", id: "technical-details" },
  { title: "Specifications", id: "specifications" },
];

const categoriesNames = ICON_OPTIONS.map((el) => el.title);

const Numerics = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchFieldsState, setSearchFieldsState] = useState<
    typeof DEFAULT_SEARCH_FIELDS_STATE
  >(getFieldsStateFromQueryParams(location));

  const [numericGroupOptions, setNumericGroupOptions] = useState<
    NumericGroupOption[]
  >([]);
  const [filteredNumericGroupOptions, setFilteredNumericGroupOptions] =
    useState<NumericGroupOption[]>([]);

  const [numericGroups, setNumericGroups] = useState<NumericGroup[]>([]);
  const [filteredNumericGroups, setFilteredNumericGroups] = useState<
    NumericGroup[]
  >([]);
  const [isModalNumericGroupCreateOpen, setIsModalNumericGroupCreateOpen] =
    useState(false);
  const [isModalNumericGroupDeleteOpen, setIsModalNumericGroupDeleteOpen] =
    useState(false);
  const [numericGroupIdToDelete, setNumericGroupIdToDelete] = useState(0);
  const [isModalNumericGroupEditOpen, setIsModalNumericGroupEditOpen] =
    useState(false);
  const [numericGroupIdToEdit, setNumericGroupIdToEdit] = useState(0);
  const [
    isModalNumericGroupOptionAddOpen,
    setIsModalNumericGroupOptionAddOpen,
  ] = useState(false);
  const [
    isModalNumericGroupOptionDeleteOpen,
    setIsModalNumericGroupOptionDeleteOpen,
  ] = useState(false);

  useEffect(() => {
    loadNumericGroups();
  }, []);

  useEffect(() => {
    let nextURL = computeNextURL({ searchFieldsState });
    navigate(`/numerics?${nextURL}`);
  }, [searchFieldsState]);

  useEffect(() => {
    const paramsObject: any = getURLParamsObjectByLocation(location);
    const { title, category } = paramsObject || {};
    let filteredNumericGroupOptions: NumericGroupOption[] = [
      ...numericGroupOptions,
    ];
    if (title) {
      filteredNumericGroupOptions = numericGroupOptions.filter(
        (el) =>
          el.title.toLowerCase().includes(title.toLowerCase()) ||
          (el?.NumericGroup?.title || "")
            .toLowerCase()
            .includes(title.toLowerCase())
      );
    }
    if (category) {
      let categories = category.split(",");
      filteredNumericGroupOptions = filteredNumericGroupOptions.filter((el) =>
        categories.includes(el?.NumericGroup?.URLSlug || "")
      );
    }
    setFilteredNumericGroupOptions(filteredNumericGroupOptions);
  }, [location]);

  const loadNumericGroups = async (delay?: number) => {
    let delayInterval = delay || 1500;
    try {
      let d1 = Date.now();
      setLoading(true);
      const numericGroupOptions: NumericGroupOption[] =
        (await api.get("/numericGroupOptions"))?.data?.data || [];
      setNumericGroupOptions(numericGroupOptions);
      setFilteredNumericGroupOptions(numericGroupOptions);
      let d2 = Date.now();
      if (d2 - d1 > delayInterval) {
        setLoading(false);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, delayInterval - (d2 - d1));
      }
    } catch (error) {
      console.log(error, "the error");
    }
  };

  const onNumericGroupCreateSuccess = () => {
    setIsModalNumericGroupCreateOpen(false);
    loadNumericGroups(500);
  };

  const onNumericGroupDeleteSuccess = () => {
    setIsModalNumericGroupDeleteOpen(false);
    loadNumericGroups();
  };

  const onNumericGroupEditSuccess = () => {
    setIsModalNumericGroupEditOpen(false);
    loadNumericGroups();
  };

  const onButtonNumericGroupOptionDeleteClick = (row: any) =>
    setIsModalNumericGroupOptionDeleteOpen(row);

  const onPopoverMoreButtonFilterDeleteClick = (numericId: number) => {
    setIsModalNumericGroupDeleteOpen(true);
    setNumericGroupIdToDelete(numericId);
  };

  const onPopoverButtonMoreNumericGroupEditClick = (numericGroupId: number) => {
    setIsModalNumericGroupEditOpen(true);
    setNumericGroupIdToEdit(numericGroupId);
  };

  const onNumericGroupOptionCreateSuccess = () => {
    setIsModalNumericGroupOptionAddOpen(false);
    loadNumericGroups(500);
  };

  const onNumericGroupOptionDeleteSuccess = () => {
    setIsModalNumericGroupOptionDeleteOpen(false);
    loadNumericGroups();
  };

  const onButtonCreateNumericGroup = () =>
    setIsModalNumericGroupCreateOpen(true);

  const onLinkClearFieldsClick = (e: any) => {
    e.preventDefault();
    setSearchFieldsState(DEFAULT_SEARCH_FIELDS_STATE);
  };

  const onTextFieldChange = ({
    field,
    value,
  }: {
    field: string;
    value: string;
  }) => {
    setSearchFieldsState((searchFieldsState) => ({
      ...searchFieldsState,
      [field]: value,
    }));
  };

  const findIdArrayFromTitleArray = (titlesArray: string[]): string[] => {
    return titlesArray.map(
      (el) => ICON_OPTIONS.find((el2: any) => el2.title === el)?.id || ""
    );
  };

  const findTitleArrayFromIdArray = (idsArray: string[]): string[] => {
    return idsArray.map(
      (el) => ICON_OPTIONS.find((el2: any) => el2.id === el)?.title || ""
    );
  };

  const categoryValues = findTitleArrayFromIdArray(
    searchFieldsState.category.split(",")
  ).filter((el) => !!el);

  const handleCategoryChange = (
    event: SelectChangeEvent<string | string[]>
  ) => {
    const {
      target: { value },
    } = event;
    setSearchFieldsState((searchFieldsState) => ({
      ...searchFieldsState,
      category:
        typeof value === "string"
          ? value
          : findIdArrayFromTitleArray(value).join(","),
    }));
  };

  return (
    <>
      <ModalNumericGroupCreate
        isModalNumericGroupCreateOpen={isModalNumericGroupCreateOpen}
        onNumericGroupCreateSuccess={onNumericGroupCreateSuccess}
        setIsModalNumericGroupCreateOpen={setIsModalNumericGroupCreateOpen}
      />
      <ModalNumericGroupDelete
        title={
          numericGroups.find(
            (el: NumericGroup) => el.id === numericGroupIdToDelete
          )?.title || ""
        }
        numericGroupId={numericGroupIdToDelete}
        isModalOpen={isModalNumericGroupDeleteOpen}
        onSuccess={onNumericGroupDeleteSuccess}
        setIsModalOpen={setIsModalNumericGroupDeleteOpen}
      />
      <ModalNumericGroupEdit
        numericGroup={numericGroups.find(
          (el: NumericGroup) => el.id === numericGroupIdToEdit
        )}
        isModalOpen={isModalNumericGroupEditOpen}
        onSuccess={onNumericGroupEditSuccess}
        setIsModalOpen={setIsModalNumericGroupEditOpen}
      />
      <ModalNumericGroupOptionAdd
        numericGroupOptions={numericGroupOptions}
        isModalOpen={isModalNumericGroupOptionAddOpen}
        setIsModalOpen={setIsModalNumericGroupOptionAddOpen}
        onSuccess={onNumericGroupOptionCreateSuccess}
      />
      <ModalNumericGroupOptionDelete
        isModalOpen={!!isModalNumericGroupOptionDeleteOpen}
        numericGroupOption={isModalNumericGroupOptionDeleteOpen}
        onSuccess={onNumericGroupOptionDeleteSuccess}
        setIsModalOpen={setIsModalNumericGroupOptionDeleteOpen}
      />
      <main id="page-numerics">
        <HeroBannerSearchLayout
          buttonActionTitle={`Numeric Option`}
          onButtonCreateClick={() => setIsModalNumericGroupOptionAddOpen(true)}
          onLinkClearFieldsClick={onLinkClearFieldsClick}
          subtitle="Numerics groups enables filtering attributes that are measurable by a unit or accept numeric values. Examples can be the dimensions of an object or technical characteristics like the number of floors a skyscraper has."
          title="Numerics"
        >
          <>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  label="Title"
                  fullWidth={true}
                  variant="outlined"
                  value={searchFieldsState.title}
                  onChange={({ target: { value } }) =>
                    onTextFieldChange({ field: "title", value })
                  }
                  autoComplete={"off"}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={categoryValues}
                    onChange={handleCategoryChange}
                    input={<OutlinedInput label="Category" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {categoriesNames.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={categoryValues.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        </HeroBannerSearchLayout>
        <Container maxWidth="xl">
          <NumericsSectionTable
            numericGroupOptions={filteredNumericGroupOptions}
            loading={loading}
            setIsModalNumericGroupOptionAddOpen={
              setIsModalNumericGroupOptionAddOpen
            }
            onButtonNumericGroupOptionDeleteClick={
              onButtonNumericGroupOptionDeleteClick
            }
            onPopoverMoreButtonFilterDeleteClick={
              onPopoverMoreButtonFilterDeleteClick
            }
            onPopoverButtonMoreNumericGroupEditClick={
              onPopoverButtonMoreNumericGroupEditClick
            }
          />
        </Container>
      </main>
    </>
  );
};

export default Numerics;
